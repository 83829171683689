import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import { setCloseSnackbarCart } from 'store/reducers/Utils/actions';
import FavoritesPage, { StateProps } from './component';

import { State as StoreState } from '../../../store';
import {
  clearFavoriteProducts,
  fetchFavoriteProducts,
  removeFromFavoriteProduct,
  clearDelayedFavoriteRequest,
} from '../../../store/reducers/FavoriteProduct/actions';
import { fetchTours } from '../../../store/reducers/Tour/actions';

/**
 * FIXME: remove eslint-disable on top
 * FIXME: check why loading / hasMore is not included on store/index.ts
 * FIXME: SPT-4406 - logged error for this
 * @param state
 */
const mapStateToProps = (state: StoreState): StateProps => ({
  favorites: state.favorites,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  loading: state.loading,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  hasMore: state.hasMore,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  tours: state.tours,
  snackbarCart: state.utils.snackbarCart,
});

const mapDispatchToProps = {
  fetchFavoriteProducts,
  removeFromFavoriteProduct,
  clearFavoriteProducts,
  fetchTours,
  clearDelayedFavoriteRequest,
  setCloseSnackbarCart,
};

export default translate('favoritesPage')(withRouter(connect(mapStateToProps, mapDispatchToProps)(FavoritesPage)));
