import { Widget } from 'components/common/Widget';
import { getWidgetParamByMarketingType } from 'components/common/Widget/widget.util';
import { defaultWidgetConfig } from 'components/pages/HomePage/style';
import { DetailPageDisplayType } from 'components/pages/ProductDetailPage/utils';
import { useHelpDialog } from 'hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import { MarketingType } from 'services/swipe-rx-pt/resources/marketings/interfaces';
import { State as StoreState } from 'store';
import { Product } from 'store/reducers/Product';

const MARKETING_WITH_PRODUCT_REFERENCE = [MarketingType.SIMILAR_PRODUCT];

/**
 * @description
 * This for future implementation of showing widget marketing_features on search suggestion
 * HOW TO USE:
 * marketing_features config should have `show_on_suggestion` property configured via Dashboard
 * on ProductListingPage.config.ts add configuration fixedSuggestions: [FixedSuggestionsEnum.MARKETING_FEATURE_WIDGETS]
 * REFERENCE:
 * SPT-19528 and SPT-19444
 */

const MarketingFeatureSection: React.FC = () => {
  const { toggleHelpDialog } = useHelpDialog();
  const marketingFeaturesRaw = useSelector((state: StoreState) => state.marketingFeatures.marketings) || [];
  const suggestionProductsRaw = useSelector((state: StoreState) => state.searchSuggestions.suggestions) || [];
  const isSearchMenuOpen = useSelector((state: StoreState) => state.searchSuggestions.menuDrawerOpen) || false;
  const marketingFeatures = marketingFeaturesRaw.filter(
    (marketing) => !!marketing?.marketing_features?.[0].config?.show_on_suggestion,
  );
  const [suggestionProducts] = suggestionProductsRaw.filter((data) => data.title === 'products');
  const { products = [] } = suggestionProducts || {};

  if (!isSearchMenuOpen) return <></>;

  const [referenceProduct, ...restProducts] = products || [];
  const excludedProductIds = restProducts?.map((product) => product.id) || [];
  const widgets = marketingFeatures.map((marketing) => {
    const useReferenceProduct = MARKETING_WITH_PRODUCT_REFERENCE.includes(marketing.type)
      ? suggestionProducts?.meta?.total_count >= 1
      : true;
    const widgetLimit = marketing?.marketing_features?.[0]?.config?.max_list ?? marketing?.config?.max_list ?? 1;
    const rule = widgetLimit ? suggestionProducts?.meta?.total_count <= widgetLimit : true;

    if (useReferenceProduct && rule) {
      return (
        <Widget
          isHomepage={false}
          marketing={marketing}
          onItemClick={toggleHelpDialog}
          defaultStyling={defaultWidgetConfig.styling}
          detailPageDisplayType={DetailPageDisplayType.MODAL}
          {...getWidgetParamByMarketingType(marketing.type, referenceProduct as Product, excludedProductIds)}
        />
      );
    }

    return <></>;
  });
  return <>{widgets}</>;
};

export default MarketingFeatureSection;
