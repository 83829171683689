import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getWidgetParamByMarketingType } from 'components/common/Widget/widget.util';
import { defaultWidgetConfig } from 'components/pages/HomePage/style';

import { useHelpDialog, useMarketingWidget } from 'hooks';

import { MarketingType } from 'services/swipe-rx-pt/resources/marketings/interfaces';
import { Product } from 'store/reducers/Product';
import { findIndexOfDifference } from 'utils/Product/product.util';
import { DetailPageDisplayType } from 'components/pages/ProductDetailPage/utils';

import { clearProductListing } from 'store/reducers/ProductListing/actions';
import { MarketingFeaturesDisplayPosition } from 'services/swipe-rx-pt/resources/marketings/constants';
import { getSimilarProductCarousel, setSimilarProductProcessedId } from 'store/reducers/ProductCarousel/actions';
import { SimilarProductLocation } from 'store/reducers/ProductCarousel/interface';
import {
  similarProductCachedIdSelector,
  similarProductCarouselLoadingSelector,
  similarProductCarouselSelector,
  similarProductCarouselSkuCodeSelector,
} from 'store/reducers/ProductCarousel/selectors';
import { Widget } from '../../Widget';
import { ProductListProps } from '../generic-product-list.component';

export const withSimilarProductWidget = (Component: any) => (props: ProductListProps & { withWidget: boolean }) => {
  if (!props?.withWidget) {
    return <Component {...props} />;
  }
  const { marketingList } = useMarketingWidget();
  const { toggleHelpDialog } = useHelpDialog();
  const dispatch = useDispatch();

  const similarProductWidgets = marketingList.filter((marketing) => marketing.type === MarketingType.SIMILAR_PRODUCT);

  const similarProductListing = useSelector(similarProductCarouselSelector(SimilarProductLocation.SEARCH_PAGE));
  const loading = useSelector(similarProductCarouselLoadingSelector(SimilarProductLocation.SEARCH_PAGE));
  const currentSkuCode = useSelector(similarProductCarouselSkuCodeSelector(SimilarProductLocation.SEARCH_PAGE));

  const getWidgets = (option: { product?: Product; index?: number }): JSX.Element[] => {
    const { product, index } = option;
    if (product === undefined || index === undefined) return [<></>];
    const { products } = props?.data;
    const indexDifferent = findIndexOfDifference(props?.data?.products || []);
    const hasPurchaseabilityChanged = indexDifferent !== -1;
    const hasProduct = !!products.length;

    const handleIndex = hasPurchaseabilityChanged ? index === indexDifferent : index === products.length - 1;
    if (hasProduct && handleIndex) {
      return similarProductWidgets.map((marketing) => {
        const isIdProcessed = useSelector(
          similarProductCachedIdSelector(marketing.id, SimilarProductLocation.SEARCH_PAGE),
        );

        const maxList = marketing.config?.max_list ?? 1;
        const showCondition = index < maxList;
        const prevList = products.slice(1, hasPurchaseabilityChanged ? indexDifferent + 1 : maxList);
        const excludedProductIds = prevList.map((product) => product.id);
        const params = getWidgetParamByMarketingType(marketing.type, products[0], excludedProductIds);

        if (!isIdProcessed && currentSkuCode !== product.sku_code) {
          dispatch(
            getSimilarProductCarousel(
              {
                marketingId: marketing.id,
                marketingType: marketing.type,
                ...params,
              },
              SimilarProductLocation.SEARCH_PAGE,
            ),
          );
          dispatch(setSimilarProductProcessedId(marketing.id, SimilarProductLocation.SEARCH_PAGE));
        }

        if (showCondition) {
          return (
            <Widget
              isHomepage={false}
              marketing={marketing}
              onItemClick={toggleHelpDialog}
              defaultStyling={defaultWidgetConfig.styling}
              detailPageDisplayType={DetailPageDisplayType.MODAL}
              data={similarProductListing}
              loading={loading}
              {...params}
            />
          );
        }
        return <></>;
      });
    }
    return [<></>];
  };

  useEffect(
    () => () => {
      dispatch(clearProductListing());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const widgets = (props.widgets || []).concat([
    { getWidgets, position: MarketingFeaturesDisplayPosition.BETWEEN_LISTING },
  ]);

  return <Component {...props} widgets={widgets} />;
};
