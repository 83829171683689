import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { translate } from 'react-i18next';
import moment from 'moment';
import { Grid, CircularProgress } from '@material-ui/core';

import { ConditionalRender } from 'components/common/ConditionalRender';
import { DetailPageDisplayType } from 'components/pages/ProductDetailPage/utils';
import { toCurrency } from 'utils/Format';
import { Product } from 'store/reducers/Product';
import { useSearchProfiler } from 'hooks/use-search-profiler';
import { getMarketId } from 'utils/MarketConfig';
import ShowIf from 'components/views/ShowIf';
import { Color, TourIds } from '../../ProductCard.interface';
import { ProductCardQuantityBox } from '../product-card-quantity-box';
import { ProductCardLowStockInfo } from '../product-card-low-stock-info';
import { ProductCardMaxQuantityInfo } from '../product-card-max-quantity-info';
import { S } from './ProductCardDetails.styles';

interface Props {
  t: any;
  color: Color;
  tourIds: TourIds;
  product: Product;
  sellingPrice: number;
  discount: number;
  discountedPrice: number;
  isStockOut: boolean;
  isReminder?: boolean;
  availabilityRequested?: boolean;
  toggleDialog: () => void;
  changeColor: (itemCount: number) => void;
  queryString?: string;
  detailPageDisplayType?: DetailPageDisplayType;
  handleOpenModal: () => void;
  trackProductDetail: () => void;
}

const BaseProductCardDetails: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  color,
  sellingPrice,
  discount,
  discountedPrice,
  tourIds,
  product,
  isReminder,
  isStockOut,
  availabilityRequested,
  toggleDialog,
  changeColor,
  t,
  queryString,
  detailPageDisplayType,
  handleOpenModal,
  trackProductDetail,
}) => {
  const { name, packaging, reminder_removal_date } = product;
  const { push } = useHistory();
  const location = useLocation();
  const marketId = getMarketId();
  const { clickThroughSRPTrack } = useSearchProfiler();
  const handleClick = (): void => {
    clickThroughSRPTrack(product.id);
    trackProductDetail();
    const pathname = `/${marketId}/product/${product.id}`;

    if (detailPageDisplayType === DetailPageDisplayType.MODAL) {
      handleOpenModal();
    } else {
      push({
        pathname,
        search: queryString,
        state: location?.state,
      });
    }
  };
  const renderReminderDeletionMessage = (): JSX.Element | null => {
    if (!isReminder || !reminder_removal_date) {
      return null;
    }

    return (
      <S.ReminderMessage>
        {t('reminderRemoval', {
          datetime: moment(reminder_removal_date).format('DD/MM/YYYY h:mm A'),
        })}
      </S.ReminderMessage>
    );
  };

  const isProductLoading = product.id === 0;
  return (
    <S.DetailsContainer>
      <Grid style={{ marginRight: 30 }} onClick={handleClick}>
        <S.ProductName stockout={isStockOut} isUnpurchasable={product?.is_unpurchasable}>
          {name}
          <ShowIf condition={(product?.max_qty_enabled && product?.max_qty && product.max_qty > 0) || false}>
            <S.MaxQty>
              &nbsp;
              {t('maxQtyLimit', {
                value: product?.max_qty,
              })}
            </S.MaxQty>
          </ShowIf>
        </S.ProductName>
        <S.Packaging stockout={isStockOut}>{packaging}</S.Packaging>
      </Grid>
      {!isStockOut && (
        <>
          <ProductCardLowStockInfo t={t} product={product} />
          <ProductCardMaxQuantityInfo t={t} product={product} />
        </>
      )}

      <S.PricingContainer>
        {isStockOut ? (
          <Grid container alignItems="center" justify="space-between">
            <S.StockOut>{t('outOfStock')}</S.StockOut>
            <ConditionalRender condition={!availabilityRequested && !isProductLoading}>
              <S.NotifyBtn onClick={toggleDialog}>
                <S.BellIcon />
                <S.Notify>{t('notify')}</S.Notify>
              </S.NotifyBtn>
            </ConditionalRender>
            <ConditionalRender condition={!availabilityRequested && isProductLoading}>
              <CircularProgress color="primary" />
            </ConditionalRender>
          </Grid>
        ) : (
          <>
            <Grid>
              <S.SellingPrice>{discount !== 0 ? toCurrency(sellingPrice) : null}</S.SellingPrice>
              <S.NetPrice>{toCurrency(discountedPrice)}</S.NetPrice>
            </Grid>
            <ProductCardQuantityBox
              color={color}
              tourIds={tourIds}
              product={product}
              changeColor={changeColor}
              data-testid={`container-product-quantity-box-${product.id}`}
            />
          </>
        )}
      </S.PricingContainer>
      {renderReminderDeletionMessage()}
      {children}
    </S.DetailsContainer>
  );
};

BaseProductCardDetails.defaultProps = {
  isReminder: undefined,
  availabilityRequested: undefined,
  queryString: undefined,
  detailPageDisplayType: DetailPageDisplayType.ROUTE,
};

export const ProductCardDetails = translate('stockOut')(BaseProductCardDetails);
