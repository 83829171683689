import React, { MouseEventHandler } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { store } from 'store';
import { trackContactCustomerCare } from 'utils/Analytics/Segment';
import { useGetCurrentUser } from 'hooks';

import { TranslateProps, DEFAULT_LOCALE, ID_LOCALE } from '../../../../utils/Localization/types';

import { RateYourExperienceContainer } from './RateYourExperience.styles';

const RateYourExperience: React.FC<React.PropsWithChildren<TranslateProps>> = (props) => {
  const { t } = props;
  const { config } = store.getState();
  const {
    coreUser: { organization_id: pharmacyId },
  } = useGetCurrentUser();

  const language = config.market.language || DEFAULT_LOCALE;
  const phoneNumber = config.market?.callCenterNumber;
  const customerSupportLink = config.market.customerSuccessSupport.link;

  const onClickSupport: MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault();

    trackContactCustomerCare(pharmacyId);

    window.open(customerSupportLink);
  };

  return (
    <RateYourExperienceContainer container direction="column" alignItems="center">
      <Grid container item direction="column" alignItems="center">
        <Grid item>
          <Typography className="green-text">{t('needHelp')}</Typography>
        </Grid>
        <Grid item>
          <Typography className="our-team">{t('ourTeamMoreThanHappyToAssist')}</Typography>
        </Grid>
        <Grid item>
          {language === ID_LOCALE ? (
            <Typography className="customer-success">
              Silakan hubungi kami melalui
              <Typography className="text-primary" onClick={onClickSupport}>
                WhatsApp&nbsp;
              </Typography>
              chat ke 0852-15804776 (WA) atau telepon ke {phoneNumber} (Tlp).
              <br />
              <br />
              Senin - Jumat / 08.00 - 21.00
              <br />
              Sabtu - Minggu / 08.00 - 17.00
              <br />
              Hari libur nasional / 08.00 - 17.00
            </Typography>
          ) : (
            <Typography className="customer-success">
              {t('pleaseContact')} <strong>{t('customerSuccess')}</strong> {t('teamViaWhatsApp', { phoneNumber })}
            </Typography>
          )}
        </Grid>
      </Grid>
    </RateYourExperienceContainer>
  );
};

export default RateYourExperience;
