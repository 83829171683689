import { Reducer } from 'redux';
import { IMarketing } from 'services/swipe-rx-pt/resources/marketings/interfaces';

import { Actions } from './interface';
import * as constants from './constants';

// FUTURE IMPLEMENTATION
// export type State = {
//   [key in MarketingFeaturesType]: {
//     marketings: IMarketing[];
//     loading: boolean;
//     expired?: Date | null;
//   };
// };

export type State = {
  marketings?: IMarketing[];
  loading: boolean;
  expired?: number | null;
};

const initialState: State = {
  marketings: undefined,
  loading: false,
  expired: null,
};

const MarketingFeaturesReducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_MARKETING_FEATURES:
      return {
        ...state,
        marketings: action.marketing,
        expired: action.expired,
      };

    case constants.SET_MARKETING_FEATURES_LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    default:
      return state;
  }
};

export default MarketingFeaturesReducer;
