import React, { useEffect } from 'react';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import lodashUniq from 'lodash/uniq';

import { GenericProductList } from 'components/common';
import { SnackbarCartComponent } from 'components/common/snackbar-cart';
import { Product } from 'store/reducers/Product';
import { State as ProductReminderState } from 'store/reducers/ProductReminder';
import { ISnackbarCart } from 'store/reducers/Utils/actions';
import { TranslateProps } from 'utils/Localization/types';
import {
  PushActionDeliveryType,
  trackInstockReminder,
  trackRemoveProductFromRemindersPage,
} from 'utils/Analytics/Segment';

import { trackStockOutReminderItem as causalFoundryTrackStockOutReminderItem } from 'utils/Analytics/CausalFoundry';

import { Tour } from 'components/common/Tour';
import { TOURS } from 'components/common/Tours';
import ShowIf from 'components/views/ShowIf';
import { useMarketingAnalytics } from 'hooks/useMarketingAnalytics';
import { v3Client } from 'utils/Client/v3';
import { getSkuCodesFromProducts } from 'utils/Product';
import { BRACKET_QS_OPTIONS, CHARACTER } from 'utils/constants';

export interface StateProps {
  productReminder: ProductReminderState;
  snackbarCart: ISnackbarCart;
}

export interface DispatchProps {
  fetchProductReminders: (params: any) => void;
  clearProductReminders: () => void;
  deleteProductReminder: (params: { availabilityRequestId: number; productId: number; distributorId: number }) => void;
  setCloseSnackbarCart: () => void;
}

interface ProductRemindersPage extends StateProps, DispatchProps, TranslateProps {}

export const ProductRemindersPage: React.FC<React.PropsWithChildren<ProductRemindersPage>> = ({
  productReminder,
  snackbarCart,
  fetchProductReminders,
  clearProductReminders,
  deleteProductReminder,
  setCloseSnackbarCart,
  t,
}) => {
  const marketingAnalytics = useMarketingAnalytics();
  const history = useHistory();
  const location = useLocation();

  const { isr, ...restQs } = qs.parse(location.search, BRACKET_QS_OPTIONS) || {};
  const isrParam = isr && typeof isr === 'string' ? decodeURIComponent(isr) : '';
  const [from_instock_reminder, product_ids_qs] = isrParam.split(CHARACTER.TILDE);

  const product_ids = (product_ids_qs?.split(',') || []).map((id) => Number(id));

  const sku_codes = getSkuCodesFromProducts(productReminder?.products, product_ids);
  const hasProducts = productReminder?.products?.length > 0;

  const handleProductClear = (product: Product): void => {
    const { id, availability_request_id, distributor } = product;

    deleteProductReminder({
      productId: id,
      availabilityRequestId: availability_request_id,
      distributorId: distributor.id,
    });

    trackRemoveProductFromRemindersPage({
      product_id: product.id,
      sku_code: product.sku_code,
      page: marketingAnalytics.from_page,
    });

    causalFoundryTrackStockOutReminderItem(product, {
      isRemove: true,
      promo_id: marketingAnalytics.marketing_id?.toString(),
    });
  };

  const handleTrackInstockReminder = async (): Promise<void> => {
    let skuCodesProperty = sku_codes;
    try {
      const isMissingSkuCodes = sku_codes?.length !== product_ids?.length;
      // fallback
      if (isMissingSkuCodes) {
        const result = await v3Client.get('products/search', {
          product_ids: product_ids.toString(),
          page: 1,
          page_size: 2000,
        });

        const isStillMissingSkuCodes = result?.data !== product_ids?.length;
        const skuCodesFromResult = getSkuCodesFromProducts(result?.data, product_ids);
        const completeSkuCodes = isStillMissingSkuCodes
          ? lodashUniq([...sku_codes, ...skuCodesFromResult])
          : skuCodesFromResult;
        skuCodesProperty = completeSkuCodes;
      }
    } catch (error) {
      console.log('Failed to get sku codes from product search', error);
    } finally {
      trackInstockReminder({
        action_delivery_type: PushActionDeliveryType.Tapped,
        sku_codes: skuCodesProperty,
        product_ids,
      });
    }
  };

  const fetchMoreData = (): void => {
    fetchProductReminders({
      page: productReminder.nextPage || 1,
      limit: productReminder.limit,
    });
  };

  useEffect(() => {
    fetchMoreData();
    setCloseSnackbarCart();

    return () => {
      clearProductReminders();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (from_instock_reminder === 'true' && hasProducts) {
      handleTrackInstockReminder();

      return history.replace({
        search: qs.stringify({ ...restQs }, BRACKET_QS_OPTIONS),
        state: {
          from_instock_reminder: true,
          product_ids,
        },
      });
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasProducts]);

  const toursModule = 'reminders_v2.1.0' as keyof typeof TOURS;

  return (
    <>
      <GenericProductList
        data={productReminder}
        emptyMessage={t('noProducts')}
        message={t('message')}
        // fetchProducts={fetchProductReminders}
        fetchProducts={fetchMoreData}
        onProductClear={handleProductClear}
        toursModule={toursModule}
        hideEndMessage={!productReminder.products.length}
      />
      <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
        <ShowIf condition={snackbarCart.open}>
          <SnackbarCartComponent
            open={!!snackbarCart.open}
            productId={snackbarCart.productId}
            itemCount={snackbarCart.itemCount}
            purchaseableQuantity={snackbarCart.maxPurchaseLimit.purchaseableQuantity}
            hasPurchased={snackbarCart.maxPurchaseLimit.hasPurchased}
          />
        </ShowIf>
      </div>

      <ShowIf condition={productReminder.products.length > 0}>
        <Tour
          module={toursModule}
          styles={{
            buttonClose: {
              display: 'none',
            },
          }}
        />
      </ShowIf>
    </>
  );
};
