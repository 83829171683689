import {
  PharmacyOrderStatus,
  ActivityStatus,
  LoyaltyHistoryStatus,
  OrderStatus,
  LogisticDeliveryStatus,
  LogisticDeliveryStatusDetail,
  OrderPrecursorStatus,
} from 'services';
import {
  LoyaltyMissionActivityTypeCode,
  LoyaltyMissionStatus,
  LoyaltyRewardRedeemDeliveryStatus,
  LoyaltyTierName,
} from 'store/reducers';
import { Lang } from './types';

export const cartModal = {
  viewCart: 'ดูตระกร้าสินค้า',
  item: 'ชิ้น',
  items: 'ชิ้น',
};

const sectionCard = {
  seeAllAction: 'ดูทั้งหมด',
  recentlyViewed: 'ดูล่าสุด',
  close: 'ปิด',
};

const pharmacyTierInfo = {
  verifyAccount: 'Verify Account',
  learnMore: 'Learn more',
  unpurchasableInfo: 'This product is only available for verified account',
  message: 'Hello, I want to upgrade my account, please help.',
  missingSipnap: 'กรุณาส่งใบอนุญาต SIPNAP ของคุณเพื่อซื้อผลิตภัณฑ์พรีเคอร์เซอร์',
};

const tabsProductDetail = {
  summary: 'Summary',
  details: 'Details',
  shipping: 'Shipping',
  recommendation: 'Recommendation',
};

const noProducts = `ไม่พบผลิตภัณฑ์ที่คุณต้องการหา ลองค้นหาอีกครั้งหรือติดต่อฝ่ายลูกค้าสัมพันธ์ (+66 6 0003 5146)`;
const productListingSearch = 'Find {{productListingName}} products here…';

export const lang: Lang = {
  activationPage: {
    chatMessage: 'สวัสดีค่ะ พร้อมใช้งานหรือยังครับ',
    contact: {
      clickButton: 'คลิ๊กที่ปุ่มด้านล่าง',
      customerService: 'ลูกค้าสัมพันธ์',
      please: 'กรุณาติดต่อ mClinica',
    },
    request: 'ขอเปิดใช้งาน',
    requestAccess: 'ขอการเข้าถึง',
    title: 'SwipeRx รุ่นเบต้า',
  },
  app: {
    agree: 'ฉันยอมรับ',
    seeAll: 'ดูทั้งหมด',
    appName: 'SwipeRx การจัดซื้อ v{{version}}',
    bottomNav: {
      cart: 'ตระกร้า',
      home: 'หน้าแรก',
      orders: 'รายการ',
      payments: 'ชำระเงิน',
      products: 'ผลิตภัณฑ์',
      account: 'บัญชีผู้ใช้',
      favorites: 'สิ่งที่ถูกใจ',
    },
    credit: {
      pay: 'จ่ายทันที',
      total: 'วงเงินทั้งหมดของคุณคือ',
      used: 'คุณใช้ไปแล้ว {{amount}}',
    },
    dpd: {
      blockMessage:
        'ดูเหมือนว่าคุณถูกบล็อกไม่ให้ทำธุรกรรมเนื่องจากคุณมีใบแจ้งหนี้ {{pastDueInvoiceCount}} ใบที่เกินกำหนดชำระเป็นเวลา {{dpd}} วัน',
    },
    suspended: {
      heading: 'บัญชีของคุณถูกระงับชั่วคราว',
    },
    orders: {
      used: 'วงเงินที่ใช้ไป',
      total: 'วงเงินทั้งหมด',
      availableCredit: 'วงเงินที่ใช้ได้',
      timeLeft: 'เหลือเวลา',
      prekursorDocsRequired: 'Prekursor docs required',
    },
    decline: 'ฉันไม่ยอมรับ',
    exit: 'ออก',
    helpSupport: 'ศูนย์ช่วยเหลือ',
    notFound: 'ไม่พบ',
    notification: {
      close: 'ปิด',
      retry: 'ลองอีกครั้ง',
    },
    searchInput: 'คุณต้องการค้นหายาชนิดไหน',
    tour: {
      start: 'เริ่มต้นเรียนรู้',
      gotIt: 'Got It!',
      welcomeTitle: 'ยินดีต้อนรับสู่ SwipeRx Pharmasource รูปแบบใหม่มาเริ่มต้นเรียนรู้ลูกเล่นแบบใหม่กันได้เลย',
      welcomeContent: 'เริ่มต้นเรียนรู้ลูกเล่นใหม่',
      naviTitle: 'แถบนำทาง',
      naviContent: 'แนะนำแถบนำทางแบบใหม่',
      homeTitle: 'เมนูหน้าหลัก',
      homeContent: 'คลิกกลับสู่หน้าหลัก',
      productTitle: 'เมนูผลิตภัณฑ์',
      productContent: 'คลิกดูผลิตภัณฑ์ทั้งหมด',
      orderTitle: 'เมนูรายการที่สั่ง',
      orderContent: 'คลิกดูรายการที่สั่งทั้งหมด',
      searchTitle: 'การค้นหา',
      searchContent: 'คุณสามารถค้นหาผลิตภัณฑ์จากยี่ห้อ หรือตัวยาสำคัญ',
      basketTitle: 'เมนูตะกร้าสินค้า',
      basketContent: 'ดูผลิตภัณฑ์ที่คุณใส่ตะกร้าได้ที่นี่',
      accountTitle: 'แถบบัญชีผู้ใช้',
      accountTitleContent: 'คลิกที่นี่เพื่อดูข้อมูลเกี่ยวกับบัญชีผู้ใช้',
      product: {
        productCardTitle: 'ข้อมูลผลิตภัณฑ์',
        productCardContent: 'คุณสามารถดูราคาได้ที่ข้อมูลผลิตภัณฑ์แบบใหม่!',
        productImageTitle: 'รูปภาพผลิตภัณฑ์',
        productImageContent: 'คุณสามารถขยายภาพของผลิตภัณฑ์ โดยการคลิกที่ภาพ',
        productFavoriteTitle: 'ปุ่มสิ่งที่ฉันถูกใจ',
        productFavoriteContent: 'ตอนนี้คุณสามารถกดถูกใจผลิตภัณฑ์ได้แล้วที่ปุ่มรูปหัวใจนี้',
        productAddContent: 'คลิก "เพิ่ม" เพื่อเพิ่มผลิตภัณฑ์ใส่ตะกร้าสินค้า',
        productQuantityContent:
          'เพิ่มหรือลดจำนวนผลิตภัณฑ์ได้ง่ายๆ เพียงกดที่เครื่องหมายบวก (+) เพื่อเพิ่ม, กดที่เครื่องหมายลบ (-) เพื่อลดจำนวน',
        viewCartTitle: 'ดูรถเข็น',
        viewCartContent: 'คลิกปุ่มนี้เพื่อดำเนินการตามคำสั่งซื้อของคุณ',
        quickFilterTitle: 'Filter',
        quickFilterContent:
          'ค้นหาประวัติการซื้อผลิตภัณฑ์ของคุณได้ที่นี่ และยังสามารถค้นหาผลิตภัณฑ์ตามตัวอักษร A-Z ได้อีกด้วย',
        searchProductByLetterContent: 'คุณสามารถค้นหาชื่อของผลิตภัณฑ์ได้จากตัวอักษร',
        appChatTitle: 'ฝ่ายบริการลูกค้า',
        appChat:
          'ต้องการติดต่อฝ่ายบริการลูกค้า สามารถส่งข้อความหาเราได้ที่นี่  หรือ Line @swiperx_th โทร +66-60-003-5146',
      },
      tierDiscount: {
        title: 'แนะนำ ส่วนลดเมื่อซื้อจำนวนมาก (bulk discounts)',
        content: 'ซื้อจำนวนมากในครั้งเดียวเพื่อส่วนลดที่มากขึ้น',
        expandBtn: 'คลิกเพื่อดูราคาส่วนลดเมื่อซื้อผลิตภัณฑ์จำนวนมาก',
        discountBtn:
          'หากต้องการรับส่วนลดเมื่อซื้อจำนวนมาก (bulk discounts) ให้คลิกที่ตัวเลือกใดตัวเลือกหนึ่งหรือเปลี่ยนจำนวนของผลิตภัณฑ์',
      },
      updates: {
        title: 'ใหม่ล่าสุด!',
        content: 'เราได้แนะนำลูกเล่นใหม่ที่จะช่วยให้การใช้งานแอพฯของคุณดียิ่งขึ้น',
        basketTitle: 'ตะกร้าสินค้า',
        basketContent: 'คุณมีตะกร้าสินค้าใหม่! คลิกที่ไอคอนเพื่อเพิ่มผลิตภัณฑ์ และค้นหาผลิตภัณฑ์เพิ่มเติม',
        accountTitle: 'เมนูบัญชีผู้ใช้',
        accountContent: 'เราได้เพิ่มเมนูบัญชีผู้ใช้ คุณสามารถดูรายละเอียดของวงเงิน, การชำระเงิน และอื่นๆได้ที่นี่',
      },
      wishlistLink: {
        title: 'เมนู Wishlist',
        content: 'คลิกที่นี่เพื่อดูหน้า Wishlist คุณสามารถดูผลิตภัณฑ์ที่คุณถูกใจได้ที่นี่ ',
      },
      searchPage: {
        welcomeTitle: 'Search Page',
        welcomeMessage: 'This is the page where you can search for products before you order!',
        mostPopularTitle: 'ยอดนิยม',
        mostPopularContent: `Now you can see what's the most popular product on SwipeRx!`,
        noResult: {
          welcomeTitle: 'Recommending a new product (1)',
          welcomeMessage: `You can now recommend a new product if you can't find the product you want to buy!`,
          suggestionTitle: 'Suggestion Button',
          suggestionContent: 'Tap on this button to suggest the product you want to recommend!',
        },
        packagingTitle: 'ค้นหาผลิตภัณฑ์ด้วยชื่อแบรนด์ หรือชื่อยาได้ที่นี่',
        packagingContent: 'ข้อมูลที่คุณค้นหาจะแสดงขี้น',
      },
      wishlistPage: {
        welcomeTitle: 'Wishlist Page',
        welcomeMessage:
          'Welcome to wishlist page. Here you can find some tabs which contains all products you marked as favorite, remind me, and products you suggested to SwipeRx PharmaSource.',
        favoritesTabTitle: 'Favorite Tab',
        favoritesTabContent: `This is the favorite tab. You'll find products you've marked as favorite!`,
        remindersTabTitle: 'Remind Me tab',
        remindersTabContent: `This tab contains products you asked SwipeRx belanja to remind you of`,
        comingSoonTabTitle: 'Coming Soon Tab',
        comingSoonTabContent: `And this tab contains all products you've recommended to SwipeRx!`,
        askCsTitle: 'In-App Chat',
        askCsContent: 'You can ask our Customer Success for further question through in-app chat!',
      },
      favoritesPage: {
        removeFavoriteTitle: 'ปุ่มสิ่งที่ฉันถูกใจ',
        removeFavoriteContent: 'You can remove a product from favorite by clicking the favorite button in this page',
      },
      remindersPage: {
        bellIconTitle: 'คลิกที่ไอคอน ‘ระฆัง’ เพื่อลบผลิตภัณฑ์ออกจากรายการ "แจ้งเตือน"',
        bellIconContent: 'จัดระเบียบได้ตามความต้องการของคุณ',
      },
      productDetailPage: {
        linkToPageTitle: 'Click the product name on the main page to access the product page',
        linkToPageContent:
          'On the main page, click the product name to access a complete description of the product you want to order',
        welcomeTitle: 'Product Page',
        welcomeMessage: 'Welcome to Product Page!',
        promotionTitle: 'Get information about product discounts if the product has promotion',
        promotionContent: 'Find the discount according to the minimum purchase amount according to your needs.',
        descriptionTitle: 'Find a complete explanation of the product description',
        descriptionContent:
          'Click on the tab "description" to see a more complete description of the product you want to order',
        compositionTitle: 'Find a description of the composition of the product',
        compositionContent:
          'Click on the tab "composition" to see more complete content of the product that you will order',
        coreMoleculeTitle: 'Find a description of the core molecule of the product',
        coreMoleculeContent:
          'Click on the tab "core molecule" to see more complete content of the product that you will order',
        categoryTitle: 'Get information related to product category',
        categoryContent: 'Check product categories by clicking on "category"',
        atcTitle: 'Get information related to product atc classification',
        atcContent: 'Check product atc classification by clicking on "atc-classification"',
        manufacturerTitle: 'Get information related to product the manufacturer"',
        manufacturerContent: 'Check product manufacturer by clicking on "manufacturer"',
        shippingTitle: 'Find information regarding shipping and returns policies',
        shippingContent: 'Complete information about the process of sending and returning goods',
        tierTitle: 'Choose the minimum purchase according to the discount you want',
        tierContent: 'Click on the minimum amount you want',
        ...tabsProductDetail,
      },
      homepage: {
        welcomeTitle: 'ยินดีต้อนรับเข้าสู่หน้าแรกโฉมใหม่ของ SwipeRx PharmaSource',
        welcomeMessage: 'เราพัฒนาระบบของเราให้เข้าถึงผลิตภัณฑ์ได้ง่ายกว่าเดิม เพื่อร้านยาของคุณ',
        loyaltyTitle: '-',
        loyaltyContent: '-',
        mainBannerTitle: 'พบกับผลิตภัณฑ์ที่มีข้อเสนอที่ดีที่สุดจากเราอยู่ที่นี่',
        mainBannerContent: 'รับข้อมูลล่าสุดและโปรโมชั่นที่น่าสนใจจากเราที่นี่',
        benefitsTitle: 'ประโยชน์ของการซื้อผลิตภัณฑ์ที่ SwipeRx',
        benefitsContent: 'SwipeRx PharmaSource แอปพลิเคชั่นจัดซื้อสำหรับร้านขายยาอันดับ 1 ในประเทศไทย',
        newProductsTitle: 'ค้นหาผลิตภัณฑ์ล่าสุดจากคลังได้ที่นี่',
        newProductsContent: 'ทำการค้นหาและเลือกซื้อผลิตภัณฑ์ที่คุณต้องการได้เลย',
        topWeeklyProductsTitle: 'ผลิตภัณฑ์ขายดีประจำสัปดาห์นี้',
        topWeeklyProductsContent: 'ค้นหาและเลือกซื้อผลิตภัณฑ์ที่ขายดีที่สุด',
        campaignListTitle: 'ข้อมูลโปรโมชั่นที่น่าสนใจได้รวบรวมไว้ที่นี่',
        campaignListContent: 'ติดตามโปรโมชั่นและผลิตภัณฑ์ในราคาที่โดนใจได้ที่นี่',
        hotDealsTitle: 'ราคาผลิตภัณฑ์โปรโมชั่นที่โดนใจที่สุด',
        hotDealsContent: 'เลือกซื้อผลิตภัณฑ์ราคาที่ดีที่สุดให้กับร้านขายยาของคุณได้ที่นี่',
        featuredProductsTitle: 'คัดสรรผลิตภัณฑ์เพื่อคุณโดยเฉพาะ',
        featuredProductsContent: 'เติมเต็มผลิตภัณฑ์ในร้านขายยาของคุณด้วยผลิตภัณฑ์ที่เราคัดสรรมาไว้ให้แล้ว',
        recentlyViewedTitle: 'ผลิตภัณฑ์ที่คุณเพิ่งค้นหาล่าสุด',
        recentlyViewedContent: 'ค้นหาและเลือกซื้อผลิตภัณฑ์ที่คุณต้องการอีกครั้งได้ที่นี่',
      },
      loyaltyLandingPage: {
        welcomeTitle: 'Welcome to the new Loyalty Landing Page!',
        welcomeMessage:
          'Now, Membership Details, Tier Information, and Rewards can be easily seen with a more intuitive and interactive interface',
        membershipDetailTitle: 'See your Membership Details',
        membershipDetailMessage: 'Click to view Membership Details and Points Information',
        activePointsTitle: 'See your Active Points',
        activePointsMessage: 'See points needed to get to unlock next level',
        mostPopularRewardsTitle: 'See Most Popular Rewards',
        mostPopularRewardsMessage: 'Click See All to view top 50 popular rewards',
        howToGetPointsTitle: 'See how you can get points',
        howToGetPointsMessage: 'Know how you can get points by doing things listed',
        allRewardsTitle: 'See All Rewards',
        allRewardsMessage: 'Click See All to view all rewards with more customized filters',
        missionWelcomeTitle: 'Welcome to our new Mission!',
        missionWelcomeMessage:
          'Get more rewards by completing your mission. You can always track your progress on this page. Complete your Mission now and claim your rewards!',
        featuredMissionsTitle: 'See your Featured Missions',
        featuredMissionsMessage: 'Click See All to view all missions',
      },
      loyaltyMembershipPage: {
        welcomeTitle: 'Welcome the to new Loyalty Membership Page!',
        welcomeMessage: 'You can see here Membership Information and Benefits, Point Information, and Redeemed Rewards',
        membershipDetailTitle: 'See your Membership Details',
        membershipDetailMessage: 'See how many points needed to retain your membership',
        pointSummaryTitle: 'Point Summary now moved to Membership Page',
        pointSummaryMessage: 'See your points and its distribution',
        myRewardsTitle: 'See your redeemed rewards',
        myRewardsMessage: 'Click See All to view all redeemede rewards',
        membershipBenefitsTitle: 'Membership Benefits',
        membershipBenefitsMessage: 'See benefits you can enjoy in your current tier',
      },
      loyaltyHotRewardPage: {
        welcomeTitle: 'Welcome to the new Hot Rewards Page!',
        welcomeMessage: 'Now, filtering and searching can be utilized to easily look for rewards.',
        searchTitle: 'New Reward Search',
        searchMessage: 'You can search specific reward based on its name',
        pointsRequiredTitle: 'New Points Required Filter',
        pointsRequiredMessage: 'You can filter rewards based on the minimum and maximum points needed',
        vendorTitle: 'New Vendor Filter',
        vendorMessage: 'You can filter rewards based on the vendor',
        categoryTitle: 'New Category Filter',
        categoryMessage: 'You can now filter rewards based on its category',
      },
      loyaltyMyRewardPage: {
        welcomeTitle: 'Welcome to the new My Rewards Page!',
        welcomeMessage: 'Now, searching can be utilized to easily looked for redeemed rewards',
        searchTitle: 'New My Reward Search',
        searchMessage: 'You can search redeemed reward based on its name',
      },
      loyaltyMissionCataloguePage: {
        [LoyaltyMissionStatus.ACTIVE]: {
          welcomeTitle: 'Welcome to the Mission Catalogue Page!',
          welcomeMessage: 'lorem ipsum dolor sit amet',
          missionTitle: 'Active Missions',
          missionMessage: 'lorem ipsum dolor sit amet',
        },
        [LoyaltyMissionStatus.COMPLETED]: {
          welcomeTitle: 'Welcome to the Mission Catalogue Page!',
          welcomeMessage: 'lorem ipsum dolor sit amet',
          missionTitle: 'Completed Missions',
          missionMessage: 'lorem ipsum dolor sit amet',
        },
        [LoyaltyMissionStatus.EXPIRED]: {
          welcomeTitle: 'Welcome to the Mission Catalogue Page!',
          welcomeMessage: 'lorem ipsum dolor sit amet',
          missionTitle: 'Expired Missions',
          missionMessage: 'lorem ipsum dolor sit amet',
        },
      },
      loyaltyMissionPage: {
        welcomeTitle: 'Welcome to the Mission Page',
        welcomeMessage: 'This is the welcome message',
      },
      productListingPage: {
        filterModalTitle: 'Filter Menu',
        filterModalContent: 'This menu will appear after you click the Sort & Filter button',
        filterToolbarTitle: 'We have updated the filter options',
        filterToolbarContent:
          'Aside from alphabetical filters, we have added more options to tailor your search results.',
        filterButtonTitle: 'Change order filter options',
        filterButtonContent: 'Click here again to change the order and filter options.',
      },
    },
    cartModal,
  },
  tierDiscount: {
    getUpTo: 'รับส่วนลดมากถึง {{discount}} %',
    buyMinimum: 'ซื้อขั้นต่ำ {{quantity}} ชิ้น',
    getDiscount: 'Get {{discount}}% discount',
    promoApplied: '{{discount}}% ใช้โปรโมชันแล้ว!',
  },
  tierDiscountSheet: {
    getUpTo: 'รับส่วนลดมากถึง {{discount}}%',
    getDiscount: 'Discount {{discount}}%',
    buyMinimum: '(ซื้อขั้นต่ำ {{quantity}} ชิ้น)',
    buy: 'สั่งซื้อ',
  },
  stockOut: {
    outOfStock: 'สินค้าหมด',
    lowOnStock: 'ผลิตภัณฑ์เหลือ {{stockLeft}} ชิ้นในสต๊อก!',
    maxQtyReached: 'ครบจำนวนสูงสุดแล้ว',
    notify: 'เตือนฉัน',
    cardTitle: 'ขออภัยสินค้าหมด',
    cardDescription: "กด 'แจ้งเตือน' เพื่อทำการรับแจ้งเตือนเมื่อมีผลิตภัณฑ์",
    dialogTitle: 'เราได้รับคำขอของคุณแล้ว!',
    dialogDescription: 'กรุณารอสักครู่! ทางเราจะแจ้งให้คุณทราบทันทีเมื่อผลิตภัณฑ์พร้อมจำหน่าย',
    okay: 'ตกลง',
    reminderRemoval: 'Will be removed at {{datetime}}.',
    maxQtyLimit: 'MAX {{value}}/day',
    maxQtyLimitReached: 'Maximum quantity reached, please place order up to {{ availableQty }} unit(s) today.',
    maxQtyMonthlyLimitReached: `You have reached the monthly maximum limit for this product. You may purchase it again next month.`,
  },
  cartPage: {
    cartItems: 'มี {{itemCount}} ชิ้นอยู่ในรถเข็นคุณ',
    getPrices: 'ดูราคา',
    noItems: 'ขณะนี้ไม่มีสินค้าในรถเข็นคุณ',
    oneItem: 'มีสินค้า 1 ชิ้นในรถเข็นคุณ',
    receivePrices: 'ขอราคาที่ดีที่สุดให้กับสินค้าเหล่านี้ทันที',
    requestPrices: 'ขอราคาที่ดีที่สุด',
    search: 'ค้นหาผลิตภัณฑ์',
    yourCart: 'รถเข็นของคุณ',
    unavailableProduct: 'สินค้าบางรายการถูกลบออกจากรถเข็นของคุณ เนื่องจากสินค้าหมด',
    viewCart: 'ดูรถเข็น',
  },
  errorPage: {
    closeApp: 'ออกจากแอปพลิเคชัน',
    contactMessage: 'หากคุณต้องการความช่วยเหลือเพิ่มเติม กรุณาติดต่อมาที่ beta@swiperxapp.com',
    contactUs: 'ติดต่อเรา',
    error: 'ผิดพลาด: $t(errors:{{message}})',
    pressingButton: 'หรือกดที่ปุ่มด้านล่าง',
    sorryMessage: 'ขออภัย เกิดข้อผิดพลาด',
    tryAgain: 'ลองอีกครั้ง',
  },
  errors: {
    'Activation request has already been approved': 'คำขอเปิดใช้งานได้รับการอนุมัติแล้ว',
    'Activation request has already been submitted': 'ได้ส่งคำขอเปิดใช้งานแล้ว',
    'Could not send activation request email': 'ไม่สามารถส่งอีเมลคำขอเปิดใช้งาน',
    'No active bank account has been set up for the user': 'ไม่พบการตั้งค่าบัญชีธนาคาร สำหรับผู้ใช้',
    'Order does not exist': 'ไม่มีรายการที่สั่ง',
    'Organization id or name must be provided': 'กรุณาระบุชื่อหรือ ID ของหน่วยงาน',
    'Organization not found': 'ไม่พบหน่วยงาน',
    'products parameter must be provided': 'กรุณาระบุ product parameter',
    'Request timed out': 'คำขอหมดเวลา',
    'Session does not exist': 'เซสชั่นไม่ได้อยู่',
    'Successfully requested for activation!': 'ส่งคำขอเปิดใช้งานแล้ว',
    'Search query must be provided': 'กรุณาระบุการค้นหา',
    'Successfully updated cart!': 'อัปเดตรถเข็นเรียบร้อยแล้ว!',
    'The net amount after tax exceeds your remaining balance': 'ราคาสุทธิหลังรวมภาษี เกินยอดเงินคงเหลือของคุณ',
    'User has not yet requested for activation': 'ผู้ใช้ยังไม่ได้ขอเปิดใช้งาน',
    'User account has not yet been activated': 'บัญชีผู้ใช้ยังไม่ได้เปิดใช้งาน',
    'You do not have access to this order': 'ไม่สามารถเข้าถึงรายการที่สั่งนี้ได้',
    'Network Problem': 'เกิดข้อผิดพลาดหรือหยุดชะงักของเครือข่าย',
  },
  helpPage: {
    chatMessage: 'สวัสดีค่ะ ฉันต้องการความช่วยเหลือ ',
    chatWithUs: 'ส่งข้อความหาเรา',
    contactUs: 'คลิกที่นี่เพื่อติดต่อเรา',
    gladToHelp: 'ทีมสนับสนุนของเราพร้อมช่วยคุณสั่งซื้อผลิตภัณฑ์ให้ถูกต้อง และทันเวลา',
    helpOrdering: 'ต้องการความช่วยเหลือในการสั่งซื้อ?',
    title: 'ช่วยเหลือและสนับสนุน',
  },
  creditLimitRequest: {
    title1: 'Request',
    title2: 'Credit Limit',
    step: 'Steps',
    step1: 'Please fill out the following form and upload the requested documents',
    step2: 'Our team will then review the credit limit request',
    currentCreditLimit: 'Your current credit limit:',
    inputYourNewCreditLimit: 'Enter your new credit limit',
    termsAndCondition: 'By clicking CONTINUE, you agree with the terms and condition.',
    continue: 'CONTINUE',
    formLinkLabel: 'Form Link',
    overLimitMessage:
      'Sorry, the credit limit that you are applying for has exceeded SwipeRx application allowed limit. For more info please contact our Customer Support',
    supportLinkMessage:
      "Hi i'm {{userName}}, requesting credit limit upgrade {{pharmacyName}} pharamacy in the amount of {{creditLimit}}",
    supportLinkLabel: 'link to WA',
    errorRequestLimit: 'Your request is below than your current credit limit',
  },
  creditLimitRequestStatus: {
    appBarTitle: 'Request Credit Limit',
    requestInProgress: 'Your request is being processed',
    requestInProgressSubtitle: 'Our credit team is still reviewing your request and will inform you afterward.',
    requestAccepted: 'Congratulation!\nYour request is accepted.',
    newCreditLimit: 'Your credit limit is updated into {{newCreditLimit}}',
    requestRejected: 'Kindly apologize we are not able to proceed with your request.',
    reason: "Here's why",
    approvalNotes: 'Notes',
    requestAgain: 'Request Again',
    thankYou: 'Thank you for completing the form.',
    willInform: 'We will notify you once we have an update on your credit limit request around 2-3 business days',
    checkProgress: 'check progress',
  },
  increaseCreditPage: {
    apply: 'ขอเพิ่มวงเงิน',
    chatMessage: 'สวัสดีค่ะ ฉันขอเพิ่มวงเงินได้ไหม',
    orderLimit: 'Your total order limit',
    text: {
      line1:
        'คุณสามารถเพิ่มวงเงินโดยอัตโนมัติเป็น THB xxx,xxx โดยทำธุรกรรมและชำระใบแจ้งหนี้ให้ตรงเวลา เพียงทำตาม 3 ขั้นตอนง่ายๆ',
      line2: 'สั่งซื้ออย่างน้อย THB 00000 ต่อเดือน ติดต่อกันเป็นเวลา 3 เดือน',
      line3: 'ชำระใบแจ้งหนี้ภายใน 37 วัน นับจากวันที่ออกใบแจ้งหนี้ ',
      line4: 'เราจะขยายวงเงิน THB xxxx ให้คุณแบบอัตโนมัติ และจะแจ้งคุณทันทีเมื่อคุณได้รับการเพิ่มวงเงิน',
      line5:
        'สมัครเพื่อขอรับวงเงินสูงสุด THB 000000 จาก Modelku โดยแนบรายการเคลื่อนไหวบัญชีอย่างน้อย 3 เดือน ติดต่อแผนกลูกค้าสัมพันธ์ (beta@swiperxapp.com) ในเวลาทำการเพื่อให้เราช่วยเหลือคุณในขั้นตอนการสมัคร',
    },
    title: 'เพิ่มวงเงิน',
  },
  orderHeader: {
    deliveryDate: 'วันที่จัดส่ง',
    invoiceDate: 'วันที่ออกใบแจ้งหนี้',
    invoiceNumber: 'หมายเลขใบแจ้งหนี้',
    orderDate: 'วันที่สั่งซื้อ',
    orderNumber: 'Order ID',
    paymentDueDate: 'กำหนดชำระ',
    status: {
      pending: 'อยู่ระหว่างดำเนินการ',
      accepted: 'ได้รับการยอมรับ',
      delivered: 'จัดส่งเรียบร้อย',
      completed: 'สำเร็จ',
    },
  },
  orderPage: {
    completeOrder: 'รายการสั่งที่เสร็จสิ้นแล้ว',
    deliveryProof: 'หลักฐานการจัดส่ง',
    discount: 'ส่วนลด',
    documents: 'เอกสาร',
    invoice: 'ใบแจ้งหนี้',
    viewTaxInvoice: 'ดูใบกำกับภาษี',
    noTaxInvoice: 'ยังไม่ได้ส่งหรืออนุมัติ ใบกำกับภาษี',
    orderDetail: 'รายละเอียดรายการที่สั่ง',
    products: 'ผลิตภัณฑ์',
    subtotal: 'ยอดรวม',
    subtotalDiscount: 'ยอดรวมหลังหักส่วนลด',
    totalAmount: 'ยอดรายการที่สั่งทั้งหมด',
    totalAmountAfterVat: {
      total: 'จำนวนเงินทั้งหมด',
      vat: '(อาจรวมภาษี)',
    },
    unit: 'หน่วย',
    units: 'หน่่วย',
    vatAmount: 'VAT (ภาษีมูลค่าเพิ่ม)',
    prekursor: {
      title: 'Prekursor Document',
      add: 'Add Prekursor Document',
      uploadSuccess: {
        title: 'Yay! your precursor document image uploaded succesfully',
        info: 'You can check status of this order in Activities tab',
        action: 'OKAY',
      },
      uploadFailed: {
        title: 'Oops! The upload of your precursor document failed',
        info: 'failed file:',
        action: 'RETRY',
      },
      uploadApprovedFailed: {
        title: 'Document already approved, can not update document',
        info: 'failed file:',
        action: 'RETRY',
      },
      prekursorTooltipTour: {
        preUpload: 'Click here to upload Prekursor document',
        postUpload: 'Tap here to preview or edit Prekursor document',
        editUpload: 'Tap here to edit Prekursor document image',
      },
    },
  },
  ordersPage: {
    schedulePayment: 'Schedule Payment',
    deliveryDue: 'จัดส่งภายใน',
    history: 'ประวัติย้อนหลัง',
    increaseCreditLimit: 'เพิ่มวงเงินรวมทั้งหมดของคุณ',
    learnMore: 'เรียนรู้เพิ่มเติม',
    orderDate: 'วันที่สั่งซื้อ',
    orderNumber: 'Order ID',
    paymentDue: 'ครบกำหนดชำระเงิน',
    paymentDueDate: 'วันครบกำหนดชำระเงิน',
    howToPay: 'วิธีการชำระเงิน',
    contactNow: `กรุณาติดต่อฝ่ายลูกค้าสัมพันธ์หากมีข้อสงสัย (+66 6 0003 5146)`,
    totalAfterVat: {
      afterVat: '(อาจรวมภาษี)',
      total: 'จำนวนเงินทั้งหมด',
    },
    viewDetails: 'ดูรายละเอียด',
    status: {
      pending: 'อยู่ระหว่างดำเนินการ',
      accepted: 'ได้รับการยอมรับ',
      delivered: 'จัดส่งเรียบร้อย',
      completed: 'สำเร็จ',
    },
    goToSearch: 'ไปที่การค้นหา',
    pendingPayments: 'การชำระเงินที่รอดำเนินการ',
    daysPastDue: 'จำนวนวันที่เลยกำหนด',
    Due: 'ครบกำหนด',
    invoiceNotYetIssued: 'กำลังดำเนินการ',
    orderID: 'Order ID',
    noPaymentsDueToday: 'ไม่พบการชำระเงินที่ต้องจ่ายวันนี้',
    payEarly: 'คุณสามารถชำระเงินล่วงหน้าเพื่อได้รับวงเงินกลับมา',
    selectAnotherDate: 'เลือกวันอื่นเพื่อตรวจสอบการชำระเงิน',
    overdueInvoices: 'คุณมีใบแจ้งหนี้ที่ค้างชำระ คลิกเพื่อดูยอดเงินที่ต้องจ่าย',
    noOrders: 'ไม่มีรายการที่สั่งในขณะนี้',
    serchForAProduct: 'ค้นหาผลิตภัณฑ์เพื่อสั่งซื้อ',
    totalDueAmount: 'ยอดค้างชำระทั้งหมด',
    noPaymentDueBy: 'ไม่่มียอดค้างชำระใน',
    recentOrders: 'รายการที่สั่งล่าสุด',
    viewAll: 'ดูทั้งหมด',
    noRecentOrders: 'ไม่มีรายการที่สั่งในขณะนี้',
    youHaveNoTransactions: 'ค้นหาสินค้าเพื่อสั่งซื้อ',
    viewCompleteHistory: 'ดูประวัติการสั่งซื้อที่สำเร็จแล้ว',
    COH: 'ประวัติการสั่งซื้อที่สำเร็จแล้ว',
    back: 'กลับ',
    loading: 'กำลังโหลด ...',
    done: 'เสร็จสิ้น',
    paidOn: 'ชำระเงินเมื่อ:',
    selectDate: 'เลือกวันที่คุณต้องการชำระเงินและตรวจสอบยอดที่ต้องชำระ',
    bankTransfer: 'โอนเงินผ่านธนาคาร',
    howToPayDetails: 'คุณอาจชำระเงินล่วงหน้าโดยใช้รายละเอียดธนาคารต่อไปนี้',
    transfer: 'โอน',
    on: 'บน',
    toThisBankAccount: 'ไปยังบัญชีธนาคารนี้',
    bank: 'ธนาคาร',
    accNum: 'เลขบัญชี',
    accName: 'ชื่อ',
    cashPay: 'Cash on pharmacies',
    prepare: 'เตรียม',
    cashInPharmacy: 'โดยเงินสดที่ร้านยาของคุณ และนัดรับสินค้าโดยติดต่อฝ่ายลูกค้าสัมพันธ์ ด้านล่าง',
    selectDateSchedule: 'เลือกวันที่',
    endOfPage: 'คุณมาถึงบรรทัดสุดท้ายของหน้านี้',
    selectDueDateHead: 'เลือกวันครบกำหนด',
    checkThisDate: 'ตรวจสอบวันที่นี้',
    paidOnTime: 'ชำระเงินเมื่อ',
    partialPayment: 'กำลังดำเนินการชำระเงิน',
  },
  payPage: {
    bank: {
      accountNumber: 'เลขบัญชี',
      label: 'ธนาคาร',
      name: 'ชื่อ',
    },
    contactNow: `กรุณาติดต่อฝ่ายลูกค้าสัมพันธ์หากมีข้อสงสัย (+66 6 0003 5146)`,
    contactService: 'ติดต่อฝ่ายลูกค้าสัมพันธ์เพื่อนัดรับเงินสด',
    dueAmount: 'ยอดรวมที่ครบกำหนดชำระ',
    method1: 'วิธีที่ 1: โอนเงินผ่านธนาคาร',
    method2: 'วิธีที่ 2: ชำระเงินสดที่ร้านขายยา',
    noBankAcc: 'ยังไม่มีการตั้งค่าข้อมูลจากธนาคารในบัญชีนี้ กรุณาติดต่อฝ่ายบริการลูกค้า',
    howToPay: 'วิธีชำระเงิน',
    title: 'ชำระเงินตอนนี้',
    totalAmountOrdered: 'ยอดรวมที่สั่ง',
    dueNow: 'ครบกำหนดตอนนี้',
    orderNumber: 'Order ID',
    orderDate: 'วันที่สั่งซื้อ',
    vat: 'หลังรวมภาษีมูลค่าเพิ่ม',
    dueWeek: 'ครบกำหนดภายในหนึ่งสัปดาห์',
    due: 'ครบกำหนด',
  },
  paymentStatus: {
    due: {
      label: 'ครบกำหนด',
      message: 'ชำระเงินล่วงหน้าเพื่อเพิ่มโควต้ารายการที่สั่ง',
    },
    dueSoon: {
      label: 'จะครบกำหนดเร็วๆนี้',
      message: 'ชำระเงินล่วงหน้าเพื่อเพิ่มโควต้ารายการที่สั่ง',
    },
    overDue: {
      label: 'ใบแจ้งหนี้ที่ครบกำหนด',
      message: 'หากคุณชำระเงินแล้ว ไม่ต้องสนใจข้อความนี้',
    },
  },
  pricesPage: {
    yourAccountWasDowngraded: 'Oops! Your account status was downgraded.',
    downgradedAccountAction: 'Please provide the new license to buy this product',
    verifyAccount: 'Verify your account',
    acceptBuy: 'ยอมรับและสั่งซื้อ',
    agree: 'ฉันยอมรับ',
    availableCredit: 'วงเงินที่มีอยู่',
    bestPrices: 'เราเจอราคา PBF ที่ดีที่สุด',
    completeOrder: 'รายการสั่งที่สำเร็จแล้ว',
    continue: 'ดำเนินการต่อ',
    creditAfterOrder: 'วงเงินหลังจากที่สั่งซื้อ',
    creditLine: 'Credit Line',
    creditForOrder: 'วงเงินสำหรับการสั่งซื้อนี้ (หลังรวมภาษีมูลค่าเพิ่ม)',
    decline: 'ฉันไม่ยอมรับ',
    deliveryTerms: 'เงื่อนไขการส่งสินค้า',
    distributor: 'ผู้จัดจำหน่าย',
    enoughCredit: 'คุณมีวงเงินเพียงพอ',
    manyCreatedOrders: 'หมายเลขรายการที่สั่งของคุณคือ',
    goBack: 'ย้อนกลับ',
    purchase: 'สั่งซื้อ',
    confirmation: 'คุณแน่ใจว่าคุณต้องการดำเนินการต่อ',
    goodNews: 'ข่าวดี!',
    helloBoss: 'สวัสดีค่ะ!',
    prekursorDocumentRequired: 'You will have to upload an Prekursor document after order confirmation',
    readyToReceiveOrders: 'ผู้จัดจำหน่ายของเราพร้อมที่จะรับรายการที่สั่งของคุณ',
    tryToFixTheFollowingOrders: 'ดูเหมือนว่าเราพบปัญหาบางอย่างกับรายการที่สั่งของคุณ ลองแก้ไขดังนี้',
    fullfillOrders: 'เพื่อตอบสนองรายการที่สั่งของคุณ!',
    weFound: 'เราพบ ',
    yourOrder: 'รายการที่สั่งของคุณ',
    below: 'คือ ข้างล่าง',
    orders: 'รายการที่สั่ง',
    cartTotal: 'You have {{total}} items in your basket.',
    needsToBeAdjusted: '<0>รายการที่สั่ง</0> ต้องได้รับการแก้ไข',
    ordersReadyToServed: 'รายการที่สั่งของคุณพร้อมแล้ว!',
    paymentDate: 'Make payment on {{date}}',
    subtotal: 'Subtotal',
    addMoreProduct: 'Add more products',
    minimumInvoice: 'รายการที่สั่งของคุณ คือ ข้างล่าง มูลค่าใบแจ้งหนี้ขั้นต่ำ\n<0>{{amount}}<0>.',
    minimumInvoiceDesc: 'Please add the quantity, or get the item(s) in your basket',
    notEnoughCredit: ' มีวงเงินเพียงพอสำหรับรายการที่สั่งนี้',
    orderTotal: 'รายการที่สั่งรวม',
    totalDiscount: 'You have saved {{amount}}!',
    totalOrderBelowMinimum: {
      itSeems: 'อืมม ดูเหมือนรายการที่สั่งทั้งหมดของคุณสำหรับ',
      belowMinimum: 'มีมูลค่าต่ำกว่ามูลค่าใบแจ้งหนี้ขั้นต่ำ',
    },
    howCanIProceed: 'ฉันจะดำเนินกาารต่อได้อย่างไร',
    clickHereToProceed: 'คลิกที่นี่เพื่อดำเนินการต่อ',
    proceedWithOrder: 'ดำเนินการกับรายการที่สั่ง',
    noAlternativeProductsFound: 'ไม่พบผลิตภัณฑ์ที่คล้ายคลึงกัน',
    close: 'ปิด',
    orderSummary: 'สรุปรายการสั่งซื้อ',
    eVat: 'ภาษีมูลค่าเพิ่ม',
    totalAfterVat: 'จำนวนเงินทั้งหมด (อาจรวมภาษี)',
    includesVat: 'Includes VAT {{ taxRate }}',
    paymentSummary: 'สรุปการชำระเงิน',
    creditForThisOrder: 'วงเงินสำหรับการสั่งซื้อ (เมื่อรวมภาษีมูลค่าเพิ่ม)',
    notEnoughCreditForOrder: 'ขออภัยวงเงินของคุณไม่เพียงพอสำหรับการสั่งซื้อครั้งนี้',
    notEnoughCreditForOrderSubtitle: 'Please pay the credit first',
    payCredit: 'Make a payment',
    looksLikeNotEnoughCredit: 'วงเงินของคุณไม่เพียงพอสำหรับการสั่งซื้อครั้งนี้',
    looksLikeBelowMinimumInvoiceAmount: 'คำสั่งซื้อมีมูลค่าต่ำกว่ามูลค่าใบแจ้งหนี้ขั้นต่ำ',
    letsTryTheFollowingOptions: 'ลองตัวเลือกต่อไปนี้',
    reduceProductQuantity: 'ลดจำนวนผลิตภัณฑ์บางอย่าง',
    increaseProductQuantity: 'เพิ่มจำนวนผลิตภัณฑ์บางอย่าง',
    cancelDistributorOrder: 'ยกเลิกคำสั่งซื้อจาก {{ distributor }}',
    cancelOrder: 'ยกเลิกคำสั่งซื้อ',
    confirmCancelOrder: 'ต้องการที่จะยกเลิกคำสั่งซื้อจากผู้จัดจำหน่ายนี้หรือไม่?',
    thisWillRemoveProductsBelow:
      'การดำเนินการนี้จะยกเลิกเฉพาะผลิตภัณฑ์ด้านล่างนี้ ผลิตภัณฑ์อื่น ๆ จะยังคงจัดส่งโดยผู้จัดจำหน่ายรายอื่น',
    otherDistributorsOfferingSameProduct: 'ผู้จัดจำหน่ายรายอื่นสนอผลิตภัณฑ์ประเภทเดียวกันโดยมีราคาสูงกว่าเล็กน้อย',
    transferSomeProductsBelow: 'ทำการโอนย้ายผลิตภัณฑ์ด้านล่าง',
    weHaveCancelledYourOrder: 'เราได้ยกเลิกคำสั่งซื้อกับตัวแทนจำหน่ายนี้แล้ว ',
    proceedToYourBasket: 'ดำเนินการไปที่ตะกร้าของคุณ',
    transferProducts: 'โอนย้ายผลิตภัณฑ์',
    currentlyLookingForAlternatives: 'โปรดรอสักครู่... ขณะนี้เรากำลังค้นหา ผู้จัดจำหน่ายรายอื่น ',
    transferAllProducts: 'โอนย้ายผลิตภัณฑ์ทั้งหมด',
    transferProductsToAnotherDistributor: 'โอนย้ายผลิตภัณฑ์ไปยังผู้จัดจำหน่ายรายอื่น',
    productMoved: 'ผลิตภัณฑ์นี้ได้ถูกนำออกไปแล้ว เพราะผู้จัดจำหน่ายรายอื่นไม่มีผลิตภัณฑ์นี้',
    productMovedDueToPriceDecrease: 'ผลิตภัณฑ์นี้ได้ถูกนำออกไปยังผู้จัดจำหน่ายรายอื่น เพราะเราพบตัวเลือกอื่นที่ดีกว่า',
    confirmOrder: 'ยืนยันรายการที่สั่ง',
    waitAMinute: 'โปรดรอสักครู่...',
    forAssistance: 'หากต้องการความช่วยเหลือเพิ่มเติม... กรุณาติดต่อ',
    customerSupportViaWhatsApp: `ฝ่ายลูกค้าสัมพันธ์ (+66 6 0003 5146)`,
    goBackAndCheck: 'ย้อนกลับและตรวจสอบ',
    ok: 'Ok',
    orderNow: 'สั่งซื้อเลย {{ totalItem }}',
    earnPoints: 'Earn <0>{{ points }}</0>',
    proceedPurchase: 'ต้องการดำเนินการต่อกับคำสั่งซื้อนี้?',
    submitOrderTo: 'ส่งรายการที่สั่งให้',
    pleaseUploadPrekursorDocument: `And please upload pending prekursor document tab after confirm this order`,
    reason: 'เหตุผล:',
    productNoLongerAvailable: 'ผลิตภัณฑ์หมด',
    membershipChanged:
      'Your account status just got downgraded,  Please submit your renewed license to buy this product',
    sipnapLicenseRequired: 'Product needs sipnap license to be bought',
    maxQtyLimitReached: `You've reached the maximum unit(s) allowed for the purchase of the product. It cannot be purchased again until tomorrow.`,
    maxQtyMonthlyLimitReached: `You have reached the monthly maximum limit for this product. You may purchase it again next month.`,
    priceIncrease: 'ราคาผลิตภัณฑ์ได้เพิ่มขึ้นจากการสั่งซื้อครั้งก่อน',
    priceDecrease: 'ราคาผลิตภัณฑ์ลดลงจาก {{ price }}',
    tour: {
      start: 'เริ่มต้น',
      welcomeTitle: 'ยินดีต้อนรับเข้าสู่ตระกร้าสินค้า',
      welcomeContent: 'มาดูลูกเล่นใหม่ของพวกเรากัน!',
      overviewTitle: 'ภาพรวมตะกร้า',
      overviewContent: 'คุณสามารถค้นหาข้อมูลผลิตภัณฑ์ที่คุณต้องการสั่งซื้อได้ที่นี่',
      productTitle: 'ผลิตภัณฑ์',
      productContent: 'คุณสามารถเห็นผลิตภัณฑ์ทั้งหมดที่คุณเพิ่มเข้าไปได้ที่นี่',
      orderSummaryTitle: 'สรุปการชำระเงิน',
      orderSummaryContent: 'นี่คือยอดรวมทั้งหมดจากการสั่งซื้อของคุณ',
      paymentSummaryTitle: 'ยอดสรุปการชำระเงิน',
      paymentSummaryContent: 'ดูว่าคุณมีวงเงินเพียงพอสำหรับคำสั่งซื้อนี้ ได้ที่นี่',
      deliveryTermsTitle: 'เงื่อนไขการจัดส่งสินค้า',
      deliveryTermsContent: 'ตรวจสอบเงื่อนไขการจัดส่งสินค้าของการรายการที่สั่ง',
      orderNowTitle: 'สั่งซื้อเลย',
      orderNowContent: "หากทุกอย่างเป็นไปตามความต้องการ, คลิก 'สั่งซื้อ'",
      product: {
        productCardTitle: 'บัตรผลิตภัณฑ์',
        productCardContent: 'คุณสามารถดูราคาผ่านบัตรผลิตภัณฑ์ได้แล้วตอนนี้!',
        productImageTitle: 'รูปภาพผลิตภัณฑ์',
        productImageContent: 'คุณสามารถขยายภาพของผลิตภัณฑ์ได้โดยการคลิกที่ภาพ',
        productAddContent: 'คลิกที่ "เพิ่ม" เพื่อเพิ่มผลิตภัณฑ์เข้าตะกร้าสินค้า',
        productQuantityContent:
          'เพิ่มหรือลดจำนวนผลิตภัณฑ์ได้ง่ายๆ เพียงกดที่เครื่องหมายบวก (+) เพื่อเพิ่มจำนวน, กดที่เครื่องหมายลบ (-) เพื่อลดจำนวน',
        viewCartTitle: 'ดูรถเข็น',
        viewCartContent: 'คลิกปุ่มนี้เพื่อดำเนินการตามคำสั่งซื้อของคุณ',
        searchProductByLetterContent: 'คุณสามารถค้นหาชื่อของผลิตภัณฑ์ได้จากตัวอักษร',
      },
    },
    minValue: {
      heading: 'คำสั่งซื้อมีมูลค่าต่ำกว่าใบแจ้งหนี้ขั้นต่ำ',
      increase: 'กรุณาเพิ่มยอดคำสั่งซื้อของคุณ หรือเพิ่มจำนวนผลิตภัณฑ์',
      minValue: 'รายการคำสั่งซื้อของคุณต้องมียอดรวมอย่างน้อย {{minAmount}}.',
    },
    belowMinimum: {
      title: 'Oops! You need to recheck your basket',
      subTitle: 'Your total order is below the minimum invoice value',
      actionButton: 'Go back to my basket',
    },
    noEnoughCredit: {
      title: "Oops! You don't have enough credit for your order",
      subTitle: 'Please make a payment to your credit first or check your basket',
      actionButton: 'Make a payment',
      cancel: 'Cancel',
    },
    suspended: {
      heading: 'บัญชีของคุณถูกระงับชั่วคราว',
      reason: 'สาเหตุของการระงับบัญชีผู้ใช้งาน',
    },
    dpd: {
      blockMessage:
        'ดูเหมือนว่าคุณถูกบล็อกไม่ให้ทำธุรกรรมเนื่องจากคุณมีใบแจ้งหนี้ {{pastDueInvoiceCount}} ใบที่เกินกำหนดชำระเป็นเวลา {{dpd}} วัน',
    },
    voucher: {
      info: 'คุณสามารถใส่ Voucher Code หลังจากยืนยันการสั่งซื้อผลิตภัณฑ์',
      chooseVoucher: 'เลือกโค้ดส่วนลด',
      voucherApplied: 'เลือกโค้ดส่วนลด {{amount}} บาท',
    },
    oops: 'Oops!',
    encounteredAnErrorWithYourOrder: 'เราพบข้อผิดพลาดกับคำสั่งซื้อของคุณ',
    productsChangedAvailability:
      'ผลิตภัณฑ์บางชนิดอาจมีการเปลี่ยนแปลงราคาและจำนวนผลิตภัณฑ์ในคลังสินค้า โปรดตรวจสอบตะกร้าสินค้า และลองอีกครั้งในภายหลัง',
    orderPaymentError: 'We encountered an error with the payment provider. Please try again later',
    oneCreatedOrder: 'หมายเลขรายการที่สั่งของคุณคือ',
    oneDistributor: 'ผู้จัดจำหน่าย 1 รายกำลังให้บริการคุณ',
    products: 'ผลิตภัณฑ์',
    items: 'รายการ',
    item: 'รายการ',
    orderFor: 'รายการที่สั่งสำหรับ',
    searchMore: 'ค้นหาเพิ่มเติม',
    servingDistributors: 'ผู้จัดจำหน่าย {{distributorCount}} รายกำลังให้บริการคุณ',
    success: 'เราได้ส่งรายการสั่งซื้อของคุณแล้ว',
    timeToCreateAnOrder: 'ถึงเวลาสั่งซื้อแล้ว!',
    youHaveNoProductOnCart: 'You have no products on your cart',
    firstProductsText: 'ขั้นตอนแรกในการหาราคาที่ดีที่สุด คือ การหาผลิตภัณฑ์ที่คุณต้องการ',
    searchProducts: 'ค้นหาผลิตภัณฑ์',
    title: 'ตะกร้าสินค้าของคุณ',
    totalAmount: {
      total: 'ยอดรวมทั้งหมด',
      vat: 'หลังรวมภาษีมูลค่าเพิ่ม',
    },
    totalCreditLimit: 'วงเงินรวมทั้งหมด',
    totalOrderAmount: 'ยอดรวมรายการสั่งซื้อ',
    vat: 'ภาษีมูลค่าเพิ่ม ({{amount}})',
    weFoundBestPrices: 'ยินดีด้วย! เราค้นพบราคาที่ดีสุดใน Distributor ทุกราย',
    payment: {
      heading: { th: 'การชำระเงิน' },
      terms: { th: 'โปรดทำการชำระเงินภายใน 4 ชั่วโมง' },
    },
    delivery: {
      heading: { th: 'เงื่อนไขการจัดส่ง' },
      deliveryHours: { th: 'สั่งซื้อสินค้าก่อน 10 โมงเช้า จะได้สินค้าภายในวันที่สั่งซื้อ' },
      conditions: { th: 'สั่งซื้อสินค้าหลัง 10 โมงเช้า จะได้สินค้าภายในวันถัดไป' },
      conditions2: { th: 'เวลาทำการจัดส่งสินค้าจะเป็นช่วงเวลาตั้งแต่ 14.00น - 18.00น' },
      terms: { th: '(งดจัดส่งสินค้าในวันเสาร์ - อาทิตย์ และวันหยุดนักขัตฤกษ์)' },
    },
    return: {
      heading: { th: 'ส่งคืนผลิตภัณฑ์' },
      conditions: {
        th: 'ากคุณต้องการคืนสินค้า คุณมีเวลา 1 วันทำการ ตั้งแต่วันที่ส่งสินค้าเพื่อคืนสินค้าแบบไม่เสียค่าบริการ\n\nนี่คือตัวเลือกเพื่อคืนสินค้าของคุณ:\n1.ส่งคืนที่จุดส่งมอบ (point of delivery)\n2.ติดต่อฝ่ายลูกค้าสัมพันธ์ ที่ Line: @swiperx_th\n\nเงื่อนไขพิเศษ\n1. บางรายการของเราจะไม่สามารถคืนได้\n2. การคืนสินค้าหลังจาก 1 วันทำการ นับจากวันที่ส่งสินค้าของคุณ ขึ้นอยู่กับนโยบายของผู้จัดจำหน่าย',
      },
    },
    contact: {
      heading: { th: 'สนับสนุนลูกค้า' },
      terms: { th: 'ท่านสามารถติดต่อขอใบกำกับภาษีได้ที่' },
      conditions: { th: 'ท่านสามารถติดต่อขอใบกำกับภาษีได้ที่' },
      line: { th: 'Line : @swiperx_th' },
      phone: { th: '+66-60-003-5146' },
      officeHour: { th: 'จันทร์ - ศุกร์ / 09.00 - 18.00 น' },
    },
    unavailableProduct: 'สินค้าบางรายการถูกลบออกจากรถเข็นของคุณ เนื่องจากสินค้าหมด',
    lowOnStock: 'ผลิตภัณฑ์เหลือ {{stockLeft}} ชิ้นในสต๊อก!',
    maxQtyReached: 'ครบจำนวนสูงสุดแล้ว',
    outOfStock: 'สินค้าหมด',
    notify: 'เตือนฉัน',
    unnotify: 'Cancel Reminder',
    unpaidOrder: 'Unpaid Order',
    limitedPaymentInfo: 'You still have an unpaid order. Kindly pay your previous order.',
    clearCart: {
      title: 'คุณแน่ใจหรือไม่?',
      content: 'คุณกำลังจะทำการยกเลิก {{itemCount}} ผลิตภัณฑ์ จากตะกร้าสินค้านี้?',
      no: 'ยกเลิก',
      delete: 'ลบ',
    },
    addedToFavorite: 'Added to wishlist',
    removedFromFavorite: 'Removed from wishlist',
    selectAll: 'Select all',
    getProductSoon: 'Get the products soon',
    seeDeliveryEstimation: 'Get products on <0>{{ estimationDate }}</0>',
    checkoutBeforeCutoff: 'Transact before cut-off time {{cutoffTime}}to receive the products by delivery date',
    prekursorDeliveryCondition:
      'To ensure the products are delivered by the same estimated delivery date, kindly upload the prekursor documents.',
    prekursorDelivery: 'Pending prekursor',
    instruction: 'Instruction',
    deleteConfirmation: {
      prefix: 'Are you sure you want to delete',
      label: 'the\n selected items',
      suffix: 'from your basket?',
      cancel: 'cancel',
      confirm: 'delete',
    },
    cartChangedConfirmation: {
      title: 'Oops! You need to recheck your basket',
      subtitle: 'Your order needs to be adjusted.',
      close: 'Go Back to My Basket',
    },
    maxQtyLimit: 'MAX {{value}}/day',
    maxPurchaseLimitReached: {
      alert: {
        titleOneProduct: `Oops! You've reached the maximum unit(s) allowed for the purchase of the {{ productName }}`,
        titleManyProducts: `Oops, You’ve reached the maximum unit(s) allowed for the purchase of some products`,
        adjustQty: 'Please adjust the product quantity',
        changeProductQty: 'change product quantity',
        cancel: 'Cancel',
      },
      summary: {
        maxPurchaseLimitReached: "You've reached the maximum unit(s) allowed for the purchase of the item.",
        pleaseAdjustQtyOneProduct: 'Please adjust the {{ productName }} quantity below',
        pleaseAdjustQtyManyProducts: 'Please adjust the products quantity below',
        changeProductQty: 'change product quantity',
      },
    },
    findSimilarProduct: 'Find Similar',
    remindMeModalTitle: 'Product reminder has been activated!',
    remindMeModalBody: 'We will let you know when the product becomes available again.',
    okay: 'Okay',
  },
  requestingPage: {
    checking: 'เรากำลังตรวจสอบกับผู้จัดจำหน่าย เพื่อค้นหาราคาที่ดีที่สุดสำหรับคุณ กรุณารอสักครู่',
    findingProducts: 'ค้นหาราคาที่ดีที่สุดสำหรับคุณ',
  },
  searchPage: {
    addProduct: 'เพิ่มผลิตภัณฑ์',
    addToCart: 'เพิ่มในรถเข็น',
    alreadyOrdered: 'คุณได้ทำการสั่งผลิตภัณฑ์นี้ไปแล้ว',
    buy: 'ซื้อ',
    buyAgain: 'ทำการซื้ออีกครั้ง',
    cartItems: 'มี {{itemCount}} รายการในตะกร้าสินค้าของคุณ',
    checkout: 'Check out',
    noItems: 'ขณะนี้ไม่มีผลิตภัณฑ์ในตะกร้าสินค้าของคุณ',
    noProducts: `เราไม่พบผลิตภัณฑ์ที่คุณกำลังค้นหาอยู่ โปรดลองค้นหาอีกครั้ง หรือติดต่อฝ่ายลูกค้าสัมพันธ์ (+66 6 0003 5146)`,
    oneItem: 'มีสินค้า 1 รายการในรถเข็นของคุณ',
    packaging: 'บรรจุภัณฑ์',
    quantity: 'ปริมาณ',
    recentPurchases: 'คำสั่งซื้อล่าสุดของคุณ',
    searchPlaceholder: 'ป้อนชื่อผลิตภัณฑ์ ...',
    searchResults: 'ผลลัพธ์การค้นหา',
    productIsUnavailable: 'ไม่พบผลิตภัณฑ์ในคลังสินค้า',
    notifyMe: 'แจ้งให้ฉันทราบเมื่อมีผลิตภัณฑ์',
    notifyInfo: 'คุณจะได้รับอีเมลแจ้งเตือนเมื่อมีผลิตภัณฑ์',
    getBackToYouSoon: 'เราจะติดต่อกลับในเร็วๆนี้',
    availableProducts: 'ผลิตภัณฑ์พร้อมจัดส่ง',
    all: 'ทั้งหมด',
    jumpLetter: 'ไปยังตัวอักษร A',
    back: 'กลับ',
    recentlyPurchased: 'การสั่งซื้อล่าสุด',
    recentlySearched: 'Recently Searched',
    lastPurchased: 'Last Purchased',
    pageEnd: 'คุณมาถึงบรรทัดสุดท้ายของหน้านี้',
    default: 'ค้นหาผลิตภัณฑ์ ให้พิมพ์ชื่อสินค้า, ตัวยาสำคัญ, ชื่อบริษัท, หรือคุณสามารถ',
    browse: 'ค้นหาผลิตภัณฑ์จากรายการสินค้าของเรา',
    mostPopular: 'ยอดนิยม',
    campaignBased: 'ยอดนิยม',
    products: 'Products',
    manufacturer: 'Manufacturer',
    didYouMean: 'Did you mean',
    relatedProducts: 'Related Products',
    seeAllRelated: 'ดูผลิตภัณฑ์ที่เกี่ยวข้องทั้งหมด {{count}} รายการ',
    seeAll: 'ค้นหา',
    cartModal,
    filterSort: {
      filterSort: 'จัดเรียงและกรอง',
      filter: 'กรอง',
      sort: 'เรียงลำดับ',
      sortBy: 'เรียงตาม',
      loyaltyExtraPoint: 'คะแนนพิเศษความภักดี',
      discount: 'การลดราคา',
      min: 'นาที',
      max: 'สูงสุด',
      to: 'ถึง',
      discountError: 'ต้องป้อนค่าสำหรับทั้ง min และ max',
      mostPurchased: 'ซื้อมากที่สุด',
      lowestPrice: 'ราคาต่ำสุด',
      highestDiscount: 'ส่วนลดสูงสุด',
      latest: 'ล่าสุด',
      mostSeen: 'พบเห็นมากที่สุด',
      reset: 'รีเซ็ต',
      apply: 'นำมาใช้',
    },
    ...sectionCard,
  },
  orderCard: {
    orderNumber: 'Order ID',
    invoiceDate: 'วันที่ออกใบแจ้งหนี้',
    dueDate: 'วันครบกำหนด',
    paymentDate: 'วันครบกำหนดชำระเงิน',
    invoices: 'ใบแจ้งหนี้',
    deadline: 'กำหนดเวลา',
    totalInvoice: 'มูลค่าใบแจ้งหนี้',
    paidAmount: 'paid',
    paid: 'paid',
    outstandingAmount: 'ยอดคงเหลือ',
    partiallyPaid: 'Partially Paid',
    totalAmount: 'ยอดทั้งหมด',
    creditNote: 'Credit Note Applied',
  },
  orderStatus: {
    partial: 'จ่ายบางส่วน',
    paid: 'จ่ายแล้ว',
  },
  payments: {
    noAvailableInvoice: 'No available invoice to display',
    noAvailablePaidInvoice: 'ขณะนี้ยังไม่มีใบเสร็จรับเงิน',
    payFirst: 'โปรดตรวจสอบอีกครั้ง เมื่อคุณได้ทำการชำระเงินตามใบแจ้งหนี้แล้ว!',
    letsMakeOrder: 'How about making a few orders with us?',
    orderNow: 'Order Now',
    goToUnpaid: 'UNPAID',
    goToPayment: 'PAYMENT',
    totalAmount: 'Total Amount',
    invoiceSelection: 'Invoice Bill',
    invoiceSelections: 'Invoice Bills',
    allSelection: 'All',
    selectPaymentOption: 'Please select your payment option below',
    paymentOption: 'Payment Option',
    payAnyAmount: 'Pay Any Amount',
    totalDueInvoice: 'Total Due Invoice',
    selectedInvoice: 'Selected Invoice',
    payWithVoucher: 'Pay Using A Voucher',
    voucherApplied: '{{amount}} Discount applied!',
    continue: 'continue',
    payments: 'การชำระเงิน',
    paymentInformation: 'Payment Information',
    areYouSure: 'Are you sure?',
    areYouSureSubtitle: 'Are you sure to cancel existing selected payment?',
    yes: 'yes',
    no: 'no',
    oops: 'Oops!',
    process: 'Process',
    youHavePendingPayment: 'You have an existing pending payment. Kindly process it first.',
    pendingPayment: 'Pending Payment',
    bankInformation: 'Bank Information',
    invoiceInformation: 'Invoice Information',
    howToPay: 'How to pay?',
    paymentConfirmation:
      'For faster payment confirmation, we would recommend you to upload recipe from your mobile banking application otherwise payment confirmation might be delayed',
    uploadReceipt: 'I have receipt. Upload Now',
    uploading: 'กำลังอัปโหลด {{progress}} โปรดรอสักครู่',
    uploadComplete: 'ส่งเรียบร้อยแล้ว',
    cancelPayment: 'Cancel This Payment',
    needHelp: 'Need help?',
    contactCustomerService:
      'Need help? Please contact our Successful Customers via {{customerServiceNumber}} if you have any questions.',
    copied: 'copied to clipboard',
    vaNumber: 'VA Number',
    slogan: 'กรุณาชำระเงินตรงเวลา',
    payNow: 'ชำระเงินทันที',
    downloadInvoiceSummary: 'ดาวน์โหลดสรุปใบแจ้งหนี้',
    invoiceDownloadSuccess: {
      title: 'ส่งอีเมลแล้ว',
      message: 'กรุณาตรวจสอบอีเมล {{email}} เพื่อดาวน์โหลดสรุปใบแจ้งหนี้',
    },
    invoiceDownloadFailed: {
      title: 'No Invoice',
      message: 'You have not made any orders to ask for an invoice summary.',
    },
    creditSummary: {
      creditBalance: 'Credit balance',
      howToPay: 'How to pay?',
      availableCredit: 'วงเงินที่ใช้ได้',
      totalUsage: 'วงเงินที่ใช้ไป',
      total: 'วงเงินทั้งหมด',
      dueToday: 'ครบกำหนดวันนี้',
      dueWeek: 'ครบกำหนดอาทิตย์หน้า',
      notDue: 'ยังไม่ครบกำหนด',
    },
    tabNames: {
      unpaid: 'รอชำระเงิน',
      due: 'วันครบกำหนด',
      paid: 'ชำระเงินแล้ว',
      deposits: 'ฝากเงิน',
    },
    noPaymentsAvailable: 'ยังไม่มีการชำระเงิน',
    showPaidInvoices: 'แสดงใบแจ้งหนี้ที่ชำระเงินแล้ว',
    seeMore: 'ดูเพิ่มเติม',
    endOfPage: 'You have reached the end of this page',
    tabs: {
      catchMessage: 'ไม่มีบิล',
      dueDate: {
        catchMessage: 'ไม่มีบิล',
        dueToday: {
          title: 'ครบกำหนดวันนี้',
          today: 'Today',
          desc: 'ครบกำหนดชำระวันนี้',
        },
        dueWithinWeek: {
          title: 'ถัดไป',
          desc: 'ชำระเงินตอนก่อน ดีกว่ารอให้สายเกินไป',
        },
        notYetDue: {
          title: 'ส่วนที่เหลือ',
          desc: 'ไม่ต้องกังวล ! เป็นแค่การเตือน',
        },
      },
      paid: {
        notAvailable: 'ไม่มีข้อมูล',
        message:
          'ได้รับชำระเงินเรียบร้อยแล้ว ทางเราได้ปรับวงเงินของคุณแล้ว!  ขณะนี้เราได้จัดสรรการชำระเงินของคุณไปยังใบแจ้งหนี้ที่ค้างชำระ คุณจะสามารถดูรายการทั้งหมดด้านล่าง',
        totalPayments: 'การชำระเงินทั้งหมด',
        remainingPayment: 'ขณะนี้เรากำลังประมวลผลการชำระเงินส่วนที่เหลือของคุณ {{remainingAmount}}',
        fullyProcessed: 'ดำเนินการชำระเงินเรียบร้อยแล้ว',
        partiallyProcessed: '{{allocatedAmount}} is allocated',
        unallocatedInfo: 'Remaining deposit amount will be allocated for the next invoices',
        refund: 'Credit Note',
      },
    },
    voucher: {
      applied: 'Voucher worth {{amount}} applied as payment',
    },
    payAnyAmountInstruction: 'How much do you want to pay?',
    paymentBankInstruction: 'Select bank to use for payment',
    minimumPayAnyAmount: 'Amount must be at least {{amount}}',
    maximumPayAnyAmount: 'Amount must be less than {{amount}}',
    selectBankInstruction: 'Select a bank',
    awaitingPayment: 'Waiting for your Payment',
    paymentTransferBefore: 'Please make your transfer before',
    billNumber: 'Bill Number',
    copy: 'Copy',
  },
  orders: {
    title: 'รายการที่สั่ง',
    details: 'Order Details',
    distributor: 'Distributor',
    search: 'ค้นหาด้วยชื่อผลิตภัณฑ์, ชื่อผู้จัดจำหน่าย และหมายเลขใบสั่งซื้อ',
    dueDate: 'วันครบกำหนด',
    poNumber: 'หมายเลขใบสั่งซื้อ',
    invoice: 'หมายเลขใบแจ้งหนี้',
    poNumberCopy: 'PO Number copied',
    invoiceCopy: 'Invoice copied',
    invoiceNotAvailable: 'Not yet available',
    status: 'สถานะ',
    totalPayment: 'การชำระเงินทั้งหมด',
    itemChanged: 'รายการที่มีการเปลี่ยนแปลง',
    itemsChanged: 'รายการที่มีการเปลี่ยนแปลง',
    tour: {
      orderTab: {
        clickHere: 'คลิกที่นี่',
        title: 'ยินดีต้อนรับสู่แถบคำสั่งซื้อแบบใหม่!',
        description:
          'เราได้ออกแบบคำสั่งซื้อของคุณใหม่อีกครั้ง เพื่อให้ข้อมูลทั้งหมดที่คุณต้องการ ในการติดตามผลิตภัณฑ์ที่คุณซื้อ',
        search: 'ค้นหารายการที่สั่ง',
        searchDesc: 'คุณสามารถดูรายการที่สั่ง ด้วยวันที่หรือผู้จัดจำหน่าย โดยตัวกรอก (filter)',
        activities: 'Activities',
        activitiesDesc:
          'ต้องการดูข้อมูลอัปเดตเกี่ยวกับคำสั่งซื้อของคุณหรือไม่? ใช้แท็บActivityเพื่อดูสถานะล่าสุดของคำสั่งซื้อของคุณได้',
        end: 'เราหวังว่าคุณจะสนุกกับการสั่งซื้อแบบใหม่!',
        endDesc: 'หากคุณมีคำถามเพิ่มเติมโปรดติดต่อฝ่ายลูกค้าสัมพันธ์ ทางแชท',
      },
      orderModal: {
        title: 'รายละเอียดรายการสั่งซื้อใหม่',
        description:
          'นี่คือรายละเอียดคำสั่งซื้อแบบใหม่ เราเพิ่มข้อมูลเข้าไปเพื่อช่วยให้คุณจัดการคำสั่งซื้อได้สะดวกขึ้น',
        status: 'สถานะ',
        statusDesc: 'แสดงสถานะและติดตามรายการที่สั่ง',
        documents: 'เอกสาร',
        documentsDesc:
          'คุณสามารถดูเอกสารใบแจ้งหนี้ของคุณได้ แค่คลิกเดียว เพียงเลือกเอกสารที่คุณต้องการดูแล้ว เราจะแสดงออกมาให้คุณ',
        products: 'Products',
        productsDesc:
          'ต้องการตรวจสอบสินค้าในใบสั่งซื้อของคุณหรือไม่? ด้วยการสั่งซื้อแบบใหม่ เราทำให้คุณได้รับข้อมูลที่ต้องการได้ง่ายขึ้น',
      },
    },
    activityStatus: {
      title: {
        [ActivityStatus.PENDING]: 'ชำระเงินแล้ว',
        [ActivityStatus.PROCESSING]: 'ชำระเงินแล้ว',
        [ActivityStatus.ACCEPTED]: 'Your order has been accepted.',
        [ActivityStatus.ORDER_CHANGED]: 'Your order has changes',
        [ActivityStatus.DELAYED]: 'The PBF Closed for a while',
        [ActivityStatus.DISPATCHED]: 'ผู้จัดส่งของเรากำลังมุ่งหน้าไปหาคุณ',
        [ActivityStatus.DELIVERED]: 'รายการที่สั่งถูกจัดส่งแล้ว',
        [ActivityStatus.FULFILLED]: 'Order Fulfilled',
        [ActivityStatus.COMPLETED]: 'Order Completed',
        [ActivityStatus.CANCELLED]: 'รายการที่สั่งถูกยกเลิก',
        [ActivityStatus.RETURNED]: 'ยกเลิก - คืนเต็มจำนวน',
        [ActivityStatus.PREKURSOR_REQUIRED]: 'Precursor Document Required',
        [ActivityStatus.PREKURSOR_APPROVED]: 'Precursor Document Approved',
        [ActivityStatus.PREKURSOR_CANCELLED]: 'Precursor Document Cancelled ',
        [ActivityStatus.PREKURSOR_REJECTED]: 'Precursor Document Rejected',
        [ActivityStatus.PREKURSOR_SUBMITTED]: 'Precursor Document Submitted',
        [ActivityStatus.PREKURSOR_RESUBMITTED]: 'Precursor Document Submitted',
      },
      description: {
        [ActivityStatus.PENDING]: 'ชำระเงินแล้ว คลิกเพื่อดูรายละเอียด',
        [ActivityStatus.PROCESSING]: 'ชำระเงินแล้ว คลิกเพื่อดูรายละเอียด',
        [ActivityStatus.ACCEPTED]: `รายการที่สั่ง {{ po_number }} ได้รับการยืนยันแล้ว คลิกเพื่อดูรายละเอียด`,
        [ActivityStatus.ORDER_CHANGED]: `"รายการที่สั่ง  {{ po_number }} มีการเปลี่ยนแปลง คลิกเพื่อดูรายละเอียด"`,
        [ActivityStatus.DELAYED]:
          'ขออภัยรายการสั่งเลขที่  {{po_number}} ล่าช้าเราจะดำเนินการตามคำสั่งซื้อของคุณเมื่อเปิด แตะที่นี่เพื่อดูรายละเอียดเพิ่มเติม',
        [ActivityStatus.DISPATCHED]: '"รายการที่สั่ง {{ po_number }} กำลังอยู่ระหว่างการส่ง คลิกเพื่อดูรายละเอียด"',
        [ActivityStatus.DELIVERED]: 'รายการที่สั่ง {{ po_number }} จัดส่งเรียบร้อยแล้ว คลิกเพื่อดูรายละเอียด',
        [ActivityStatus.FULFILLED]: 'รายการที่สั่ง {{ po_number }} จัดส่งเรียบร้อยแล้ว คลิกเพื่อดูรายละเอียด',
        [ActivityStatus.COMPLETED]: 'รายการที่สั่ง {{ po_number }} จัดส่งเรียบร้อยแล้ว คลิกเพื่อดูรายละเอียด',
        [ActivityStatus.CANCELLED]: 'รายการที่สั่ง {{ po_number }}  ถูกยกเลิกแล้ว  คลิกเพื่อดูรายละเอียด',
        [ActivityStatus.RETURNED]: 'รายการที่สั่ง {{ po_number }} ถูกส่งคืนแล้ว คลิกเพื่อดูรายละเอียด',
        [ActivityStatus.PREKURSOR_REQUIRED]:
          'Your order {{ po_number }} need precursor document to continue the process. Tap here for more details.',
        [ActivityStatus.PREKURSOR_APPROVED]: 'Your precursor document has been approved. Tap here for more details.',
        [ActivityStatus.PREKURSOR_CANCELLED]: 'Your precursor document has been cancelled. Tap here for more details.',
        [ActivityStatus.PREKURSOR_REJECTED]: 'Your precursor document is rejected. Tap here for more details.',
        [ActivityStatus.PREKURSOR_SUBMITTED]:
          'Hang tight, we are validating your precursor document. Tap here for more details.',
        [ActivityStatus.PREKURSOR_RESUBMITTED]:
          'Hang tight, we are validating your precursor document. Tap here for more details.',
      },
    },
    orderStatus: {
      DEFAULT: '',
      [PharmacyOrderStatus.ALL_ORDERS]: 'รายการที่สั่งทั้งหมด',
      [PharmacyOrderStatus.ORDER_RECEIVED]: 'ชำระเงินแล้ว',
      [PharmacyOrderStatus.WAITING_DOCUMENT]: 'On Approval Process',
      [PharmacyOrderStatus.PREPARING_GOODS]: 'กำลังเตรียมผลิตภัณฑ์',
      [PharmacyOrderStatus.DELAYED]: 'ล่าช้า',
      [PharmacyOrderStatus.OUT_OF_DELIVERY]: 'กำลังนำส่ง',
      [PharmacyOrderStatus.DELIVERED]: 'ส่งแล้ว',
      [PharmacyOrderStatus.CANCELLED]: 'ถูกยกเลิก',
      [PharmacyOrderStatus.COMPLETED]: 'เสร็จสิ้น',
      [PharmacyOrderStatus.ORDER_CHANGED]: 'คำสั่งซื้อมีการเปลี่ยนแปลง',
      [PharmacyOrderStatus.CANCELLED_FULL_RETURN]: 'ยกเลิก - คืนเต็มจำนวน',
      [PharmacyOrderStatus.FULFILLED]: 'Fulfilled',
      [PharmacyOrderStatus.PAID]: 'จ่ายแล้ว',
      // NOTE: need confirmation
      [PharmacyOrderStatus.UNPAID]: 'รอชำระเงิน',
      returned: 'ส่งคืน',
      deliveredPartialReturn: 'Partially Returned',
    },
    orderPrecursorStatus: {
      all: 'All',
      [OrderPrecursorStatus.DOCUMENT_REQUIRED]: 'Document Required',
      [OrderPrecursorStatus.DOCUMENT_WAITING_APPROVAL]: 'Waiting for Document Approval',
      [OrderPrecursorStatus.DOCUMENT_REJECTED]: 'Rejected Documents',
      [OrderPrecursorStatus.DOCUMENT_CANCELLED]: 'Document Cancelled',
      [OrderPrecursorStatus.DOCUMENT_APPROVED]: 'Document Approved',
    },
    orderHistory: {
      DEFAULT: '',
      [PharmacyOrderStatus.ORDER_RECEIVED]: 'เราได้รับคำสั่งซื้อของคุณแล้วและจะดำเนินการโดยเร็วที่สุด',
      [PharmacyOrderStatus.PREPARING_GOODS]: 'เรากำลังเตรียมคำสั่งซื้อของคุณ จะแจ้งให้คุณทราบเมื่อเราพร้อมจัดส่ง',
      [PharmacyOrderStatus.ORDER_CHANGED]: 'มีการเปลี่ยนแปลงรายการที่สั่ง',
      [PharmacyOrderStatus.OUT_OF_DELIVERY]: 'รายการที่สั่งอยู่ระหว่างการส่งแล้ว',
      [PharmacyOrderStatus.DELIVERED]: 'รายการที่สั่งส่งถึงร้านของคุณเรียบร้อยแล้ว',
      [`${PharmacyOrderStatus.DELIVERED}By`]: 'คำสั่งซื้อถูกส่งไปยังร้านของคุณแล้ว รับโดย {{received_by}}',
      returned: 'คำสั่งซื้อถูกส่งคืนแล้ว',
      cancelled: 'คำสั่งซื้อถูกยกเลิกตามคำขอของคุณ แตะที่นี่เพื่อดูรายละเอียด',
      date: 'Date',
    },
    orderStatusHistory: {
      DEFAULT: '',
      [OrderStatus.PENDING]: 'Confirmed',
      [OrderStatus.PROCESSING]: 'Order Process',
      [OrderStatus.ACCEPTED]: 'Order Process',
      [OrderStatus.ORDER_CHANGED]: 'Order Process',
      [OrderStatus.DISPATCHED]: 'Dispatching',
      [OrderStatus.RETURNED]: 'Returned',
      [LogisticDeliveryStatus.DELIVERY_COURIER_ASSIGNED]: 'Courier Assigned',
      [LogisticDeliveryStatus.DELIVERY_COURIER_DEPARTED]: 'Courier Departed',
      [LogisticDeliveryStatus.DELIVERY_PICKED_UP]: 'Parcel Picked Up',
      [LogisticDeliveryStatus.DELIVERY_ONGOING]: 'On Delivery',
      [LogisticDeliveryStatus.DELIVERY_COURIER_ARRIVED]: 'Courier Arrived',
      [LogisticDeliveryStatus.DELIVERY_DELIVERED]: 'Delivered',
      [LogisticDeliveryStatus.DELIVERY_DELAYED]: 'Delivery Delayed',
      [LogisticDeliveryStatus.DELIVERY_FAILED]: 'Failed to Deliver',
      [LogisticDeliveryStatus.DELIVERY_CANCELED]: 'Failed to Deliver',
      [OrderStatus.DELIVERED]: 'Delivered',
      [OrderStatus.CANCELLED]: 'Canceled',
    },
    orderStatusDetailHistory: {
      DEFAULT: '',
      [OrderStatus.PENDING]: 'Your order is packed and will be handover to delivery partner',
      [OrderStatus.PROCESSING]: 'Your order invoice already created',
      [OrderStatus.ACCEPTED]: 'Your order invoice already created',
      [OrderStatus.ORDER_CHANGED]: 'Your order has changes',
      [OrderStatus.DISPATCHED]: 'Your order is already prepared and ready to pickup by courier',
      [LogisticDeliveryStatus.DELIVERY_COURIER_ASSIGNED]:
        'Your order is already accepted by driver and will be delivered to you',
      [LogisticDeliveryStatus.DELIVERY_COURIER_DEPARTED]:
        'Our courier departed to the pick up point at <strong>Warehouse</strong>',
      [LogisticDeliveryStatus.DELIVERY_PICKED_UP]: 'Our parcel successfully picked up',
      [LogisticDeliveryStatus.DELIVERY_ONGOING]: 'Out for delivery! we will attempt to deliver your order',
      [LogisticDeliveryStatus.DELIVERY_COURIER_ARRIVED]:
        'Our courier has arrived and is waiting for you to receive the order',
      [LogisticDeliveryStatus.DELIVERY_DELIVERED]: 'Your order has been delivered',
      [`${LogisticDeliveryStatus.DELIVERY_DELIVERED}_received_by`]:
        'Your order has been delivered and received by <strong>{{received_by}}<strong>',
      [LogisticDeliveryStatus.DELIVERY_DELAYED]: 'We are sorry the delivery today was unsuccesful',
      [LogisticDeliveryStatus.DELIVERY_FAILED]: 'We are sorry the delivery today was unsuccesful',
      [LogisticDeliveryStatus.DELIVERY_CANCELED]: 'We are sorry the delivery today was unsuccesful',
      [`${LogisticDeliveryStatus.DELIVERY_FAILED}_description`]:
        'We are sorry the delivery today was unsuccesful<br/>Reason: {{description}}',
      [`${LogisticDeliveryStatus.DELIVERY_FAILED}_${LogisticDeliveryStatusDetail.PHARMACY_CLOSED}`]:
        'We are sorry the delivery today was unsuccesful<br/>Reason: pharmacy was close',
      [OrderStatus.DELIVERED]: 'Your order has been delivered',
      [`${OrderStatus.DELIVERED}_received_by`]:
        'Your order has been delivered and received by <strong>{{received_by}}<strong>',
      [OrderStatus.CANCELLED]: 'We are sorry the delivery today was cancel',
    },
    orderNote: {
      DEFAULT: '',
      [LogisticDeliveryStatusDetail.PHARMACY_CLOSED]:
        'The driver has arrived at the pharmacy, but the pharmacy is closed. The driver will return the next day to deliver the order.',
      [OrderStatus.DELIVERED]:
        'If any items were returned, we are currently processing and may take 24 hours to reflect the changes.',
    },
    orderStatusDeliveryProof: 'See Proof of Delivery',
    tabNames: {
      activity: 'activity',
      history: 'ประวัติย้อนหลัง',
    },
    DIRECT_PAYMENT: 'Direct Payment',
    CREDIT_LINE: 'Credit Line',
    paymentMethod: 'วิธีชำระเงิน',
    purchasedDate: 'วันสั่งซื้อ',
    expectedDeliveryDate: 'จะได้รับผลิตภัณฑ์ประมาณวันที่',
    toBeDetermined: 'วันจัดส่งจะยืนยันหลังยืนยันขำระเงิน',
    slaPrekursorWarningText: `Expected delivery date to be determined. Please upload the required document below`,
    slaUnpaidWarningText: `วันเวลาจัดส่งจะแจ้งให้ทราบหลังการชำระเงินโปรดชำระเงินภายใน`,
    slaUnpaidWarningAction: `ชำระเงินตอนนี้`,
    viewStatus: 'แสดงสถานะ',
    viewInvoice: 'แสดงใบแจ้งหนี้',
    invoiceNumber: 'หมายเลขใบแจ้งหนี้',
    invoicePhoto: 'รูปภาพใบแจ้งหนี้',
    taxInvoice: 'ใบกำกับภาษี',
    viewTax: 'แสดงภาษี',
    deliveryProof: 'หลักฐานการส่งของ',
    viewProof: 'แสดงหลักฐาน',
    note: 'บันทึก',
    orderInformation: 'รายการซื้อ',
    moreDetails: 'รายละเอียดเพิ่มเติม',
    priceChanged: 'เปลี่ยนแปลงราคา',
    quantityChanged: 'เปลี่ยนแปลงปริมาณ',
    unitPrice: 'จำนวนต่อชิ้น',
    quantity: 'ปริมาณ',
    unit: 'ชิ้น',
    units: 'ชิ้น',
    subTotal: 'ผลรวมบางส่วน',
    discount: 'ส่วนลด',
    total: 'ทั้งหมด',
    paymentDetails: 'รายละเอียดการชำระเงิน',
    totalOrders: 'รายการที่สั่งทั้งหมด',
    tax: 'ภาษี',
    totalAmount: 'จำนวนเงินทั้งหมด (อาจรวมภาษี)',
    totalAmountHistory: 'ราคารวม',
    amountAfterTax: 'ราคาสุทธิหลังรวมภาษี',
    paymentInformations: 'รายละเอียดการชำระเงิน',
    paymentDate: 'วันที่ชำระเงิน',
    needAssistanceForThisOrder: 'ต้องการความช่วยเหลือในการสั่งซื้อ',
    needAssistanceForReturn: 'ในกรณีที่มีการคืนผลิตภัณฑ์ ทางเราจะดำเนินการภายใน 24 ชม.',
    yesIneedAssistance: 'ต้องการความช่วยเหลือ ?',
    needAssistance: 'ต้องการความช่วยเหลือ',
    needAssistanceOrderViaWhatsapp: 'Hello, can you assist me with Order # {{poNumber}}?',
    detailStatus: 'รายละเอียดสถานะ',
    detailChanges: 'การเปลี่ยนแปลงรายละเอียด',
    orderChanged: 'รายการนี้ได้รับการอัปเดต',
    hna: 'Rp/Unit',
    // NOTE: need confirmation
    outOfStock: 'สินค้าหมด',
    orderStatusTitle: 'Order Status',
    moreReturnedItems: 'See {{count}} more products',
    moreOrderItems: '{{count}} items left',
    minimizeOrderItems: 'Minimize product list',
    seeMoreDetails: 'see more details',
    return: {
      title: 'Returned Products',
      reason: 'Returned Reason',
    },
    delivered: {
      title: 'Delivered Products',
    },
    loyalty: {
      rewardPass: 'SwipeRx Reward Pass',
      rewardPointAdded: 'Reward Points',
      rewardPointDeducted: 'Deducted Points',
      rewardPointTotal: 'Total Reward Points',
      rewardPending: '(Pending)',
    },
    refund: {
      title: 'Refund Information',
      date: 'Credit Note Date',
      value: 'Total Credit Note Price',
      tax: 'Tax Credit Note',
      total: 'Total Credit Note',
    },
    creditMemo: {
      title: 'Credit Memo Information',
      no: 'Credit Memo No',
      see: 'See',
      document: 'Credit Memo Document',
      download: 'Download',
      documentOnProgress: 'Credit memo document is still being processed.',
    },
    checkout: 'Check Out',
    prekursorTooltipTour: {
      activityFilter: 'You can find pending prekursor document here',
      activityCard: 'Click this card to upload prekursor document',
    },
    alkes: {
      title: 'Medical Devices',
    },
    regular: {
      title: 'Regular',
    },
    prekursor: {
      title: {
        required: 'Precursor Document Required',
        submitted: 'Waiting for Document Approval',
        resubmitted: 'Waiting for Document Approval',
        approved: 'Precursor Document Approved',
        rejected: 'Precursor Document Rejected',
        cancelled: 'Precursor Document Cancelled',
      },
      add: 'Add Prekursor Document',
      uploadSuccess: {
        title: 'Yay! your precursor document image uploaded succesfully',
        info: 'You can check status of this order in Activities tab',
        action: 'OKAY',
      },
      uploadFailed: {
        title: 'Oops! The upload of your precursor document failed',
        info: 'failed file:',
        action: 'RETRY',
      },
      uploadApprovedFailed: {
        title: 'Document already approved, can not update document',
        info: 'failed file:',
        action: 'RETRY',
      },
      prekursorTooltipTour: {
        preUpload: 'Click here to upload Prekursor document',
        postUpload: 'Tap here to preview or edit Prekursor document',
        editUpload: 'Tap here to edit Prekursor document image',
      },
      requiredLabel: 'Prekursor docs required',
      required: 'Prekursor Document Needed',
      submitted: "Waiting Document's Approval",
      resubmitted: 'Menunggu Persetujuan Dokumen',
      approved: 'Document Approved',
      rejected: 'Document Rejected',
      cancelled: 'Document Cancelled',
      instruction: 'Instruction',
      downloadSPSample: 'Download The Example of SP Document',
      rejected_reason: 'Rejected Reason',
      reasons: {
        document_unclear: 'Submitted Image Is Not Clear',
        photo_unclear: 'Photo Blurred',
        precursor_format_invalid: 'SP Format Invalid',
        address_pbf_invalid: 'PBF Name & Address Invalid',
        no_sipnap: 'No SIPNAP Number',
        sipnap_not_match: 'Invalid SIPNAP Data',
        pharmacy_data_incomplete: 'Pharmacy Document Not Complete',
        specimen_not_updated: 'Specimen Not Updated',
        pharmacy_data_invalid: 'Pharmacy Data Invalid',
        apj_data_invalid: 'Pharmacy APJ Data Invalid',
        signature_blank: 'APJ Signature Blank',
        signature_not_match: 'Invalid APJ Signature',
        signature_dry: 'APJ Signature Not From Ink',
        stamp_blank: "Pharmacy's Stamp Blank",
        stamp_not_match: "Pharmacy's Stamp Invalid",
        stamp_dry: "Pharmacy's Stamp Not From Ink",
        wrong_core_molecules: 'Incorrect Core Molecules',
        wrong_product_name: 'Incorrect Product Name',
        wrong_quantity: 'Incorrect Product Quantity',
      },
      fileSizeValidationError: 'File size cannot be larger than {{size}}MB',
      fileTypeValidationError: 'File type must be image',
      generalValidationError: 'Something went wrong',
      sendSPViaEmail:
        'We send sample precursor document to your email, please check your email that registered on our system',
    },
    orderInfoDelivered: 'delivered Products',
    orderInfoReturned: 'returned products',
    letsRateYourOrder: 'Let’s rate your order experience',
    howDidYouLikeYourOrder: 'How did you like the product and overall order experience from <0>{{distributor}}<0>?',
    tapTheStarToRate: 'Tap the stars (1 is terrible, 5 is awesome)',
    tellUsMore: 'Tell us more!',
    optional: 'Optional',
    submit: 'Submit',
    whatCouldBeImproved: 'What could be improved?',
    enjoyedLetUsKnow: 'Enjoyed? Let us know...',
    moreProducts: '+{{ n }} more products',
    oneItem: '1 product',
    writeYourFeedback: 'Write Review',
    yourOpinionMatter: 'Your opinion matter to us! Please take a moment to let us know your thoughts.',
    starRating: {
      terrible: 'Terrible',
      poor: 'Poor',
      ok: 'Okay',
      good: 'Good',
      awesome: 'Awesome',
    },
    gladYouEnjoyed: {
      header: "We're glad you enjoyed it!",
      text: 'We appreciate your feedback. Thank you for sharing your order experience with us!',
    },
    sorryForYourInconvenience: {
      header: 'Oops! sorry for your inconvenience',
      text: 'We appreciate your feedback. We are promise to improve our services. ',
    },
    done: 'done',
  },
  orderConfirmed: {
    title: 'ใบยืนยันการสั่งซื้อ',
    hooray: 'ดีใจด้วย !',
    ordersSuccessfullySubmitted: 'รายการที่สั่งได้รับการยืนยันแล้ว',
    prepareSignedPurchaseOrder: 'กรุณาเตรียม ใบสั่งซื้อ (PO) ที่เซ็นต์แล้ว',
    fulfillOrders:
      'ผู้จัดจำหน่ายของเราสามารถดำเนินการตามคำสั่งซื้อของคุณได้ก็ต่อเมื่อคุณมีใบสั่งซื้อ (PO) ที่พิมพ์และเซ็นต์แล้ว โปรดแสดงเอกสารนี้ให้กับตัวแทนจำหน่ายของเราก่อนส่งการจัดส่ง',
    orderSummary: 'สรุปรายการที่สั่ง',
    totalOrderAmount: 'ราคารวม',
    eVat: 'ภาษีมูลค่าเพิ่ม ',
    totalAfterVat: 'จำนวนเงินทั้งหมด (อาจรวมภาษี)',
    seeDetails: 'ดูรายการที่สั่ง',
    orderNumber: 'Order Number',
    netAmount: 'ราคารวม',
    ordered: 'ทำการสั่งวันที่',
    reserved: 'Ordered on',
    amountAfterTax: 'จำนวนเงินทั้งหมด (อาจรวมภาษี)',
    rateYourOrderExperience: 'คุณจะให้คะแนนกับการสั่งของผ่าน App ครั้งนี้เท่าไร',
    needHelp: 'ต้องการความช่วยเหลือ ?',
    ourTeamMoreThanHappyToAssist: 'ทางเรายินดีที่จะตอบทุกคำถามของท่านเกี่ยวกับการสั่งของและการจ่ายเงิน',
    pleaseContact: 'กรุณาติดต่อ ...',
    customerSuccess: 'ฝ่ายลูกค้าสัมพันธ์',
    teamViaWhatsApp: `team via {{phoneNumber}}`,
    checkout: 'ชำระเงิน',
    orderedOn: 'ทำการสั่งวันที่',
    prekursorFlag: 'Prekursor Docs Required',
    alkesFlag: 'Medical Devices',
    regularFlag: 'Regular',
    prekursorTooltipTour: {
      orderSummary: 'Click here to upload document',
    },
    voucher: {
      chooseVoucher: 'เลือกโค้ดส่วนลด',
      voucherApplied: 'เลือกโค้ดส่วนลด {{amount}} บาท',
    },
  },
  promotedPage: {
    noPromoted: 'โปรโมชั่นจะพร้อมใช้งานเร็ว ๆ นี้ คุณสามารถตรวจสอบอีกครั้ง',
    packaging: 'Packaging',
    quantity: 'Quantity',
    addProduct: 'Add product',
    addToCart: 'Add to cart',
    pbfDeals: 'ข้อเสนอที่ดีที่สุด',
    showMore: 'แสดงเพิ่มเติม',
    greatDeals: 'ดูข้อเสนอดีๆ เพิ่มเติม',
    pageEnd: 'คุณมาถึงบรรทัดสุดท้ายของหน้านี้',
    buyNow: 'ซื้อเลย',
    noProducts,
    noProductsInArea: `ไม่พบรายการที่คุณกำลังหา โปรดค้นหาผลิตภัณฑ์อื่นๆ หรือติดต่อทีมฝ่ายลูกค้าสัมพันธ์เพื่อขอความช่วยเหลือเพิ่มเติม`,
    recentlyPurchased: 'ประวัติการสั่งซื้อ',
    cartModal,
  },
  productPage: {
    removeItemConfirmation: 'คุณต้องการจะลบผลิตภัณฑ์นี้ออกจากตะกร้า ?',
    add: 'เพิ่ม',
    similarProducts: 'Similar Products',
    yes: 'Yes',
    no: 'No',
  },
  accountPage: {
    myAccount: 'บัญชีของฉัน',
    license: {
      yourActiveLicenses: 'ใบอนุญาตที่ใช้งานอยู่ของคุณ',
      document: 'เอกสาร',
      validUntil: 'ใช้ได้ถึงวันที่ {{valid_until}}',
      valid: 'ถูกต้อง',
      expiredOn: 'หมดอายุเมื่อ {{expired_on}}',
      verified: 'ตรวจสอบแล้ว',
      unverified: 'ไม่ได้รับการยืนยัน',
      contactUs: 'ติดต่อเรา',
      willExpireMsg:
        'ใบอนุญาตจะหมดอายุในวันที่ {{valid_until}}. โปรดส่งใบอนุญาตที่ต่ออายุเพื่อใช้บัญชีที่ได้รับการยืนยันต่อไป.',
      expiredMsg: 'ใบอนุญาตหมดอายุ โปรดส่งใบอนุญาตที่ต่ออายุเพื่อใช้บัญชีที่ได้รับการยืนยันต่อไป',
      helpUpgrade: 'สวัสดี ฉันต้องการอัปเกรดบัญชีของฉัน โปรดช่วย',
      ...pharmacyTierInfo,
    },
    available: {
      creditBalance: 'วงเงินคงเหลือ',
      availableCredit: 'วงเงินที่ใช้ได้',
      usedCredit: 'วงเงินที่ใช้ไปทั้งหมด',
      totalCredit: 'วงเงินทั้งหมด',
    },
    balance: {
      outstandingBal: 'ยอดคงเหลือ',
      viewOrders: 'ดูรายการที่สั่ง',
      paymentMethod: 'วิธีชำระเงิน',
      viewDetails: 'ดูรายละเอียด',
      howToPay: 'วิธีชำระเงิน',
      bank: 'ธนาคาร',
      accountNo: 'เลขที่บัญชี',
      name: 'ชื่อ',
      needHelp: 'ต้องการความช่วยเหลือ ?',
      helpMore: `สอบถามข้อมูลเพิ่มเติมกรุณาติดต่อ +66 6 0003 5146`,
      noBankDetail: 'ไม่มีข้อมูลจากธนาคาร',
    },
    virtualAccount: {
      paymentChannels: 'ช่องทางการจ่ายเงิน',
      setAsDefault: 'ตั้งเป็นค่าเริ่มต้น',
      default: 'ค่าเริ่มต้น',
      copy: 'คัดลอก',
      copied: 'คัดลอกแล้ว',
      learnHowToPay: 'เรียนรู้วิธีการชำระเงิน',
      selectMethod: 'เลือกวิธีการ',
      instruction: 'Transfer any amount to your preferred bank',
      needToDo: `กรุณาดำเนินการตามนี้`,
      transferToAnyOfTheFollowingA: 'โอน',
      transferToAnyOfTheFollowingB: 'ในวันที่ {{due_date}} ผ่านช่องทางชำระเงินดังนี้:',
      paymentDetails: 'Your total amount of {{value}} will be auto-filled in your banking-app.',
    },
    help: {
      needHelp: 'ต้องการความช่วยเหลือ ?',
      customerSuccess: 'Customer Success',
      message: `กรุณาติดต่อฝ่ายลูกค้าสัมพันธ์หากมีข้อสงสัย {{customer_success}} via {{number}}`,
      message1: 'ต้องการความช่วยเหลือ ?',
      message2: 'กรุณาติดต่อฝ่ายบริการลูกค้าที่',
      message3: 'Line: @swiperx_th หรือกดลิงค์ https://page.line.me/373xaoez?openQrModal=true',
      message4: 'โทร: +66-60-003-5146 จันทร์-ศุกร์ 9.00-18.00น.',
      message5: '',
      message6: '',
    },
    swipeApp: {
      swiperx: 'ลูกเล่นอื่นๆ',
      news: 'ข่าวสาร',
      cpd: 'การศึกษา',
    },
    helpSupport: {
      homepage: 'ตั้งการจัดซื้อเป็นหน้าแรก',
      howToAddProduct: 'ฉันสามารถเพิ่มผลิตภัณฑ์อย่างไร',
      howToOrder: 'ฉันสามารถทำการสั่งสินค้าอย่างไร',
      help: 'ช่วยเหลือ',
      faq: 'คำถามที่พบบ่อย',
      policy: 'นโยบายความเป็นส่วนตัว',
      contact: 'ติดต่อเรา',
      terms: 'เงื่อนไขการใช้งาน',
      exit: 'ออก',
    },
    tour: {
      welcomeTitle: 'ยินดีต้อนรับสู่ตะกร้าสินค้าของคุณ',
      welcomeContent: 'ดูลูกเล่นใหม่ๆ',
      detail: '',
      detailContent: 'คุณสามารถหาข้อมูลบัญชีได้ที่นี่',
      credit: '',
      creditContent: 'คุณสามารถตรงสอบวงเงินที่ใช้ได้ที่นี่',
      due: 'คุณอยากทราบว่าต้องชำระเงินจำนวนเท่าไร ?',
      dueContent: 'คุณสามารถตรงสอบยอดค้างชำระได้ที่นี่',
      payment: 'คุณอยากทราบว่าวิธีการชำระเงินทำอย่างไร ?',
      paymentContent: 'คุณสามารถดูข้อมูลเกี่ยวกับวิธีการชำระเงินได้ที่นี่',
      swipe: 'ง่ายต่อการเข้าถึง SwipeRx',
      swipeContent: 'กดที่ link เพื่อไปที่ Newsfeed หรือ Education ',
      homePage: 'การจัดซื้อเป็นหน้าหลักบน SwipeRX ของคุณแล้ว',
      homePageContent: 'หากต้องการเปลี่ยนการตั้งค่า คุณเพียงแค่อัปเดตรายการด้านล่าง',
      howToAddProductsContent: 'ฉันสามารถเพิ่มผลิตภัณฑ์อย่างไร',
      howToCreateOrderContent: 'ฉันสามารถทำการสั่งสินค้าอย่างไร',
      exit: '',
      exitContent: 'คลิก เพื่อออกจากแอพ',
    },
    benefits: {
      title: 'Account Benefits',
      currentAccount: 'Current account',
      unverified: 'unverified',
      verified: 'verified',
      verifyYourAccountUnlock: 'Verify your account to unlock more benefits',
      typeOfProductsEligible: 'Type of Products Eligible to Purchase',
      withoutCustomLogo: 'Product without special logo',
      withOTCMarkingLogo: 'Products with OTC marking logo',
      greenSymbolWithBlackCircle: 'Green symbol with black outline circle',
      otcWithoutPrescription:
        "Over-the-counter drugs are sold in the market and can be purchased without a doctor's prescription.",
      blueSymbolWithBlackCircle: 'Blue symbol with black outline circle',
      otcWithPrescription:
        "Over-the-counter drugs are sold in the market and can be purchased without a doctor's prescription.",
      treeSymbol: 'Tree symbol',
      medicineMadeNatural: 'Traditional medicine or herbal medicine made from natural ingredients',
      withRedLogo: 'Product with red logo',
      letterKsymbolRedCircle: '‘K’ Symbol in red circle',
      strongDrugsWithPrescription: "Strong drugs that can only be obtained with a doctor's prescription",
      productPrecursor: 'Product Prekursor',
      multiplePaymentOptions: 'Multiple Payment Options',
      secureAndConvenientPayment: 'Secure and convenient payment',
      directPaymentOption:
        'Using direct payment option you can pay after placing an order using a bank VA of your choice through our secured and protected payment system.',
      unlockCreditLimit: 'Unlock credit limit',
      creditLimitBasedEligibility: 'Chance to receive credit limit of up to Rp500.000.000 based on your eligibility.',
      licenseRequirements: 'ข้อกำหนดใบอนุญาต',
      submitDocuments: 'Verify your account by submitting following documents',
      speciment: 'Speciment',
      activeLicense: 'Lisensi Aktif',
      siaDocument: 'SIA Dokumen',
      sipaDocument: 'SIPA Dokumen',
      sipnapDocument: 'SIPNAP Dokumen',
      ownerId: 'KTP Pemilik',
      pharmacistId: 'KTP Apoteker',
      npwpOwnerId: 'NPWP Owner',
      selfieWithID: 'Selfie dengan KTP',
      verifyYourAccount: 'verify your account',
      verifyYourAccountChat1: '',
      verifyYourAccountChat2: '',
      licenseRequirements2: 'ข้อกำหนดใบอนุญาต',
      licenseTitle2: 'Lisensi Aktif',
    },
    creditLimitUpgradeRequest: {
      cardMessage: 'Want to increase your credit limit?\nRequest now.',
      pendingCardMessage: 'Click to view your request status.',
      blockedMessage:
        "Your account is blocked. There are {{dpdInvoiceCount}} bills\n that haven't been paid for up to {{dpd}} days.\nPay off immediately so you can shop again.",
      suspendMessage: 'Your account has been temporarily suspended',
    },
  },
  favoritesPage: {
    favorites: 'favorites',
    pageEnd: 'คุณมาถึงบรรทัดสุดท้ายของหน้านี้',
    empty: `You don't have a favorite product yet.`,
    removedFromFavoriteProduct: 'Favorite product have been deleted',
  },
  productRemindersPage: {
    message: 'ผลิตภัณฑ์จะถูกนำออกจากหน้านี้อัตโนมัติ7วัน หลังจากผลิตภัณฑ์พร้อมจำหน่าย',
    noProducts: `You don't have a product reminder yet.`,
  },
  productList: {
    pageEnd: 'คุณมาถึงบรรทัดสุดท้ายของหน้านี้',
    empty: 'The product list is empty.',
  },
  productCard: {
    upTo: 'ส่วนลดมากถึง {{discount}}%',
    addedToFavoriteProduct: 'Product is added to favorite.',
    removedFromFavoriteProduct: 'Product is removed from favorite.',
    prekursorTooltipTour: {
      productCard: 'This product required prekursor document',
      productCardTitle: 'Prekursor Icon',
    },
    maxQtyLimit: 'MAX {{value}}/day',
  },
  wishlistPage: {
    wishlist: 'Wishlist',
    reminders: 'Reminders',
    comingSoon: 'Coming Soon',
    favorites: 'Favorites',
  },
  loyaltyBadge: {
    tier_label: 'Tier',
    verified_orders_total: 'Verified Orders',
    pending_orders_total: 'Pending Orders',
    asOf: 'as of',
    viewBenefits: 'View Benefits',
    title: 'Your status in',
  },
  productRequest: {
    question: 'หาสิ่งที่คุณต้องการไม่เจอใช่หรือไม่?',
    title: 'เข้ามาบอก SwipeRx ว่าคุณกำลังค้นหาผลิตภัณฑ์อะไร',
    inputLabel: 'ชื่อผลิตภัณฑ์',
    inputPlaceholder: 'พิมพ์ชื่อผลิตภัณฑ์',
    buttonSubmit: 'บอกให้ SwipeRx รู้',
    thankYou: 'ขอบคุณสำหรับคำแนะนำ',
    oops: 'Something went wrong. Please try again in a few minutes.',
    lengthLessThan3: 'Product name must be more than 3 characters...',
    pageEnd: 'คุณมาถึงบรรทัดสุดท้ายของหน้านี้',
    empty: `You haven't suggested any products yet.`,
  },
  directPayment: {
    copied: 'Transaction ID copied to clipboard',
    close: 'ปิด',
    billCreateError: 'สร้างบิลไม่สําเร็จ โปรดลองอีกครั้งในภายหลัง',
    billCreateErrorRetry: 'โปรดรอสักครู่! เรากำลังพยายามสร้างบิล',
    headerTitle: 'ข้อมูลการชำระเงิน',
    timerLabel: 'กำลังรอการชำระเงิน',
    instructions: 'หมายเลขบิลของคุณคือ',
    saveQRCode: 'ใช้หน้าจอของรหัส QR นี้สำหรับการอ้างอิงของคุณ',
    totalAmount: 'จำนวนเงินทั้งหมด',
    billCode: 'รหัสบิล',
    footerInstructionTitle: 'วิธีชำระเงิน',
    footerInstructionDetails: {
      step1: 'ถ่ายภาพหน้าจอของรหัส QR นี้',
      step2: 'เปิดแอปพลิเคชันมือถือของธนาคารของคุณ',
      step3: 'อัปโหลดรหัส QR นี้ไปยังแอปพลิเคชันธนาคาร (รหัส QR จะใส่ยอดเงินทั้งหมดโดยอัตโนมัติ)',
      step4: 'ตรวจสอบให้แน่ใจว่าบัญชีธนาคารของคุณมีเงินเพียงพอเพื่อให้การชำระเงินสำเร็จ',
      step5:
        'เมื่อคุณโอนเงินผ่านแอปธนาคารของคุณสำเร็จแล้ว ทางเราจะจัดเตรียมผลิตภัณฑ์ตามคำสั่งซื้อเพื่อจัดส่งต่อไป หากมีข้อสงสัยโปรดติดต่อ @SwipeRx_TH',
    },
    paidBillNotification: {
      title: 'เย้! การชำระเงินสำเร็จแล้ว!',
      content:
        'ขอขอบคุณสำหรับชำระเงินของเลขที่บิล {{billReference}} ทางเราจะจัดเตรียมผลิตภัณฑ์ตามรายการคำสั่งซื้อ เพื่อการจัดส่งภายใน 24 ชั่วโมง',
      action: 'ตรวจสอบคำสั่งซื้อของคุณ',
    },
    paymentFullNotification: {
      title: 'เย้! การชำระเงินสำเร็จแล้ว!',
      content: 'คําสั่งซื้อของคุณได้รับการวางและจะได้รับการประมวลผลเร็ว ๆ นี้!',
      action: 'ตรวจสอบคำสั่งซื้อของคุณ',
    },
    headerPaymentFullTitle: 'ขอบคุณ!',
    headerPaymentFullMessage: 'คําสั่งซื้อของคุณจะถูกประมวลผลเร็ว ๆ นี้!',
  },
  directPaymentList: {
    amount: 'จำนวน',
    poNumber: 'Order ID',
    checkout: 'เช็คเอาท์',
    totalAmount: 'รวมทั้งหมด',
    voucherApplied: 'เลือกโค้ดส่วนลดแล้ว',
  },
  directPaymentModal: {
    title: 'Order Summary',
    checkout: 'เช็คเอาท์',
    netAmount: 'ราคารวม',
    eVat: 'ภาษีมูลค่าเพิ่ม',
    amountAfterTax: 'จำนวนเงินทั้งหมด (อาจรวมภาษี)',
    viewOrder: 'รายละเอียดรายการที่สั่ง',
    orderNumber: 'Order Number',
    hooray: 'ดีใจด้วย !',
    ordered: 'ทำการสั่งวันที่',
    reserved: 'Ordered on',
    ordersSuccessfullySubmitted: 'รายการที่สั่งได้รับการยืนยันแล้ว',
    prepareSignedPurchaseOrder: 'กรุณาเตรียมเอกสาร PO ของคุณ',
    fulfillOrders: 'ทางเราจะสามารถดำเนินการสั่ง....',
    orderedOn: 'ทำการสั่งวันที่',
    voucher: {
      chooseVoucher: 'เลือกโค้ดส่วนลด',
      voucherApplied: 'เลือกโค้ดส่วนลด {{amount}} บาท',
    },
    prekursorTooltipTour: {
      orderSummary: 'Click here to upload document',
    },
  },
  loyalty: {
    today: 'Today',
    noData: 'No data',
    point: 'Point',
    points: 'Points',
    member: 'Member',
    status: {
      [LoyaltyHistoryStatus.ALL]: 'All',
      [LoyaltyHistoryStatus.VERIFIED]: 'Active',
      [LoyaltyHistoryStatus.BONUS]: 'Bonus',
      [LoyaltyHistoryStatus.PENDING]: 'Pending',
      [LoyaltyHistoryStatus.DEDUCTED]: 'Deducted',
      [LoyaltyHistoryStatus.EXPIRED]: 'Expired',
      [LoyaltyHistoryStatus.EXPIRING_SOON]: 'Expiring Soon',
      [LoyaltyHistoryStatus.REDEEMED]: 'Redeemed',
    },
    cardDescription: {
      [LoyaltyHistoryStatus.VERIFIED]: 'Purchased from PO Number',
      [LoyaltyHistoryStatus.BONUS]: 'Purchased from PO Number',
      [LoyaltyHistoryStatus.PENDING]: 'Purchased from PO Number',
      [LoyaltyHistoryStatus.DEDUCTED]: 'Purchased from PO Number',
      [LoyaltyHistoryStatus.EXPIRED]: 'Purchased from PO Number',
      [LoyaltyHistoryStatus.EXPIRING_SOON]: 'Purchased from PO Number',
      [LoyaltyHistoryStatus.REDEEMED]: 'Purchased',
    },
    loyaltyPointDescriptionTitle: 'What are loyalty points?',
    loyaltyPointDescriptionMore: 'Learn More',
    retry: {
      message: 'Something went wrong',
      button: 'Try again',
    },
    rewards: {
      all: 'all',
      title: 'Rewards',
      myRewards: 'My Rewards',
      hotRewards: 'Hot Rewards',
      redeemNow: 'Redeem Now',
      howToUse: 'How to Use',
      termsOfUse: 'Terms of Use',
      redeemedOn: 'Redeemed on',
      redeemDialoag: {
        title: 'You’re All Set',
        description: 'You just redeemed',
        description2: 'Enjoy!',
        close: 'Close',
      },
      errorMessage: {
        insufficientPoints: 'You need {{points}} more points in order to redeem this reward.',
        dpd: 'Oh no, you can’t redeem the reward because you have outstanding payment(s) for more than {{dpd}} days. (DPD). Please pay your invoices soon.',
      },
      filter: {
        pointsRequired: 'Points Required',
        filterByPoints: 'Filter by Points',
        minimumPoints: 'Minimum Points',
        maximumPoints: 'Maximum Points',
        filterByVendor: 'Filter by Vendor',
        applyFilter: 'Terapkan Filter',
        vendor: 'Vendor',
        vendorName: 'Vendor Name',
        search: 'Search',
      },
      noRedeemData: 'no recently claimed rewards',
    },
    landing: {
      title: 'Loyalty Program Dashboard',
      lastUpdate: 'Last update',
      breakdown: {
        [LoyaltyHistoryStatus.VERIFIED]: 'Active Points',
        [LoyaltyHistoryStatus.PENDING]: 'Pending Points',
        [LoyaltyHistoryStatus.REDEEMED]: 'Redeemed Points',
        [LoyaltyHistoryStatus.DEDUCTED]: 'Deducted Points',
        [LoyaltyHistoryStatus.EXPIRING_SOON]: 'Expiring Soon',
      },
      seeAllPointsHistory: 'See All Points History',
      whatDoPointsMean: 'What do these points mean?',
      pointMeaning: {
        [LoyaltyHistoryStatus.VERIFIED]: {
          title: 'Active Points',
          description:
            'These are the points when your orders are delivered and fulfilled. It takes up to 7 days convert your pending points to verified points.  These points are redeemable.',
        },
        [LoyaltyHistoryStatus.PENDING]: {
          title: 'Pending Points',
          description:
            'These are the points you get immediately when you order via SwipeRx Belanja app and NOT redeemable yet. These points will remain pending until the orders are delivered or fulfilled.',
        },
        [LoyaltyHistoryStatus.REDEEMED]: {
          title: 'Redeemed Points',
          description:
            'These are the points we deduct from your verified points after you redeem the points for rewards.',
        },
        [LoyaltyHistoryStatus.BONUS]: {
          title: 'Bonus Points',
          description:
            'These are the points that earned every time you make an order on a specially marked product or through our program with bonus points',
        },
        [LoyaltyHistoryStatus.DEDUCTED]: {
          title: 'Deducted Points',
          description:
            "These are the points that we will reduce when you return product(s) or cancel any order that we have already fulfilled. This deducted points will based on the return product(s) 'value.",
        },
        [LoyaltyHistoryStatus.EXPIRING_SOON]: {
          title: 'Expiring Soon Points',
          description:
            'These are the points that will be expired in the next 30 days. Once they expired, they can no longer be redeemed. All points will expire after 6 months, starting from the time your points got activated.',
        },
      },
      seeAllRewards: 'See All Rewards',
      allRewards: 'All Rewards',
      seeMore: 'See More',
      benefitAndTiers: 'Benefits & Tiers',
      howToEarn: 'How to earn',
      faq: 'Frequently Asked Questions',
      tnc: 'Terms and Conditions',
      cycle: "You're in the {month} month of your {cycle} cycle",
      viewMembership: 'View Membership',
      claimedReward: 'You have {{reward}} claimed reward(s)',
      pointsDescription: 'Earn points on every purchase and redeem it at SwipeRX Rewards here!',
      pointsExpiringSoon1: 'You have {{points}}',
      pointsExpiringSoon2: 'expiring soon',
      howToGetPoints: {
        title: 'How To Get Points?',
        orderFromSwiperx: {
          title: 'Order from SwipeRx app',
          description: 'You will get Rp 50 for every Rp 10.000 fully spent',
          action: 'Start Earning',
        },
        reachHigherTier: {
          title: 'Reach higher tiers to get Swiper Bonus',
          description: 'Get bonus points when you hit Gold, Platinum and Titanium tier',
        },
        participateInPromotion: {
          title: 'Participate in promotions',
          description: 'Get additional points by joining monthly campaigns',
        },
        getMoreAdditionalBenefits: {
          title: 'Get more additional benefits',
          description: 'COMING SOON',
        },
      },
      mostPopularRewards: 'Most Popular Rewards',
      missions: {
        title: 'Missions',
        description: 'Complete these missions to unlock exclusive rewards!',
        seeAllLink: 'See All',
        seeAllButton: 'SEE ALL {{total}} ACTIVE MISSIONS',
        viewCompletedButton: 'View your completed missions',
      },
    },
    history: {
      title: 'History',
      pointReceived: 'Points Received',
      pointUsed: 'Points Deducted',
      rollbackMessage: 'Failed to redeem {{rewardName}}. Reason: {{reason}}. RefId: {{referenceId}}',
      rollbackReturnMessage: 'Rollback return {{memo}}. Reason: {{reason}}',
    },
    membership: {
      title: 'My Membership',
      tierDescription: 'You’ve earned {{points}} points this cycle.',
      retainLevel: 'Earn {{points}} more points by {{date}} to maintain your {{level}} tier',
      pointsDescription: 'Earn points on every purchase and redeem it at SwipeRX Rewards here!',
      breakdown: {
        [LoyaltyHistoryStatus.VERIFIED]: 'Active Points',
        [LoyaltyHistoryStatus.PENDING]: 'Pending Points',
        [LoyaltyHistoryStatus.REDEEMED]: 'Redeemed Points',
        [LoyaltyHistoryStatus.DEDUCTED]: 'Deducted Points',
        [LoyaltyHistoryStatus.EXPIRING_SOON]: 'Expiring Soon',
        [LoyaltyHistoryStatus.BONUS]: 'Bonus Points',
      },
      redeem: 'Redeem',
      pointSummary: 'Point Summary',
      lastUpdate: 'Last update',
      seeAllPointsHistory: 'See All Points History',
      pointDefinition: 'Definition of Points',
      whatIsExpirationDate: 'What is expiration date?',
      expirationDateDetails:
        'Points that will expire in 30 days and after 30 days all points earned cannot be redeemed. All points will expire after 6 months since becoming active points.',
      redeemPointNow: 'Redeem Point Now',
      benefitsTitle: 'Membership Benefits',
      benefitsBtn: 'LEARN MORE',
      benefitsLevel: {
        [LoyaltyTierName.BRONZE]: `bronze member`,
        [LoyaltyTierName.SILVER]: ` silver member`,
        [LoyaltyTierName.GOLD]: `gold member`,
        [LoyaltyTierName.PLATINUM]: `platinum member`,
        [LoyaltyTierName.TITANIUM]: `titanium member`,
        [LoyaltyTierName.SPECIALCASHBACK]: `special_cashback member`,
        [LoyaltyTierName.IPECREGISTRANT]: `ipec_registrant member`,
        [LoyaltyTierName.VIP]: `vip member`,
      },
    },
    redeemFailed: {
      DEFAULT: 'Please contact our Customer Support',
      OL501: 'No coupons left',
      OL502: 'Not enough points',
      OL503: 'Please wait, your redemption is in progress',
      MP13: 'Customer number is blocked',
      MP14: 'Incorrect destination number',
      MP16: 'Number does not match with operator',
      MP106: 'Product is temporarily out of service',
      MP203: 'Number is too long',
      MP206: 'Destination number has been blocked',
    },
    redeemDialog: {
      title: 'Info Diperlukan',
      description: 'In order for us to process your redeem, please provide the following information.',
      privacyPolicyLabel: 'By clicking submit, you agree to our',
      privacyPolicyText: 'Privacy Policy',
      submitText: 'Submit',
      errorMessage: 'Please input the valid value',
    },
    redeemStatus: {
      [LoyaltyRewardRedeemDeliveryStatus.ORDERED]: 'Ordered',
      [LoyaltyRewardRedeemDeliveryStatus.DISPATCHED]: 'On Delivery',
      [LoyaltyRewardRedeemDeliveryStatus.DELIVERED]: 'Delivered',
      [LoyaltyRewardRedeemDeliveryStatus.CANCELED]: 'Cancelled',
      [LoyaltyRewardRedeemDeliveryStatus.EXPIRED]: 'Expired',
    },
    orderPotentialPoints: 'This order has potential to earn points',
    orderIncreasePoints: 'Increase your order to get points',
    orderEarnPoint: 'Earn',
  },
  loyaltyMission: {
    viewListProduct: 'view list of products',
    viewDetails: 'view details',
    promoProduct: '*Promo limited to selected products only',
    tab: {
      [LoyaltyMissionStatus.ACTIVE]: 'ACTIVE',
      [LoyaltyMissionStatus.COMPLETED]: 'COMPLETED',
      [LoyaltyMissionStatus.EXPIRED]: 'EXPIRED',
    },
    progress: {
      [LoyaltyMissionActivityTypeCode.GMV]: 'Reach {{value}} GMV',
      [LoyaltyMissionActivityTypeCode.ACTIVE_DAYS]: 'Reach {{value}} Monthly Active Days',
      [LoyaltyMissionActivityTypeCode.OPEN_APP]: 'Open app {{value}} times',
      [LoyaltyMissionActivityTypeCode.REQUEST_PRODUCT]: 'Request Product {{value}} times',
      [LoyaltyMissionActivityTypeCode.NOTIFY_ME]: 'Click product availability button {{value}} times',
      [LoyaltyMissionActivityTypeCode.REDEEM_POINTS]: 'Redeem {{value}} Rewards',
      [LoyaltyMissionActivityTypeCode.SKU_MIX]: 'Number of Product Variation: {{value}}',
    },
    pendingProgress: 'A total of {{value}} are still in the validation process',
    targetReachedLabel: 'Target Reached',
    targetReachedInfo: {
      title: 'How Target Reached works?',
      description:
        'Achievements are calculated based on completed transactions. You will see your achievements when the invoice is printed.',
    },
    date: {
      [LoyaltyMissionStatus.ACTIVE]: 'Ends on {{value}}',
      [LoyaltyMissionStatus.EXPIRING]: 'Ending soon on {{value}}',
      [LoyaltyMissionStatus.EXPIRED]: 'Expired on {{value}}',
      [LoyaltyMissionStatus.COMPLETED]: 'Completed on {{value}}',
    },
    rewardPoint: {
      [LoyaltyMissionStatus.ACTIVE]: 'Earn {{value}}',
      [LoyaltyMissionStatus.EXPIRING]: 'Earn {{value}}',
      [LoyaltyMissionStatus.EXPIRED]: 'Earn {{value}}',
      [LoyaltyMissionStatus.COMPLETED]: 'You won {{value}}',
    },
    rewardGift: {
      [LoyaltyMissionStatus.ACTIVE]: 'Get a {{value}}',
      [LoyaltyMissionStatus.EXPIRING]: 'Get a {{value}}',
      [LoyaltyMissionStatus.EXPIRED]: 'Get a {{value}}',
      [LoyaltyMissionStatus.COMPLETED]: 'You won a {{value}}',
    },
    tnc: 'Terms & Conditions',
    noData: 'There are no missions to display',
    noActiveTitle: 'Stay Tuned!',
    noActiveDescription: 'New mission is coming soon',
    completedDialog: {
      title: 'Congratulations!',
      description: 'You completed Level {{level}} of the {{mission}}! You have received',
      rewardPoints: '{{value}} points',
      viewNextLevel: 'View next level',
      viewCompletedMission: 'View completed mission',
    },
  },
  loyaltyRegistrationPage: {
    title: "Let's Get Started",
    description: `To discover our programs, <br />benefits and rewards in one place. <br /><br />Or still need more information <br />about our Loyalty Programs?<br />`,
    form: {
      pharmacyLabel: 'Pharmacy Name',
      pharmacyPlaceholder: 'Your registered pharmacy name',
      pharmacyInvalid: 'Invalid pharmacy name',
      phoneLabel: 'Phone Number',
      phonePlaceholder: 'Number that can be contacted',
      phoneInvalid: 'Invalid phone number',
      emailLabel: 'Email Address',
      emailPlaceholder: 'Email address that can be contacted',
      emailInvalid: 'Invalid email',
      agree: {
        toSwiperx: 'I agree to SwipeRx',
        termsOfUse: 'Terms of Use',
        and: 'and',
        privacyPolicy: 'Privacy Policy',
        invalid: 'Please check',
      },
    },
    readHere: 'Read Here',
    joinNow: 'Join Now',
    alreadyJoined: 'Oops! it looks like your pharmacy has already joined the program by using email',
    contactSupport:
      'If you feel there is an error, or want to change your email regarding loyalty program. Please contact our Customer Support.',
    contactUs: 'Contact Us',
    join_now: 'Join Now',
    learn_more: 'Learn More',
    next: 'ถัดไป',
    skip: 'ข้าม',
    walkthrough: {
      benefit: {
        title: 'Many Benefits',
        content: 'More shopping, more benefits',
      },
      extra_point: {
        title: 'Extra Point',
        content: 'Get bonus points from our promos',
      },
    },
  },
  loyaltyBanner: {
    member: 'Member',
    point: 'Point',
    mission: 'Mission',
    join_now: 'Join Now',
    learn_more: 'Learn More',
    next: 'ถัดไป',
    skip: 'ข้าม',
    walkthrough: {
      benefit: {
        title: 'Many Benefits',
        content: 'More shopping, more benefits',
      },
      extra_point: {
        title: 'Extra Point',
        content: 'Get bonus points from our promos',
      },
    },
    viewRewards: 'View Rewards',
    viewPlayNow: 'Play Now',
    active_mission: 'Active Missions',
    activeMissionsMessage1: 'You have ',
    activeMissionsMessage2: '{{totalActiveMissions}} active missions!',
    activeMissionsMessage3: '',
    pointsDescription: 'Earn points on every purchase and redeem it here!',
  },
  productDetailPage: {
    availablePromo: 'Available Promo',
    promoNotAvailable: 'Promotion is empty',
    detail: 'Detail',
    description: 'Description',
    composition: 'Composition',
    coreMolecule: 'Core Molecule',
    category: 'Category',
    atcClassification: 'ATC Classification',
    manufacturer: 'Manufacturer',
    shipmentTitle: 'Shipping',
    returnTitle: 'Return Policy',
    nearestExpiryDateTitle: 'Nearest Exp. Date',
    shipmentContent1:
      'จัดส่งผลิตภัณฑ์ภายใน 24 ชั่วโมง. หากคุณต้องการคืนสินค้า คุณมีเวลา 1 วันทำการ ตั้งแต่วันที่ส่งสินค้าเพื่อคืนสินค้าแบบไม่เสียค่าบริการ นี่คือตัวเลือกเพื่อคืนสินค้าของคุณ:\n1.ส่งคืนที่จุดส่งมอบ (point of delivery)\n2.ติดต่อฝ่ายลูกค้าสัมพันธ์ ที่ Line: @swiperx_th',
    shipmentContent2:
      'เงื่อนไขพิเศษ บางรายการของเราจะไม่สามารถคืนได้ การคืนสินค้าหลังจาก 1 วันทำการ นับจากวันที่ส่งสินค้าของคุณ ขึ้นอยู่กับนโยบายของผู้จัดจำหน่าย',
    addedToFavoriteProduct: 'Product is added to favorite.',
    removedFromFavoriteProduct: 'Product is removed from favorite.',
    addedToCart: 'item(s) has been added to the cart',
    addedToCartWithMaxQty:
      'As you have already made purchases of this product, you are eligible to buy only {{ maxPurchaseQty }} more unit(s) today.',
    seeCart: 'see',
    discount: 'Discount',
    minimum: 'Buy min.',
    totalPrice: 'Total Price',
    addToCart: 'เพิ่มในรถเข็น',
    outOfStock: 'สินค้าหมด',
    maxPurchase: 'Due to high demand, you can order only up to {{value}} unit(s) today.',
    maxPurchaseRemaining:
      'As you have already made purchases of this product, you are eligible to buy only {{ value }} more unit(s) today.',
    maxPurchaseReached: 'Maximum quantity reached, you can order only up to {{value}} unit(s) today.',
    maxPurchaseMonthlyReached:
      'You will reach {{value}} maximum monthly limit for this product after checkout. To do more purchasing after checkout, you may purchase it again next month or reach out to our Customer Service team to get further service',
    maxPurchaseMonthlyThresholdReached:
      'You almost hit your {{value}} monthly limit for this product. If you need to purchase more than monthly limit, before proceeding please reach out to our Customer Service team to get further service.',
    notify: 'เตือนฉัน',
    unnotify: 'Cancel Reminder',
    similarProducts: 'Similar Products',
    loyaltyPointTitle: 'This product can earn you',
    loyaltyPointPoints: 'points!',
    prekursorDocumentRequired: `This product required prekursor documents.`,
    instruction: `Instruction`,
    prekursorTooltipTour: {
      productDetail: 'You can find an example of prekursor document here',
    },
    maxQtyLimit: 'MAX {{value}}/day',
    productUnavailable: 'Product is not available',
    ...pharmacyTierInfo,
    ...tabsProductDetail,
  },
  homepage: {
    greetings: 'สวัสดี {{pharmacyName}}, ยินดีต้อนรับ!',
    categories: 'ประเภท',
    recentlyViewed: 'ดูล่าสุด',
    productInfo: 'มีผลิตภัณฑ์ 4000+ รายการ',
    competitivePrice: 'ผลิตภัณฑ์มีราคาที่น่าดึงดูด',
    sameDayDelivery: 'จัดส่งฟรี',
    more: 'MORE',
  },
  bannerListPage: {
    pageTitle: 'โปรโมชั่นทั้งหมด',
  },
  productListingPage: {
    cartModal,
    all: 'all',
    noProducts,
    marketingNoProducts: '{{productName}} ไม่ถูกจัดอยู่ในหมวดหมู่ {{playlistName}} ลองค้นหาผลิตภัณฑ์นี้ ที่นี่',
    search: productListingSearch,
    searchPlaceholder: 'ป้อนชื่อผลิตภัณฑ์ ...',
  },
  recentlyViewedPage: {
    title: 'ดูล่าสุด',
    pageEnd: 'คุณมาถึงบรรทัดสุดท้ายของหน้านี้',
    search: productListingSearch,
  },
  widget: {
    seeAll: 'ดูทั้งหมด',
    seeAllCategories: 'See All Product+',
  },
  maintenancePage: {
    title: `กำลังปรับปรุง`,
    subtitle: `หน้านี้กำลังปรับปรุง พวกเราจะกลับมาในเร็วๆนี้`,
    back: `back`,
    reloadMessage: 'เรามีการอัปเดตใหม่รอคุณอยู่ กรุณาเปิดแอปอีกครั้ง',
    close: 'Close',
  },
  purchase_order_document_requirement: {
    pendingPrekursorDocument: `You have pending prekursor document to upload`,
    prekursorTooltipTour: {
      banner: 'Click here to upload Prekursor Document',
      bannerTitle: 'Pending Prekursor Document',
    },
  },

  changePasswordPage: {
    title: 'โปรดสร้างรหัสผ่านและยินยันรหัสผ่านของคุณ เพื่อการสมัครใช้งาน SwipeRx PharmaSource อย่างเต็มรูปแบบ',
    newPass: 'รหัสผ่านใหม่',
    enterPass: 'พิมพ์รหัสผ่านเก่า',
    confirmPass: 'ยืนยันรหัสผ่าน',
    confirmPass2: 'พิมพ์รหัสผ่านใหม่อีกครั้ง',
    submitPass: 'เปลี่ยนรหัสผ่าน',
    successTitle: 'สำเร็จ!',
    successHeader: 'รหัสผ่านของคุณได้เปลี่ยนแล้ว โปรดใช้รหัสผ่าน',
    successFooter: 'ในการเข้าใช้งานครั้งถัดไป',
    LoginSwipe: 'เข้าสู่ระบบ',
    oldPass: 'รหัสผ่านเก่า',
    passwordReq:
      'โปรดป้อนอักขระอย่างน้อย 8 ตัว เราแนะนำให้ใส่ อักษรตัวใหญ่อย่างน้อย 1 อักษร, ตัวเล็ก 1 อักษร, ตัวเลข 1 ตัว และอักขระพิเศษ 1 ตัว เพื่อทำให้รหัสผ่านของคุณปลอดภัย (เช่น PTAbc12!)',
    generalError: 'คุณสามารถเปลี่ยนรหัสผ่านผ่านแอปพลิเคชัน SwipeRx โดยคลิกที่ "ลืมรหัสผ่าน"',
    validationEnterPass: 'กรุณาใส่รหัสผ่านของคุณ',
    validationMinPass: 'รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร',
    ValidationPassReq:
      'รหัสผ่านอย่างน้อย 8 อักขระ ควรมีตัวอักษรตัวพิมพ์ใหญ่ และตัวพิมพ์เล็กรวมกัน ต้องมีตัวเลข และอักขระพิเศษ',
    ValidationPassMatch: '"รหัสผ่านใหม่" และ "ยืนยันรหัสผ่าน" ต้องตรงกัน',
  },
  voucher: {
    vouchers: 'Vouchers',
    enterYourVoucherCodeHere: 'กรุณาระบุโค้ดส่วนลด',
    search: 'ตกลง',
    apply: 'ตกลง',
    applyNow: 'ตกลง',
    nextTime: 'ใช้คราวหน้า',
    limitedOffers: 'จำนวนจำกัด',
    validDate: 'ระยะเวลา',
    free: 'Free',
    oops: 'Oops!',
    close: 'Close',
    loadMore: 'Load More',
    USAGE_LIMIT_ERROR: 'การใช้บัตรกำนัลเกินขีดจำกัดแล้ว',
    LIMIT_PER_CUSTOMER_ERROR: 'การใช้บัตรกำนัลเกินขีดจำกัดแล้ว',
    QUANTITY_RANGE_ERROR: 'ปริมาณการสั่งซื้อไม่ตรงตามเงื่อนไขของเวาเชอร์',
    AMOUNT_RANGE_ERROR: 'ยอดสั่งซื้อไม่ตรงตามเงื่อนไขของเวาเชอร์',
    SELECTED_PHARMACIES_ERROR: 'ร้านขายยาของคุณไม่มีสิทธิ์สำหรับบัตรกำนัลนี้',
    SELECTED_DISTRIBUTORS_ERROR: 'ผู้จัดจำหน่ายไม่มีสิทธิ์ใช้เวาเชอร์นี้',
    SELECTED_DISTRIBUTOR_PRODUCTS_ERROR: 'สินค้าตัวแทนจำหน่ายไม่มีสิทธิ์สำหรับบัตรกำนัลนี้',
    SELECTED_PRODUCTS_ERROR: 'สินค้าไม่มีสิทธิ์สำหรับบัตรกำนัลนี้',
    PAYMENT_METHOD_ERROR: 'วิธีการชำระเงินไม่มีสิทธิ์สำหรับบัตรกำนัลนี้',
    OPEN_LOYALTY_ENROLLED_ERROR: 'ร้านขายยาของคุณยังไม่ได้ลงทะเบียนเข้าร่วมโปรแกรมความภักดี',
    OPEN_LOYALTY_POINT_SUFFICIENT_ERROR: 'คะแนนความภักดีของคุณไม่เพียงพอ',
    OPEN_LOYALTY_VOUCHER_REGISTERED_ERROR: 'บัตรกำนัลไม่ถูกต้อง',
    INVALID_VOUCHER_ERROR: 'บัตรกำนัลไม่ถูกต้อง',
    ANY_PENDING_PAYMENT_ERROR: `ไม่สามารถใช้บัตรกำนัลได้ในขณะที่มีการชำระเงินที่รอดำเนินการ กรุณาชำระเงินให้เสร็จก่อน`,
    INVALID_VOUCHER_RULES_ERROR: 'บัตรกำนัลไม่ถูกต้อง',
    VOUCHER_NOT_FOUND_ERROR: 'ไม่พบบัตรกำนัล',
  },
  unverifiedPharmacy: pharmacyTierInfo,
  snackbar: {
    unverifiedPharmacy: pharmacyTierInfo,
    maxQuantityLimit: {
      maxPurchaseDaily: 'Sorry! due to high demand, you can order only up to {{maxUnit}} unit(s) today.',
      maxPurchaseMonthly:
        'You will reach {{value}} maximum monthly limit for this product after checkout. To do more purchasing after checkout, you may purchase it again next month or reach out to our Customer Service team to get further service',
    },
    ok: 'Ok',
  },
  depositDetail: {
    depositInformation: 'Deposit information',
    refundInformation: 'Credit Note information',
    totalDeposit: 'Total Deposit',
    totalRefund: 'Total Credit Note',
    depositAllocatedTo: 'Deposit Allocated To',
    refundAllocatedTo: 'Credit Note Allocated To',
    full: 'Paid',
    partial: 'Partially paid',
    unallocated: 'Unallocated',
    invoiceNo: 'Invoice No.',
    orderNo: 'Order No.',
    needHelp: 'Need help?',
    contactCustomerService: `Please contact our Successful Customers via <0>{{customerServiceNumber}}</0> if you have any questions.`,
    showMore: 'Show more',
    creditMemoInformation: 'Credit Memo Information',
    creditNoteNumber: 'Credit Memo No.',
    creditMemoDocument: 'Credit Memo Document',
    creditMemoDocumentInProgress: 'Credit memo document is still being processed.',
    see: 'see',
    download: 'download',
    creditMemoNumberCopied: 'Credit Memo Number copied',
    invoiceNumberCopied: 'Invoice Number copied',
    totalAmount: 'Total Amount',
    outstandingAmount: 'Outstanding Amount',
    noInvoiceToDisplay: 'No available invoice to display',
    goBack: 'Go Back',
    depositWillBeAllocated: 'Your deposit will be automatically allocated to the available invoices soon',
    refundWillBeAllocated: 'Your refund will be automatically allocated to the available invoices soon',
  },
  similarProductListPage: {
    pageTitle: 'Similar Products',
    noProducts,
  },
  addToCartNotif: {
    title: 'เพิ่มในตะกร้าแล้ว!',
    message: 'คุณได้เพิ่ม {{productName}} ลงใน backet ของคุณแล้ว',
    action: 'ไปที่ตะกร้า',
  },
  availability: {
    timer: {
      maxPurchaseDaily:
        "You've reached the maximum unit(s) allowed for the purchase of the product. <0>It cannot be purchased again until tomorrow.</0>",
      maxPurchaseMonthly:
        'You will reach {{value}} maximum monthly limit for this product after checkout. To do more purchasing after checkout, you may purchase it again next month or reach out to our Customer Service team to get further service',
    },
  },
  noAvailableProducts: {
    title: 'The product is not available',
    subtitle: 'Please look for other products or contact the Customer Success Team for further assistance.',
    fuzzyTitle: 'Oops! No results for {{query}}',
    button: 'SEARCH FOR OTHER PRODUCTS',
    relatesTo: 'Related searches to {{query}}',
  },
};

export default lang;
