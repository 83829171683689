import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { DateTime } from 'components/common';
import { calculateAmountAfterTax, toCurrency } from 'utils/Format';
import { Typography } from '@material-ui/core';
import { useTooltipTour, TooltipTour } from 'components/common/TooltipTour';
import * as colors from 'components/styles/colors';
import { S } from './orders-details-card.styles';

let firstTooltipId: null | number = null;

interface Props {
  t: any;
  order: {
    id: number;
    distributor_name: string;
    po_number: string;
    ordered_at: Date;
    net_amount: number;
    vat: number;
    amount_after_tax: number;
    is_prekursor: boolean;
    is_alkes: boolean;
  };
  onClick: () => void;
}

export const OrderDetailCard: React.FC<React.PropsWithChildren<Props>> = ({ t, order, onClick }) => {
  const { tooltipParams, visible } = useTooltipTour('prekursorTooltipTour.orderSummary', 'bottom');
  const { is_prekursor, is_alkes } = order;

  useEffect(() => {
    if (!firstTooltipId && order.is_prekursor) firstTooltipId = order.id;
  }, [order]);

  const cardStatusHeaderList: { class: string; color: string; text: string }[] = [];
  if (!is_prekursor && !is_alkes) {
    cardStatusHeaderList.push({ class: 'primary', text: t('regularFlag'), color: colors.REGULAR_GRAY });
  } else if (is_prekursor && !is_alkes) {
    cardStatusHeaderList.push({ class: 'primary', text: t('prekursorFlag'), color: colors.ORANGE });
  } else if (!is_prekursor && is_alkes) {
    cardStatusHeaderList.push({ class: 'primary', text: t('alkesFlag'), color: colors.REGULAR_GRAY });
  } else {
    cardStatusHeaderList.push({ class: 'primary', text: t('prekursorFlag'), color: colors.ORANGE });
    cardStatusHeaderList.push({ class: 'secondary', text: t('alkesFlag'), color: colors.REGULAR_GRAY });
  }

  return (
    <S.Card onClick={() => onClick()} className={order.is_prekursor ? 'prekursor' : ''}>
      <>
        {cardStatusHeaderList.map((data) => (
          <S.CardStatusHeader container data-testid="order-card-header" color={data.color}>
            <Grid item className={data.class}>
              <S.Status>{data.text}</S.Status>
            </Grid>
          </S.CardStatusHeader>
        ))}
      </>
      <S.CardContainer>
        <S.Row>
          <Grid container alignItems="center" justify="space-between">
            <Grid item xs={7}>
              <DateTime dateTime={order.ordered_at} />
            </Grid>
            <Grid item xs={5} container direction="row" alignItems="flex-start" justify="flex-end" spacing={1}>
              <Grid item>
                <S.ViewDetailText data-testid={`seeDetails-btn-${order.po_number}`}>{t('seeDetails')}</S.ViewDetailText>
              </Grid>
              <Grid item>
                <S.ViewDetailIcon />
              </Grid>
            </Grid>
          </Grid>
        </S.Row>
        <S.CardHeader>
          <S.Title>{order.distributor_name}</S.Title>
        </S.CardHeader>
        <S.Row>
          <S.Subtitle>
            {t('orderNumber')} {order.po_number}
          </S.Subtitle>
        </S.Row>
        <S.Row>
          <Grid item xs={8}>
            <S.Label>{t('netAmount')}</S.Label>
            <S.Label>{t('eVat')}</S.Label>
          </Grid>
          <Grid item xs={8}>
            <S.Currency align="right" className="text-gray">
              {toCurrency(order.net_amount)}
            </S.Currency>
            <S.Currency align="right" className="text-gray">
              {toCurrency(order.vat * order.net_amount)}
            </S.Currency>
          </Grid>
        </S.Row>
        <S.DividerLine />
        <S.Row>
          <Grid item xs={8}>
            <S.Label>{t('amountAfterTax')}</S.Label>
          </Grid>
          <Grid item xs={8}>
            <S.Currency align="right" className="text-light">
              {toCurrency(calculateAmountAfterTax(order.net_amount, order.vat))}
            </S.Currency>
            {firstTooltipId === order.id && <div ref={tooltipParams.setTriggerRef} />}
          </Grid>
        </S.Row>
        {firstTooltipId === order.id && (
          <TooltipTour
            name="prekursorTooltipTour.orderSummary"
            tooltipParams={tooltipParams}
            visible={visible && order.is_prekursor}
            width={210}
            offsetX={-26}
            zIndex={10}
          >
            <Typography>{t('prekursorTooltipTour.orderSummary')}</Typography>
          </TooltipTour>
        )}
      </S.CardContainer>
    </S.Card>
  );
};
