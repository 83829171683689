/* eslint-disable no-console */
import { Action } from 'redux';
import { swipeRxPt } from 'services';

import { recordException } from 'utils/Reporting/Sentry';
import { formatOrderCreatePayload } from 'utils/helpers/format-order-request-payload';
import { trackOrderCheckoutEvent as causalFoundryTrackOrderCheckoutEvent } from 'utils/Analytics/CausalFoundry';
import { isWebviewIos } from 'utils/helpers/check-platform';
import { store } from 'store';
import * as constants from './constants';
import { ThunkActionCreator } from '../../../types/thunk';
import { Order, OrderDetails, OrderRequest } from '.';
import ClientRequest from '../../../utils/Client';
import { trackFailedCheckout } from '../../../utils/Analytics/Segment';

import { resetCartCounter } from '../Counter/actions';
import { CurrentOrderRating, FeebackOption, FeebackOptionValue, PurchasedItemQuantity } from './interface';
import { updateProductsExceedingLimit } from '../Cart/actions';

/**
 * Action Types
 */
export interface UpdateOrdersAction extends Action {
  type: constants.UPDATE_ORDERS;
  pending?: Order[];
  due?: Order[];
  history?: Order[];
  ordersDueBy?: Order[];
}

export interface OrdersDueByAction extends Action {
  type: constants.GET_ORDERS_DUE_BY;
  ordersDueBy?: Order[];
}

export interface UpdateOrderHistryAction extends Action {
  type: constants.ORDER_HISTORY | constants.REFRESH_HISTORY;
  history: Order[];
}

export interface UpdateOrderAction extends Action {
  type: constants.UPDATE_ORDER;
  selected?: OrderDetails;
}

export interface CreateOrdersAction extends Action {
  type: constants.CREATE_ORDERS;
  purchaseOrderBatchId: number;
  creating: boolean;
  orders: any;
  created: string[];
}

export interface FailOrdersAction extends Action {
  type: constants.FAIL_ORDERS;
  error: string;
}

export interface CreatingOrderAction extends Action {
  type: constants.ORDER_CREATING;
  creating: boolean;
}

export interface CreateOrderFailAction extends Action {
  type: constants.CREATE_ORDER_FAIL;
  createdFailed: boolean;
  creating: boolean;
}

export interface CloseCartErrorAction extends Action {
  type: constants.CLOSE_CART_ERROR;
}

export interface ResetLoaderErrorAction extends Action {
  type: constants.RESET_LOADER;
  creating: boolean;
}

export interface ToggleCancelledDialog extends Action {
  type: constants.TOGGLE_CANCELLED_ORDER_DIALOG;
  shouldShowCancelledOrderDialog: boolean;
}

export interface OpenOrderRatingAction extends Action {
  type: constants.OPEN_ORDER_RATING;
}

export interface CloseOrderRatingAction extends Action {
  type: constants.CLOSE_ORDER_RATING;
}

export interface OpenOrderStatusAction extends Action {
  type: constants.OPEN_ORDER_STATUS;
}

export interface CloseOrderStatusAction extends Action {
  type: constants.CLOSE_ORDER_STATUS;
}

export interface SetOrderRatingAction extends Action {
  type: constants.SET_ORDER_RATING;
  value: number | null;
}

export interface SetFeedbackOptions extends Action {
  type: constants.SET_FEEDBACK_OPTIONS;
  value: FeebackOption[];
}

export interface SetCheckedOptions extends Action {
  type: constants.SET_CHECKED_OPTIONS;
  value: FeebackOptionValue[];
}

export interface SetFeedbacks extends Action {
  type: constants.SET_FEEDBACKS;
  value: string;
}

export interface SetCurrentOrderRating extends Action {
  type: constants.SET_CURRENT_ORDER_RATING;
  value: CurrentOrderRating | null;
}

export interface SetOrderRatingLoading extends Action {
  type: constants.SET_ORDER_RATING_LOADING;
  value: boolean;
}

export interface ToggleOrderModal extends Action {
  type: constants.TOGGLE_ORDER_MODAL;
  value: boolean;
}
export interface OpenOrderRatingThankYou extends Action {
  type: constants.OPEN_ORDER_RATING_THANK_YOU;
}

export interface CloseOrderRatingThankYou extends Action {
  type: constants.CLOSE_ORDER_RATING_THANK_YOU;
}

export interface GetOrderSPDocument extends Action {
  type: constants.SET_PURCHASE_ORDER_REQUIREMENT_SP_DOCUMENT;
  payload: any;
}

export interface SetSPDocumentLoading extends Action {
  type: constants.SET_SP_DOCUMENT_LOADING;
  payload: any;
}

export interface FailGetSPDocument extends Action {
  type: constants.FAIL_GET_PURCHASE_ORDER_REQUIREMENT_SP_DOCUMENT;
  error: string;
}

export interface FetchPurchasedItemQuantity extends Action {
  type: constants.ORDER_ITEM_QUANTITY_HISTORY_CREATE;
  items: Array<PurchasedItemQuantity>;
}

export type Actions =
  | ToggleCancelledDialog
  | ResetLoaderErrorAction
  | CloseCartErrorAction
  | CreateOrderFailAction
  | UpdateOrdersAction
  | UpdateOrderHistryAction
  | OrdersDueByAction
  | UpdateOrderAction
  | CreateOrdersAction
  | FailOrdersAction
  | CreatingOrderAction
  | CloseOrderStatusAction
  | OpenOrderStatusAction
  | OpenOrderRatingAction
  | CloseOrderRatingAction
  | SetOrderRatingAction
  | SetFeedbackOptions
  | SetCheckedOptions
  | SetFeedbacks
  | SetCurrentOrderRating
  | SetOrderRatingLoading
  | ToggleOrderModal
  | OpenOrderRatingThankYou
  | CloseOrderRatingThankYou
  | GetOrderSPDocument
  | SetSPDocumentLoading
  | FailGetSPDocument
  | FetchPurchasedItemQuantity;

/**
 * Actions
 */

export const showCancelledOrderDialog: ThunkActionCreator<Actions> = () => async (dispatch) => {
  // Empty the selected order
  dispatch({ type: constants.TOGGLE_CANCELLED_ORDER_DIALOG, shouldShowCancelledOrderDialog: true });
};

export const closeCancelledOrderDialog: ThunkActionCreator<Actions> = () => async (dispatch) => {
  // Empty the selected order
  dispatch({
    type: constants.TOGGLE_CANCELLED_ORDER_DIALOG,
    shouldShowCancelledOrderDialog: false,
  });
};

export const sendSPDocumentViaEmail: ThunkActionCreator<Action> = (orders: any[]) => async (dispatch) => {
  try {
    const orderIds = orders.filter((order) => order.is_prekursor).map((order) => order.id);
    await swipeRxPt.orders.sendSPDocumentViaEmail(orderIds);
  } catch (error) {
    if (error instanceof Error) {
      recordException(error, 'sendSPDocumentViaEmail', { orders });
      dispatch({
        type: constants.FAIL_GET_PURCHASE_ORDER_REQUIREMENT_SP_DOCUMENT,
        error: error.message,
      });
    }
  }
};

export const fetchOrder: ThunkActionCreator<Actions> = (id: number) => async (dispatch) => {
  try {
    // Fetch order
    const selected: OrderDetails = (
      await ClientRequest.fetch(`products/orders/${id}`, {
        warp_token: localStorage.getItem('session_token'),
      })
    ).data;

    // Dispatch the order
    dispatch({ type: constants.UPDATE_ORDER, selected });
  } catch (error) {
    if (error instanceof Error) {
      recordException(error, 'fetchOrder', { id });
      console.error(error.message);
      // Dispatch the error
      dispatch({ type: constants.FAIL_ORDERS, error: error.message });
    }
  }
};

export const emptyOrder: ThunkActionCreator<Actions> = () => async (dispatch) => {
  // Empty the selected order
  dispatch({ type: constants.UPDATE_ORDER, selected: undefined });
};

export const creatingOrder: ThunkActionCreator<Actions> = () => async (dispatch) => {
  // Empty the selected order
  dispatch({ type: constants.ORDER_CREATING, creating: true });
};

export const closeCartError: ThunkActionCreator<Actions> = () => async (dispatch) => {
  // Empty the selected order
  dispatch({ type: constants.CLOSE_CART_ERROR });
};

export const resetLoader: ThunkActionCreator<Actions> = () => async (dispatch) => {
  // Empty the selected order
  dispatch({ type: constants.RESET_LOADER, creating: false });
};

export const createOrders: ThunkActionCreator<Actions> = (request: OrderRequest) => async (dispatch) => {
  // Set order numbers
  const orderNumbers: string[] = [];
  let orders: any = [];
  let purchaseOrderBatchId;
  const { distributors } = formatOrderCreatePayload(request);
  let directPaymentBilCode: string | null = null;
  const marketingCart = await swipeRxPt.cart.getMarketingCart();
  const {
    config: {
      market: { precursorIosDownload },
    },
  } = store.getState();
  try {
    // Dispatch creating
    dispatch({
      type: constants.CREATE_ORDERS,
      creating: true,
      created: orderNumbers,
      orders,
      purchaseOrderBatchId: 0,
    });

    dispatch(resetCartCounter(true));

    const result = await swipeRxPt.orders.create({
      distributors,
    });

    orders = result.data.orders;
    purchaseOrderBatchId = result.data.purchaseOrderBatchId;

    const billCode = result.data.bill_code;

    if (billCode) {
      directPaymentBilCode = billCode;
    }

    // Map the order numbers
    result.data.poNumbers.forEach((item) => orderNumbers.push(item));
  } catch (error) {
    const isMaxPurchaseLimitReached = (error as Error).name === 'MaxPurchaseLimitReachedError';

    if (isMaxPurchaseLimitReached) {
      const { data } = JSON.parse((error as Error).message);
      dispatch(updateProductsExceedingLimit([...data.products_exceeding_limit]));
    }

    recordException(error as Error, 'createOrders', { request });
    trackFailedCheckout((error as Error).message);
    // Dispatch the error
    dispatch({ type: constants.CREATE_ORDER_FAIL, createdFailed: true, creating: false });
    causalFoundryTrackOrderCheckoutEvent(request, orders, false, marketingCart?.cartMarketing?.items);
    return {
      success: false,
      isMaxPurchaseLimitReached,
      isPharmacySuspended: (error as Error).name === 'PharmacySuspendedError',
      isPaymentError: (error as Error).name === 'PaymentError',
      isLimitedActivePaymentError: (error as Error).name === 'LimitedActivePaymentError',
      isProductUnpurchaseableError: (error as Error).name === 'ProductUnpurchaseableError',
      billCode: directPaymentBilCode,
    };
  }

  // Dispatch end of creation
  dispatch({
    type: constants.CREATE_ORDERS,
    creating: false,
    created: orderNumbers,
    orders,
    purchaseOrderBatchId,
  });

  causalFoundryTrackOrderCheckoutEvent(request, orders, true, marketingCart?.cartMarketing?.items);
  if (isWebviewIos() && !precursorIosDownload) dispatch(sendSPDocumentViaEmail(orders));
  return {
    success: true,
    billCode: directPaymentBilCode,
  };
};

export const fetchOrderedProductQuantityHistory: ThunkActionCreator<Actions> = () => async (dispatch) => {
  try {
    const result = await swipeRxPt.orders.fetchProductOrderedHistory();

    dispatch({
      type: constants.ORDER_ITEM_QUANTITY_HISTORY_CREATE,
      items: result.map((history) => ({
        distributor_product_id: history.distributor_product_id,
        purchase_history_qty: history.purchase_history_qty,
        purchase_history_monthly_qty: history.purchase_history_monthly_qty,
      })),
    });
  } catch (error) {
    if (error instanceof Error) {
      recordException(error, 'fetchOrderedProductQuantityHistory');
    }
  }
};

export const setCreateOrderFailed: ThunkActionCreator<Actions> = () => async (dispatch) => {
  dispatch({ type: constants.CREATE_ORDER_FAIL, createdFailed: true, creating: false });
};

export const openOrderRating: ThunkActionCreator<Actions> = () => async (dispatch) => {
  dispatch({ type: constants.OPEN_ORDER_RATING });
};

export const closeOrderRating: ThunkActionCreator<Actions> = () => async (dispatch) => {
  dispatch({ type: constants.CLOSE_ORDER_RATING });
};

export const openOrderStatus: ThunkActionCreator<Actions> = () => async (dispatch) => {
  dispatch({ type: constants.OPEN_ORDER_STATUS });
};

export const closeOrderStatus: ThunkActionCreator<Actions> = () => async (dispatch) => {
  dispatch({ type: constants.CLOSE_ORDER_STATUS });
};

export const setOrderRating: ThunkActionCreator<Actions> = (rate: number | null) => async (dispatch) => {
  dispatch({ type: constants.SET_ORDER_RATING, value: rate });
};

export const setFeedbackOptions: ThunkActionCreator<Actions> = (options: FeebackOption[]) => async (dispatch) => {
  dispatch({ type: constants.SET_FEEDBACK_OPTIONS, value: options });
};

export const setCheckedOptions: ThunkActionCreator<Actions> = (options: FeebackOptionValue[]) => async (dispatch) => {
  dispatch({ type: constants.SET_CHECKED_OPTIONS, value: options });
};

export const setFeedbacks: ThunkActionCreator<Actions> = (feedbacks: string) => async (dispatch) => {
  dispatch({ type: constants.SET_FEEDBACKS, value: feedbacks });
};

export const setCurrentOrderRating: ThunkActionCreator<Actions> =
  (currentOrderRating: CurrentOrderRating | null) => async (dispatch) => {
    dispatch({ type: constants.SET_CURRENT_ORDER_RATING, value: currentOrderRating || null });
  };

export const setOrderRatingLoading: ThunkActionCreator<Actions> = (value: boolean) => async (dispatch) => {
  dispatch({ type: constants.SET_ORDER_RATING_LOADING, value });
};

export const toggleOrderModal: ThunkActionCreator<Actions> = (value: boolean) => async (dispatch) => {
  dispatch({ type: constants.TOGGLE_ORDER_MODAL, value });
};

export const openOrderRatingThankYou: ThunkActionCreator<Actions> = () => async (dispatch) => {
  dispatch({ type: constants.OPEN_ORDER_RATING_THANK_YOU });
};

export const closeOrderRatingThankYou: ThunkActionCreator<Actions> = () => async (dispatch) => {
  dispatch({ type: constants.CLOSE_ORDER_RATING_THANK_YOU });
};

export const getSPDocument: ThunkActionCreator<Action> = (purchaseOrderId: number) => async (dispatch) => {
  dispatch({
    type: constants.SET_SP_DOCUMENT_LOADING,
    payload: true,
  });

  try {
    const spDocument = await swipeRxPt.orders.getSPDocument(purchaseOrderId);
    if (spDocument) {
      dispatch({
        type: constants.SET_PURCHASE_ORDER_REQUIREMENT_SP_DOCUMENT,
        payload: spDocument,
      });
    }
  } catch (error) {
    if (error instanceof Error) {
      recordException(error, 'getSPDocument', { purchaseOrderId });
      dispatch({
        type: constants.FAIL_GET_PURCHASE_ORDER_REQUIREMENT_SP_DOCUMENT,
        error: error.message,
      });
    }
  }
};

export const clearSPDocument: ThunkActionCreator<Action> = () => async (dispatch) => {
  dispatch({
    type: constants.SET_PURCHASE_ORDER_REQUIREMENT_SP_DOCUMENT,
    payload: null,
  });
};
