import { ThunkActionCreator } from 'types/thunk';
import { recordException } from 'utils/Reporting';
import { State } from 'store';

import { MarketingFeaturesType } from 'services/swipe-rx-pt/resources/marketings/constants';
import { v3Client } from 'utils/Client/v3';
import { Actions } from './interface';
import * as constants from './constants';

interface MarketingFeaturesParams {
  features: MarketingFeaturesType[];
  fields?: string[];
}

const maxPageSize = Number(process.env.REACT_APP_MARKETING_FEATURES_MAX_PAGE_SIZE) || 10;
const expiredTime = Number(process.env.REACT_APP_MARKETING_FEATURES_TTL_IN_SECOND || 60) * 1000;

export const getMarketingFeatures: ThunkActionCreator<Actions, State> =
  (payload: MarketingFeaturesParams) => async (dispatch, getState) => {
    const { features = [MarketingFeaturesType.GENERAL], fields = ['id', 'name', 'type', 'config', 'position'] } =
      payload;

    const currentData = getState().marketingFeatures;
    const expiration = currentData.expired ?? 0;
    const now = Date.now();
    if (currentData.marketings) {
      if (expiration > now) return;
    }

    dispatch({
      type: constants.SET_MARKETING_FEATURES_LOADING,
      loading: true,
    });

    try {
      const data =
        (await v3Client.get('marketing', {
          is_published: true,
          features,
          fields,
          page: 1,
          page_size: maxPageSize,
          order_by: 'random',
        })) || [];

      dispatch({
        type: constants.GET_MARKETING_FEATURES,
        marketing: data.data ?? [],
        expired: Date.now() + expiredTime,
      });
    } catch (error) {
      recordException(error as Error, 'searchQuery', { payload });
      const errMsg = (error as Error).message;
      // eslint-disable-next-line no-console
      console.error(errMsg);
    } finally {
      dispatch({
        type: constants.SET_MARKETING_FEATURES_LOADING,
        loading: false,
      });
    }
  };
