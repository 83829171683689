import { useSelector, shallowEqual } from 'react-redux';
import { State as StoreState } from 'store';
import { Product } from 'store/reducers/Product';
import { get20Percent } from 'utils/helpers';
import { ProductPurchaseLimit } from 'store/reducers/Counter';

export const useProductLimit = (
  product: Product,
  counterList: any,
): {
  maxPurchasedReached: boolean;
  productLimitExceeded: boolean;
  stockLimitExceeded: boolean;
  maxPurchaseLimit: number;
  purchaseableQuantity: number;
  remainingStock: number;
  maxQuantityEnabled: boolean;
  hasPurchased: boolean;
  purchasedQuantityMonthly: number;
  maxPurchaseMonthlyThreshold: number;
  maxPurchaseMonthlyReached: boolean;
  productMonthlyLimitExceeded: boolean;
  maxPurchaseMonthlyLimit: number;
  purchaseableMonthlyQuantity: number;
  maxMonthlyQuantityEnabled: boolean;
} => {
  const productPurchaseLimits = useSelector((state: StoreState) => state.counter.productPurchaseLimit);
  const cartMaxPurchaseLimits = useSelector((state: StoreState) => state.counter.cartMaxPurchaseLimit, shallowEqual);

  let limit: ProductPurchaseLimit;
  limit = productPurchaseLimits.find(
    (history) => history.distributor_product_id === product.distributor_product_id,
  ) || {
    distributor_product_id: product.distributor_product_id,
    max_monthly_qty: 0,
    max_qty: 0,
    max_qty_enabled: false,
    max_qty_monthly_enabled: false,
    purchase_history_monthly_qty: 0,
    purchase_history_qty: 0,
  };

  const productCartLimit = cartMaxPurchaseLimits[product.name]?.[product.id]?.cartLimit;
  if (productCartLimit) {
    const cartLimit = {
      distributor_product_id: product.distributor_product_id,
      max_monthly_qty: productCartLimit?.monthly_max_qty,
      max_qty: productCartLimit?.max_qty,
      max_qty_enabled: productCartLimit?.max_qty_enabled,
      max_qty_monthly_enabled:
        (productCartLimit?.max_qty_enabled &&
          productCartLimit?.monthly_max_qty &&
          productCartLimit?.monthly_max_qty > 0) ||
        false,
      purchase_history_monthly_qty: productCartLimit?.purchase_history_qty_monthly,
      purchase_history_qty: productCartLimit?.purchase_history_qt,
    };

    if (!(cartLimit === limit)) {
      limit = cartLimit;
    }
  }

  const maxQuantityEnabled = Boolean(limit?.max_qty_enabled) || false;
  const maxPurchaseQuantity = limit?.max_qty || 0;
  const purchasedQuantity = limit?.purchase_history_qty || 0;
  const cartItemCount = counterList[product.id] ? counterList[product.id].count : 0;

  const remainingStock = product.remaining_quantity - product.quantity_threshold;
  const purchaseableQuantity =
    maxQuantityEnabled && limit?.max_qty ? limit.max_qty - purchasedQuantity : remainingStock;
  const maxPurchasedReached = maxQuantityEnabled && limit?.max_qty ? maxPurchaseQuantity <= purchasedQuantity : false;
  const productLimitExceeded = maxQuantityEnabled && limit?.max_qty ? purchaseableQuantity <= cartItemCount : false;

  const maxMonthlyQuantityEnabled = Boolean(limit?.max_qty_monthly_enabled) || false;
  const maxPurchaseQuantityMonthly = limit?.max_monthly_qty || 0;
  const purchasedQuantityMonthly = limit?.purchase_history_monthly_qty || 0;
  const purchaseableMonthlyQuantity =
    maxMonthlyQuantityEnabled && limit?.max_monthly_qty
      ? limit.max_monthly_qty - purchasedQuantityMonthly
      : remainingStock;

  const maxPurchaseMonthlyReached =
    maxMonthlyQuantityEnabled && limit?.max_monthly_qty
      ? maxPurchaseQuantityMonthly <= purchasedQuantityMonthly
      : false;

  const maxPurchaseMonthlyThreshold = maxPurchaseQuantity
    ? maxPurchaseQuantityMonthly - maxPurchaseQuantity > 0
      ? maxPurchaseQuantityMonthly - (maxPurchaseQuantityMonthly - maxPurchaseQuantity)
      : maxPurchaseQuantityMonthly - get20Percent(maxPurchaseQuantityMonthly)
    : maxPurchaseQuantityMonthly - get20Percent(maxPurchaseQuantityMonthly);

  const productMonthlyLimitExceeded =
    maxMonthlyQuantityEnabled && limit?.max_monthly_qty ? purchaseableMonthlyQuantity <= cartItemCount : false;

  const stockLimitExceeded = cartItemCount >= remainingStock;

  return {
    maxPurchasedReached,
    productLimitExceeded,
    stockLimitExceeded,
    purchaseableQuantity,
    remainingStock,
    maxPurchaseLimit: limit?.max_qty || 0,
    maxQuantityEnabled,
    hasPurchased: maxQuantityEnabled && !!purchasedQuantity,
    purchasedQuantityMonthly,
    maxPurchaseMonthlyThreshold,
    maxPurchaseMonthlyReached,
    productMonthlyLimitExceeded,
    maxPurchaseMonthlyLimit: limit?.max_monthly_qty || 0,
    purchaseableMonthlyQuantity,
    maxMonthlyQuantityEnabled,
  };
};
