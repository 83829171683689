import * as React from 'react';

import delay from 'lodash/delay';
import { routeKey } from 'utils/route.utils';
import Grid from '@material-ui/core/Grid';
import { OrderAction } from 'components/common';
import ShowIf from 'components/views/ShowIf';
import { Header, HeaderContent } from 'components/common/header';
import { isDirectPaymentEnabled, getPaymentProvider, getPaymentMethod, isVoucherEnabled } from 'utils/MarketConfig';
import { PaymentMethods, PaymentProvider } from 'utils/constants';
import { shouldShowCreditInfo } from 'utils/helpers/show-credit-info';
import { TranslateProps } from 'utils/Localization/types';
import { IVoucher } from 'services/swipe-rx-pt/resources/voucher/interface/voucher.interface';
import { swipeRxPt } from 'services';

import PrepSignedPurchaseOrder from './components/PrepSignedPurchaseOrder';
import RateYourExperience from './components/RateYourExperience';
import OrderDetail from './components/OrderDetail';
import { PricesContainer } from './components/PriceContainer';
import IntroContainer from './components/IntroContainer';
import { DIRECT_PAYMENT_QR_CODE_ROUTE } from './constants';
import { DirectPaymentProps, DispatchProps, OwnProps, State, StateProps } from './interface';

import { Voucher } from '../PaymentsPage/components/Voucher';
import { VoucherErrorDialog } from '../PaymentsPage/components/Voucher/components/VoucherErrorDialog';
import Container from '../../views/Container';
import Layout from '../../views/Layout';
import Content from '../../views/Content';
import * as colors from '../../styles/colors';
import { toDirectPaymentRoute } from '../DirectPaymentPage/utils/to-direct-payment-route';

class OrderConfirmed extends React.Component<StateProps & DispatchProps & TranslateProps & OwnProps, State> {
  state = {
    showConfirmDialog: false,
    isVoucherSelectionOpen: false,
    showAppliedVoucher: false,
    isConfirmingPayment: false,
  };

  minimumAmount = 250000;

  timer!: NodeJS.Timer;

  componentDidUpdate(): void {
    const { history, orders, paymentMethod } = this.props;

    if (orders.length === 0 && paymentMethod) {
      history.push(`${routeKey('main_payments')}/unpaid`);
    }
  }

  componentWillUnmount(): void {
    const { clearSelectedVoucher, clearVoucherApplied } = this.props;

    clearTimeout(this.timer);
    clearSelectedVoucher();
    clearVoucherApplied();
  }

  private getTotalAmountAfterTax(): number {
    const { orders } = this.props;
    const totalAmountAfterTax = orders.reduce((total, order) => total + order.amount_after_tax, 0);
    return totalAmountAfterTax;
  }

  private getTotalNetAmount(): number {
    const { orders } = this.props;
    const totalNetAmount = orders.reduce((total, order) => total + order.net_amount, 0);
    return totalNetAmount;
  }

  showConfirmDialog = (): boolean => {
    const { showConfirmDialog } = this.state;

    this.setState({ showConfirmDialog: true });

    return showConfirmDialog;
  };

  closeConfirmDialog = (): void => {
    this.setState({ showConfirmDialog: false });
  };

  goTo = (page: string) => () => {
    const { history, location } = this.props;
    history.replace(`${page}${location.search}`);
  };

  goBack = (): void => {
    const { history } = this.props;
    history.goBack();
  };

  confirmPayment = (): void => {
    const { history, location, orders, voucherApplied, applyVoucher } = this.props;
    const voucherEnabled = isVoucherEnabled();

    if (getPaymentProvider() === PaymentProvider.DIGIO) {
      const { billCode } = location.state as DirectPaymentProps;

      if (voucherEnabled && voucherApplied) {
        if (!billCode) {
          throw new Error('Bill code is not provided');
        }

        applyVoucher(
          PaymentMethods.DIRECT_PAYMENT,
          voucherApplied.code,
          {
            billCode,
          },
          (response) => {
            const totalAmountAfterTax = this.getTotalAmountAfterTax();
            const afterVoucherDiscount = totalAmountAfterTax - response.voucherUsage.total_discount;
            const paid = afterVoucherDiscount <= 0;

            toDirectPaymentRoute(history, billCode, paid);
          },
        );
      } else {
        history.push(`${DIRECT_PAYMENT_QR_CODE_ROUTE}/${billCode}`);
      }
    } else if (getPaymentProvider() === PaymentProvider.DOKU) {
      const { setPaymentInformation } = this.props;
      const { billCode } = location.state as DirectPaymentProps;
      setPaymentInformation({ hidden: false, showDepositRequestWarning: false });

      if (!billCode) {
        throw new Error('Deposit Request is not provided');
      }

      if (voucherEnabled && voucherApplied) {
        const purchaseOrderIds = orders.map((order) => order.id) as number[];
        applyVoucher(
          PaymentMethods.DIRECT_PAYMENT,
          voucherApplied.code,
          {
            purchaseOrderIds,
            depositRequestId: +billCode, // FIXME: is this bill code or payment direct id
          },
          (response) => {
            const totalAmountAfterTax = this.getTotalAmountAfterTax();
            const afterVoucherDiscount = totalAmountAfterTax - response.voucherUsage.total_discount;
            const isFullyPaidByVoucher = afterVoucherDiscount <= 0;

            if (isFullyPaidByVoucher) {
              setPaymentInformation({ hidden: true });
              this.toggleConfirmPayment();

              delay(() => {
                this.toggleConfirmPayment();

                history.push(`${routeKey('main_payments')}/paid`);
              }, 1000);
            } else {
              history.push(`${routeKey('main_payments')}/unpaid`);
            }
          },
        );
      } else {
        history.push(`${routeKey('main_payments')}/unpaid`);
      }
    }
  };

  toggleConfirmPayment = () => {
    const { isConfirmingPayment } = this.state;

    this.setState({
      isConfirmingPayment: !isConfirmingPayment,
    });
  };

  showVoucherSelection = () => {
    this.setState({
      isVoucherSelectionOpen: true,
    });
  };

  toggleVoucherSelection = (): void => {
    const { isVoucherSelectionOpen } = this.state;

    if (!isVoucherSelectionOpen) {
      swipeRxPt.voucherTracking.trackListOpen();
    }

    this.setState({
      isVoucherSelectionOpen: !isVoucherSelectionOpen,
    });
  };

  showVoucherDetail = (): void => {
    this.setState({
      showAppliedVoucher: true,
    });
  };

  toggleVoucherDetail = (): void => {
    const { showAppliedVoucher } = this.state;

    this.setState({
      showAppliedVoucher: !showAppliedVoucher,
    });
  };

  onCancelVoucherSelection = (): void => {
    const { clearSelectedVoucher } = this.props;

    this.toggleVoucherSelection();

    clearSelectedVoucher();
  };

  onVoucherApplied = (voucher: IVoucher): void => {
    const { checkEligibility, clearSelectedVoucher, orders } = this.props;

    const options = {
      purchaseOrderIds: orders.map((order) => order.id) as number[],
    };

    checkEligibility(voucher.code, options, () => {
      this.toggleVoucherSelection();
      clearSelectedVoucher();
    });
  };

  onConfirmRemoveVoucher = (): void => {
    this.showVoucherSelection();
    this.showVoucherDetail();
  };

  onVoucherRemoved = (): void => {
    const { clearVoucherApplied } = this.props;

    this.toggleVoucherDetail();
    this.toggleVoucherSelection();

    clearVoucherApplied();
  };

  onVoucherErrorClose = (): void => {
    const { clearVoucherApplied } = this.props;

    clearVoucherApplied();
  };

  render(): JSX.Element {
    const { isVoucherSelectionOpen, showAppliedVoucher, isConfirmingPayment } = this.state;
    const { t, orders, location } = this.props;
    const { vouchers, voucherApplied, vouchersMeta, voucherError } = this.props;
    const { totalItem } = location.state as DirectPaymentProps;
    const amount = this.getTotalNetAmount();
    const voucherEnabled = isVoucherEnabled();

    return (
      <Layout full background={colors.OFF_WHITE}>
        <Container>
          <ShowIf condition={!isVoucherSelectionOpen}>
            <Header>
              <HeaderContent goBack={this.goBack} title={t('title')} />
            </Header>
          </ShowIf>
          {/* </AppNav> */}
          <Content>
            <PricesContainer>
              <Grid container direction="column" justify="space-between">
                <Grid item style={{ margin: '16px' }}>
                  <IntroContainer t={t} />
                </Grid>
                <ShowIf condition={shouldShowCreditInfo()}>
                  <>
                    <PrepSignedPurchaseOrder t={t} />
                    <br />
                  </>
                </ShowIf>

                <OrderDetail orders={orders} t={t} />
              </Grid>
              <br />
              <RateYourExperience t={t} />
            </PricesContainer>
          </Content>

          {isDirectPaymentEnabled() && getPaymentMethod() === PaymentMethods.DIRECT_PAYMENT && (
            <OrderAction
              item={totalItem}
              amount={amount}
              showVoucherSelection={voucherEnabled}
              appliedVoucher={voucherApplied}
              onButtonClick={this.confirmPayment}
              onSelectVoucher={this.toggleVoucherSelection}
              confirmRemoveVoucher={this.onConfirmRemoveVoucher}
              confirmingPayment={isConfirmingPayment}
              t={t}
            >
              {t('checkout')}
            </OrderAction>
          )}
        </Container>

        {voucherEnabled && (
          <Voucher
            open={isVoucherSelectionOpen}
            options={vouchers}
            applied={voucherApplied}
            showDetail={showAppliedVoucher}
            onClose={this.onCancelVoucherSelection}
            onApply={this.onVoucherApplied}
            onRemove={this.onVoucherRemoved}
            meta={vouchersMeta}
          />
        )}

        {voucherEnabled && <VoucherErrorDialog onClose={this.onVoucherErrorClose} validationError={voucherError} />}
      </Layout>
    );
  }
}

export default OrderConfirmed;
