export enum MarketingFeaturesType {
  GENERAL = 'general-search-suggestion',
}

export enum MarketingFeaturesDisplayType {
  CAROUSEL = 'carousel',
  LIST = 'list',
}

export enum MarketingFeaturesDisplayPosition {
  AFTER_LISTING = 'after_listing',
  BETWEEN_LISTING = 'between_listing',
}
