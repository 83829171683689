import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const RateYourExperienceContainer = styled(Grid)`
  .container-wrapper {
    padding: 0 8px 0 8px;
  }

  .product-name {
    font-size: 8pt;
    color: #666666;
    font-weight: bolder;
  }

  .order-totals {
    font-size: 12px;
    color: #888888;
  }

  .border-padding {
    padding: 12px 0 12px 0;
  }

  .discounted-amount {
    color: #888888;
    font-size: 10pt;
    font-weight: bolder;
  }

  .total-after-vat {
    font-size: 10pt;
    color: #888888;
  }

  .container-footer {
    padding: 8px !important;
    background-color: #eeeeee;
    border: 0.5px solid #cccccc;
  }

  .container-footer-details {
    padding: 0 8px;
  }

  .card-content {
    padding-bottom: 4px !important;
  }

  .order-total-amount {
    color: #269781;
    font-size: 11pt;
    font-weight: bolder;
  }

  .view-order {
    font-size: 12px;
    font-weight: bold;
  }

  .our-team {
    width: 210px;
    font-size: 12px;
    padding-top: 8px;
    text-align: center;
  }

  .customer-success {
    width: 210px;
    font-size: 12px;
    padding-top: 24px;
    text-align: center;
    padding-bottom: 64px;
  }

  .customer-success .text-primary {
    font-size: 14px;
    color: #14c47d;
    display: inline-flex;
    text-decoration: underline;
  }

  .green-text {
    width: 220px;
    color: #269781;
    font-size: 14pt;
    padding-top: 8px;
    text-align: center;
    font-weight: bolder;
  }
`;
