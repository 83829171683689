import { OrderRequest } from 'store/reducers/Orders';
import { SwipeRxPt } from '../../SwipeRxPt.class';

import { OrderList, OrderListParams, OrderParams, OrderPatchPayload } from './interfaces';
import { OrderedProductHistory } from './interfaces/product-limit-history.type';

export class SwipeRxPtOrders {
  private readonly base: SwipeRxPt;

  constructor(base: SwipeRxPt) {
    this.base = base;
  }

  /**
   * @deprecated use listV2
   * @param params
   */
  async list(params?: OrderListParams): Promise<OrderList> {
    const result = await this.base.get('/orders', params);

    return result;
  }

  /**
   * Uses `getV2`
   * @param params
   */
  async listV2(params?: OrderListParams): Promise<OrderList> {
    const result = await this.base.getV2('/orders', params);

    return result;
  }

  async create(params: OrderRequest): Promise<any> {
    const result = await this.base.post('/orders', params);

    return result;
  }

  async retrieve(id: number, params?: OrderParams): Promise<any> {
    const { data } = await this.base.get(`/orders/${id}`, params);

    return data;
  }

  async update(id: number, params?: OrderPatchPayload): Promise<any> {
    const { data } = await this.base.patch(`/orders/${id}`, params);

    return data;
  }

  async getRating(id: number): Promise<any> {
    const { data } = await this.base.getV2(`/orders/${id}/rating`);

    return data;
  }

  async getUnreadActivityCount(): Promise<any> {
    const { data } = await this.base.get(`/orders/unread-activities`);
    return data;
  }

  async getSPDocument(purchaseOrderId: number): Promise<any> {
    const { data } = await this.base.getV2(`/orders/${purchaseOrderId}/sp-pdf`);

    return data;
  }

  async sendSPDocumentViaEmail(orderIds: number[]): Promise<any> {
    const { data } = await this.base.getV2(`/orders/send-sp-pdf-via-email`, {
      orderIds,
    });
    return data;
  }

  async fetchProductOrderedHistory(): Promise<OrderedProductHistory[]> {
    const { data } = await this.base.getV2(`/product-history`);
    return data;
  }
}
