import * as Redux from 'redux';
import * as constants from './constants';
import { Actions, CartProduct } from './actions';
import { PurchasedItemQuantity } from '../Orders';

export interface purchaseOrderBatch {
  rating: number;
  id: number;
  comments: string | null;
}

export interface Distributor {
  id: number;
  name: string;
  minimum_invoice_amount: number;
  is_minimum_invoice_meet: boolean;
}

export interface ProductPurchaseLimit extends PurchasedItemQuantity {
  max_qty_enabled: boolean;
  max_qty_monthly_enabled: boolean;
  max_qty: number;
  max_monthly_qty: number;
}
export interface State {
  counterList: any;
  counterIsEmpty: boolean;
  totalItem: number;
  totalAmount: number;
  cartItems: CartProduct;
  unavailableProducts: any;
  purchaseOrderBatch: purchaseOrderBatch;
  cartMaxPurchaseLimit: any;
  productPurchaseLimit: Array<ProductPurchaseLimit>;
}

const InitialState: State = {
  counterList: {},
  totalItem: 0,
  totalAmount: 0,
  cartItems: {},
  unavailableProducts: {},
  counterIsEmpty: true,
  purchaseOrderBatch: {
    id: 0,
    rating: 0,
    comments: null,
  },
  cartMaxPurchaseLimit: {},
  productPurchaseLimit: [],
};

const CounterListReducer: Redux.Reducer<State, Actions> = (state = InitialState, action) => {
  switch (action.type) {
    case constants.UPDATE_PRODUCT_QUANTITY:
      return {
        ...state,
        counterIsEmpty: false,
        counterList: action.counterList,
        totalItem: action.totalItem,
        totalAmount: action.totalAmount,
        cartItems: action.cartItems,
      };
    case constants.UPDATE_CART_ITEM:
      return {
        ...state,
        counterList: action.counterList,
        totalItem: action.totalItem,
        totalAmount: action.totalAmount,
        cartItems: action.cartItems,
        unavailableProducts: action.unavailableProducts,
        alternative: action.alternative,
        loading: action.loading,
      };
    case constants.RESET_CART_COUNTER: {
      return {
        ...state,
        counterList: action.counterList,
        totalItem: action.totalItem,
        totalAmount: action.totalAmount,
        cartItems: action.cartItems,
        purchaseOrderBatch: action.purchaseOrderBatch,
      };
    }
    case constants.UPDATE_PURCHASE_ORDER_RATING: {
      return {
        ...state,
        purchaseOrderBatch: action.purchaseOrderBatch,
      };
    }
    case constants.MAX_QUANTITY_CART_ITEM: {
      return {
        ...state,
        cartMaxPurchaseLimit: action.cartMaxPurchaseLimit,
      };
    }
    case constants.MAX_QUANTITY_CART_ITEM_ADD: {
      const newProductIds = action.items.map((item) => item.distributor_product_id);
      const oldPurchaseLimits = state.productPurchaseLimit.filter(
        (product) => !newProductIds.includes(product.distributor_product_id),
      );

      return {
        ...state,
        productPurchaseLimit: [...oldPurchaseLimits, ...action.items],
      };
    }
    default:
      return state;
  }
};

export default CounterListReducer;
