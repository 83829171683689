import React, { useState, useCallback, useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { translate } from 'react-i18next';
import debounce from 'lodash/debounce';

import ShowIf from 'components/views/ShowIf';
import { useUnverifiedInfoHook } from 'components/common/snackbar';
import { useAddProductAnalytics } from 'hooks/useAddProductAnalytics';
import { State as AuthState } from 'store/reducers/Auth';
import { getMarketId } from 'utils/MarketConfig';

import { useMarketing } from 'hooks/use-marketing';
import { SimilarProductsButton } from 'components/common/SimilarProductsButton';
import {
  incrementProductQuantity,
  decrementProductQuantity,
  updateProductQuantity,
  updateCartItem,
  CartProduct,
  UpdateCartItemType,
} from 'store/reducers/Counter/actions';
import { Product } from 'store/reducers/Product';
import { store, State as StoreState } from 'store';
import { clearProductListing } from 'store/reducers/ProductListing/actions';
import { setOpenSnackbarCart } from 'store/reducers/Utils/actions';

import { getEventPageIdentity } from 'utils/Analytics/Segment/Helper';
import { Color, TourIds } from '../../ProductCard.interface';

import { QuantityBox } from './ProductCardQuantityBox.component';
import { S } from './ProductCardQuantityBox.styles';
import { useProductLimit } from './hooks/use-product-limit.hook';
import { ProductContext } from '../../ProductCard.context';

interface StateProps {
  counterList: any;
  auth: AuthState;
}

interface DispatchProps {
  increment: (product: Product) => any;
  decrement: (product: Product) => any;
  updateCounter: (product: Product, quantity: number) => any;
  updateCartItem: (product: CartProduct, type: UpdateCartItemType) => void;
}

type Props = {
  color: Color;
  product: Product;
  tourIds?: TourIds;
  t: any;

  fullWidth?: boolean;
  text?: string;
  onClick?: () => void;
  changeColor: (itemCount: number) => void;
  isUnverifiedCartOpen?: boolean;
  marketing_id?: number | string | undefined;
  marketing_name?: string | undefined;
  isLoyaltyPointsShown?: boolean | undefined;
  similarProductList?: Product[] | undefined;
} & StateProps &
  DispatchProps;

export const ProductQuantityBoxContainerBase: React.FC<React.PropsWithChildren<Props>> = ({
  color,
  product,
  tourIds,
  increment,
  decrement,
  counterList,
  auth,
  updateCounter,
  updateCartItem,
  changeColor,
  onClick,
  t,
  isUnverifiedCartOpen,
  marketing_id,
  marketing_name,
  isLoyaltyPointsShown,
  similarProductList,
  ...restProps
}) => {
  const { text = t('add'), fullWidth = false } = restProps;
  const marketId = getMarketId();
  const { push } = useHistory();
  const [showQuantityBox, setShowQuantityBox] = useState(false);
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { indexNumber } = useContext(ProductContext);
  const { filterToolbar } = store.getState();
  const { trackProductWithMarketing } = useAddProductAnalytics(
    marketing_id,
    marketing_name,
    filterToolbar.selectedFilters,
    indexNumber,
  );
  const { marketing } = useMarketing();
  const { showMessage } = useUnverifiedInfoHook(auth, product);
  const { maxPurchasedReached, maxPurchaseMonthlyReached, purchaseableQuantity, hasPurchased } = useProductLimit(
    product,
    counterList,
  );

  const { REACT_APP_CLICK_DEBOUNCE = 300, REACT_APP_CLICK_DEBOUNCE_MAX_WAIT = 1000 } = process.env;
  const wait = +REACT_APP_CLICK_DEBOUNCE;
  const maxWait = +REACT_APP_CLICK_DEBOUNCE_MAX_WAIT;
  const updateCart = useCallback(
    debounce(
      (product: CartProduct, type: UpdateCartItemType) => {
        updateCartItem(product, type);
      },
      wait,
      { leading: false, trailing: true, maxWait },
    ),
    [updateCartItem],
  );
  const cartItem =
    counterList?.[product.id]?.cartItems?.[product?.name]?.[product?.id] ?? counterList[product?.id] ?? null;
  const marketingId = marketing_id ?? marketing?.id ?? cartItem?.marketing_id;
  const productWithMarketingId = {
    ...product,
    ...(marketingId ? { marketing_id: marketingId } : {}),
  };

  const showHandleQuantityBox = (product: any): void => {
    trackProductWithMarketing(product);
    setShowQuantityBox(true);
    const updatedProduct = increment(productWithMarketingId);
    updateCart(updatedProduct, UpdateCartItemType.CREATE_OR_UPDATE);
  };

  const showSnackbarCart = (): void => {
    dispatch(
      setOpenSnackbarCart({
        open: true,
        productId: product.id,
        itemCount: 1,
        maxPurchaseLimit: {
          purchaseableQuantity,
          hasPurchased,
        },
      }),
    );
  };

  const counter =
    counterList === undefined || counterList[product.id] === undefined ? null : counterList[product.id].count;

  let showQuantityBoxCounter = false;
  if (showQuantityBox && counter) {
    showQuantityBoxCounter = false;
  }

  const goToSimilarProductList = (): void => {
    dispatch(clearProductListing());
    push(`/${marketId}/similar-products/${product?.sku_code}`, { initial_page: getEventPageIdentity(match) });
  };

  return (
    <>
      {showQuantityBoxCounter ||
      (counterList && counterList[product.id] && counterList[product.id].count !== 0) ||
      isUnverifiedCartOpen ? (
        <div data-name={`productQuantityBox${product.id}`} id={tourIds?.quantityBtn}>
          <QuantityBox
            product={productWithMarketingId}
            color={color}
            counterList={counterList}
            increment={increment}
            decrement={decrement}
            updateCounter={updateCounter}
            updateCartItem={updateCart}
            changeColor={changeColor}
            t={t}
            isUnverifiedCartOpen={isUnverifiedCartOpen}
          />
        </div>
      ) : (
        <>
          {product?.is_unpurchasable ? (
            <S.LockAddToCartButton fullWidth={fullWidth} onClick={showMessage}>
              {text}
            </S.LockAddToCartButton>
          ) : (
            <>
              <ShowIf condition={fullWidth}>
                <ShowIf condition={!isLoyaltyPointsShown}>
                  <ShowIf condition={!!similarProductList && similarProductList?.length > 0}>
                    <SimilarProductsButton text={t('similarProducts')} onClick={goToSimilarProductList} />
                    <S.CartDivider
                      orientation="vertical"
                      flexItem
                      style={{ marginTop: '5px', marginBottom: '5px', marginRight: '10px' }}
                    />
                  </ShowIf>
                </ShowIf>
              </ShowIf>
              <S.AddToCartButton
                data-testid={`addToCart-btn-${product.id}`}
                fullWidth={fullWidth}
                style={{ cursor: 'pointer' }}
                className="add-to-cart"
                colour={color}
                id={tourIds?.addBtn}
                counterList={counterList}
                onClick={() => {
                  if (onClick) onClick();
                  showSnackbarCart();
                  showHandleQuantityBox(productWithMarketingId);
                }}
                disabled={maxPurchasedReached || maxPurchaseMonthlyReached || !product.net_price}
              >
                {text}
              </S.AddToCartButton>
            </>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ counter: { counterList }, auth }: StoreState): StateProps => ({
  counterList,
  auth,
});

const mapDispatchToProps: DispatchProps = {
  increment: incrementProductQuantity,
  decrement: decrementProductQuantity,
  updateCounter: updateProductQuantity,
  updateCartItem,
};

ProductQuantityBoxContainerBase.defaultProps = {
  tourIds: undefined,
  fullWidth: undefined,
  text: undefined,
  onClick: undefined,
  isUnverifiedCartOpen: undefined,
  marketing_id: undefined,
  marketing_name: undefined,
  isLoyaltyPointsShown: undefined,
  similarProductList: undefined,
};

export const ProductCardQuantityBox = translate('productPage')(
  connect(mapStateToProps, mapDispatchToProps)(ProductQuantityBoxContainerBase),
);
