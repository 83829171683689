import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store';
import { useHelpDialog } from 'hooks';

import { defaultWidgetConfig } from 'components/pages/HomePage/style';

import { MarketingFeaturesDisplayPosition } from 'services/swipe-rx-pt/resources/marketings/constants';
import { clearProductListing } from 'store/reducers/ProductListing/actions';

import { MarketingType } from 'services/swipe-rx-pt/resources/marketings/interfaces';
import { ProductListProps } from '../generic-product-list.component';
import { Widget } from '../../Widget';
import { ProductListingWidget } from '../interfaces/generic-product-list.interface';

export const withFeaturedWMarketingWidget = (Component: any) => (props: ProductListProps & { withWidget: boolean }) => {
  const dispatch = useDispatch();
  const { toggleHelpDialog } = useHelpDialog();

  const marketingFeaturesRaw = useSelector((state: State) => state.marketingFeatures.marketings) || [];
  const marketingFeatures = marketingFeaturesRaw.filter(
    (marketing) => marketing.type !== MarketingType.SIMILAR_PRODUCT,
  );

  if (!props?.withWidget) {
    return <Component {...props} />;
  }

  useEffect(
    () => () => {
      dispatch(clearProductListing());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const randomizeWidgets = (widgets: ProductListingWidget[]): ProductListingWidget[] => {
    widgets
      .map((value) => ({
        value,
        random: Math.floor(Math.random() * 100),
      }))
      .sort((a, b) => a.random - b.random)
      .map(({ value }) => value);
    return widgets;
  };

  const shouldShowSearchVoid = useMemo(
    () => props.data.products.length < 8 && !props.data.loading,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.data.products.length, props.data.loading],
  );

  const widgets = shouldShowSearchVoid
    ? marketingFeatures.map((marketing) => ({
        id: marketing.id,
        getWidgets: () => [
          <Widget
            key={marketing.id}
            isHomepage={false}
            marketing={{ ...marketing }}
            onItemClick={toggleHelpDialog}
            defaultStyling={defaultWidgetConfig.styling}
          />,
        ],
        position: MarketingFeaturesDisplayPosition.AFTER_LISTING,
      }))
    : props.widgets || [];

  return <Component {...props} widgets={randomizeWidgets(widgets)} />;
};
