import { Action } from 'redux';

import { recordException } from 'utils/Reporting/Sentry';
import { ThunkActionCreator } from '../../../types/thunk';
import { v3Client } from '../../../utils/Client/v3';
import { State } from '../..';
import { Actions as GlobalActions, showError } from '../Global/actions';
import { PharmacyProduct } from '../FavoriteProduct';
import * as constants from './constants';
import { IDelayedInfoProducts } from '.';

export interface SetProductRemindersAction extends Action {
  type: constants.SET_PRODUCT_REMINDERS;
  payload: {
    products: PharmacyProduct[];
    limit: number;
    nextPage: number;
    hasMore: boolean;
  };
}
export interface ClearProductRemindersAction extends Action {
  type: constants.CLEAR_PRODUCT_REMINDERS;
}

interface SetDelayedAvailabilityRequest extends Action {
  type: constants.SET_DELAYED_AVAILABILITY_REQUEST;
  delayedAvailabilityReq: IDelayedInfoProducts[];
}
interface SetDelayedDeleteAvailabilityRequest extends Action {
  type: constants.SET_DELAYED_DELETE_AVAILABILITY_REQUEST;
  delayedDeleteAvailabilityReq: IDelayedInfoProducts[];
}

interface ClearDelayedAvailabilityRequestAction extends Action {
  type: constants.CLEAR_DELAYED_AVAILABILITY_REQUEST;
}

export type Actions =
  | GlobalActions
  | SetProductRemindersAction
  | ClearProductRemindersAction
  | SetDelayedAvailabilityRequest
  | SetDelayedDeleteAvailabilityRequest
  | ClearDelayedAvailabilityRequestAction;

export const fetchProductReminders: ThunkActionCreator<Actions, State> =
  (params: any) => async (dispatch, getState) => {
    try {
      const { page, limit: page_size } = params;

      const nextPage = page ? page + 1 : 1;
      const result = await v3Client.get('products/reminders', {
        page,
        page_size,
      });

      const newProductReminders = result.data;
      const hasMore = page < result.meta.page_count;

      const {
        productReminder: { products: currentProductReminders },
      } = getState();

      const productReminders = page === 1 ? newProductReminders : [...currentProductReminders, ...newProductReminders];

      dispatch({
        type: constants.SET_PRODUCT_REMINDERS,
        payload: {
          products: productReminders,
          limit: page_size,
          nextPage,
          hasMore,
        },
      });
    } catch (error) {
      if (error instanceof Error) {
        recordException(error, 'fetchProductReminders', { params });
        const errMsg = error.message;
        // eslint-disable-next-line no-console
        console.error(error);
        dispatch(showError(errMsg));
      }
    }
  };

export const clearProductReminders: ThunkActionCreator<Actions> = () => async (dispatch) => {
  dispatch({
    type: constants.CLEAR_PRODUCT_REMINDERS,
  });
};

export const productAvailabilityRequest: ThunkActionCreator<Actions, State> =
  (productId: number, distributorId: number) => async (dispatch, getState) => {
    try {
      const {
        productReminder: { delayedAvailabilityReq, delayedDeleteAvailabilityReq },
      } = getState();

      const { data } = await v3Client.post(`distributors/${distributorId}/availability-requests`, {
        product_id: typeof productId === 'string' ? Number.parseInt(productId, 10) : productId, // force productId from useStockOutCart
      });

      const isDeleteRequestExist = delayedDeleteAvailabilityReq.find((delayed) => delayed.productId === productId);

      if (isDeleteRequestExist) {
        const reqIndex = delayedDeleteAvailabilityReq.findIndex((delayed) => delayed.productId === productId);
        delayedDeleteAvailabilityReq.splice(reqIndex, 1);
        dispatch({
          type: constants.SET_DELAYED_DELETE_AVAILABILITY_REQUEST,
          delayedDeleteAvailabilityReq,
        });
      }

      delayedAvailabilityReq.push({
        availabilityRequestId: data.id,
        productId: data.product_id,
        distributorId: data.distributor_id,
      });

      if (data) {
        dispatch({
          type: constants.SET_DELAYED_AVAILABILITY_REQUEST,
          delayedAvailabilityReq,
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        recordException(error, 'fetchProductReminders', { productId, distributorId });
        const errMsg = error.message;
        // eslint-disable-next-line no-console
        console.error(error);
        dispatch(showError(errMsg));
      }
    }
  };

export const deleteProductReminder: ThunkActionCreator<Actions, State> =
  (params: { availabilityRequestId: number; productId: number; distributorId: number }) =>
  async (dispatch, getState) => {
    try {
      const { availabilityRequestId, productId, distributorId } = params;

      await v3Client.delete(`distributors/${distributorId}/availability-requests/${availabilityRequestId}`);

      const { productReminder } = getState();
      const { products, delayedDeleteAvailabilityReq, delayedAvailabilityReq } = productReminder;
      const productIndex = products.findIndex(({ id }) => id === productId);
      const isRequestExist = delayedAvailabilityReq.find((delayed) => delayed.productId === productId);

      products.splice(productIndex, 1);

      if (isRequestExist) {
        const reqIndex = delayedAvailabilityReq.findIndex((delayed) => delayed.productId === productId);
        delayedAvailabilityReq.splice(reqIndex, 1);
        dispatch({
          type: constants.SET_DELAYED_AVAILABILITY_REQUEST,
          delayedAvailabilityReq,
        });
      }
      delayedDeleteAvailabilityReq.push(params);
      dispatch({
        type: constants.SET_DELAYED_DELETE_AVAILABILITY_REQUEST,
        delayedDeleteAvailabilityReq,
      });

      dispatch({
        type: constants.SET_PRODUCT_REMINDERS,
        payload: {
          ...productReminder,
          products,
        },
      });
    } catch (error) {
      if (error instanceof Error) {
        recordException(error, 'deleteProductReminder', { params });
        const errMsg = error.message;
        // eslint-disable-next-line no-console
        console.error(error);
        dispatch(showError(errMsg));
      }
    }
  };

export const clearDelayedAvailabilityRequest: ThunkActionCreator<Actions, State> = () => async (dispatch) => {
  dispatch({
    type: constants.CLEAR_DELAYED_AVAILABILITY_REQUEST,
  });
};
